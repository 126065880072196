import { isFunction } from '@ahha/utils/@types/typeChecks'
import { Context, useCallback, useReducer, useRef, useSyncExternalStore } from 'react'

type Subscriber = () => void

type StoreGetter<T, R> = (store: T) => R

type StoreSetter<T> = T | ((prevStore: T) => T)

type StoreUpdater<T> = Partial<T> | ((prevStore: T) => Partial<T>)

export interface AtomicStore<T> {
  get: <R>(getter: StoreGetter<T, R>) => R
  getAll: () => T
  set: (setter: StoreSetter<T>) => void
  subscribe: (callback: Subscriber) => () => void
}

export const useAtomicStore = <T>(initialState: T) => {
  const store = useRef<T>(initialState)
  const subscribers = useRef(new Set<Subscriber>())

  const getAll = useCallback(() => store.current, [])

  const get = useCallback(<R>(getter: StoreGetter<T, R>) => getter(store.current), [])

  const set = useCallback((setter: StoreSetter<T>) => {
    store.current = isFunction(setter) ? setter(store.current) : setter

    return subscribers.current.forEach((fn) => fn())
  }, [])

  const update = useCallback((updater: StoreUpdater<T>) => {
    const nextState = isFunction(updater) ? updater(store.current) : updater
    store.current = { ...store.current, ...nextState }

    return subscribers.current.forEach((fn) => fn())
  }, [])

  const subscribe = useCallback((callback: Subscriber) => {
    subscribers.current.add(callback)

    return () => subscribers.current.delete(callback)
  }, [])

  return {
    get,
    getAll,
    set,
    update,
    subscribe,
  }
}

export const useAtomicContext = <T>(context: Context<T | null>, selector: () => Partial<T>) => {
  const store = useAtomicStore<T | null>(null)

  const state = useSyncExternalStore(
    store.subscribe,
    selector
  )

  return {
    get: store.get,
    set: store.set,
    subscribe: store.subscribe,
    Context: context,
  }
}

export const useDeferredStore = (delay?: number) => {
  const snapshot = useRef<unknown>()
  const [, forceUpdate] = useReducer((s) => s + 1, 0)

  const updater = () => {
    if (delay) {
      setTimeout(forceUpdate, delay)
    } else {
      queueMicrotask(forceUpdate)
    }
  }

  const defer = <R>(v: R) => {
    const prevSnap = snapshot.current ?? v

    snapshot.current = v
    if (prevSnap !== v) {
      updater()
    }
    return undefined as R
  }

  const resolve = () => snapshot.current

  return { defer, resolve }
}
