import { Draft, PayloadAction } from '@reduxjs/toolkit'

import { _addSnapshot, _getLastSnapshot } from '@/stores/slices/Lisa/annotationSlice'
import { AnnotationState } from '@/stores/slices/Lisa/annotation/types'
import { getSettledFiles } from '@/stores/slices/Lisa/annotation/utils'

export const removeObjectReducers = {
  removeObject: (state: Draft<AnnotationState>, action: PayloadAction<{ index: number, objectId: string }>) => {
    if (state.isInProgress) {
      console.error('Other requests is in progress')
      return
    }
    const { index, objectId } = action.payload
    const fileId = getSettledFiles(state, index).id
    const last = _getLastSnapshot(state, fileId)
    if (last.labelOk) {
      console.error('Cannot update object of the file marked as ok')
      return
    }
    const snapshot = {
      labelOk: last.labelOk,
      // object: last.object.filter((o) => o.id !== objectId),
      object: last.object.map((o) => ({
        ...o,
        isDeleted: o.id === objectId ? true : o?.isDeleted,
      })),
    }
    _addSnapshot(state, fileId, snapshot)
  },

  // for classification
  removeTag: {
    reducer: (state: Draft<AnnotationState>, action: PayloadAction<{ fileId: string }>) => {
      if (state.isInProgress) {
        console.error('Other requests is in progress')
        return
      }
      const { fileId } = action.payload
      const last = _getLastSnapshot(state, fileId)
      const snapshot = {
        labelOk: last.labelOk,
        // object: last.object.filter((o) => o.id !== objectId),
        object: last.object.map((o) => ({
          ...o,
          isDeleted: true,
        })),
      }
      _addSnapshot(state, fileId, snapshot)
    },
    prepare: (fileId: string) => ({
      payload: { fileId },
    }),
  },
}
