import {
  DatasetNode,
  InferenceNode,
  LabelNode,
  ModelNode,
  ReviewNode,
  ValidationNode
} from '@/stores/slices/Pipeline/types/node'

export const isDatasetNode = (data?: Record<string, any>): data is DatasetNode => data?.type === 'dataset'

export const isLabelingNode = (data?: Record<string, any>): data is LabelNode => data?.type === 'labeling'

export const isReviewNode = (data?: Record<string, any>): data is ReviewNode => data?.type === 'review'

export const isModelNode = (data?: Record<string, any>): data is ModelNode => data?.type === 'train'

export const isValidationNode = (data?: Record<string, any>): data is ValidationNode => data?.type === 'validation'

export const isInferenceNode = (data?: Record<string, any>): data is InferenceNode => data?.type === 'inference'

export const isGenericModelNode = (data?: Record<string, any>): data is ModelNode => ['train', 'validation', 'inference'].includes(data?.type)
