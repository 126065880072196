import { useEffect, useRef } from 'react'
import { UAParser } from 'ua-parser-js'

import ImmutableSet from '@ahha/utils/ImmutableSet'
import { shouldSuppressKeyEvent } from '@ahha/utils/event'
import { isArray, isKeyOf } from '@ahha/utils/@types/typeChecks'
import { useReactive } from '@ahha/utils/hooks'

type LetterKeys = 'A' | 'B' | 'C' | 'D' | 'E' | 'F' | 'G' | 'H' | 'I' | 'J' | 'K' | 'L' | 'M' | 'N' | 'O' | 'P' | 'Q' | 'R' | 'S' | 'T' | 'U' | 'V' | 'W' | 'X' | 'Y' | 'Z'

type ArrowKeys = 'ArrowUp' | 'ArrowDown' | 'ArrowLeft' | 'ArrowRight'

type EnterKeys = 'Enter' | 'NumpadEnter'

type NumberKeys = `Digit${number}` | `Numpad${number}`

type ModifierKeys = 'Shift' | 'Ctrl' | 'Alt' | 'Cmd'

type ArithmeticKeys = 'Equal' | 'Minus' | 'NumpadDivide' | 'NumpadMultiply' | 'NumpadAdd' | 'NumpadSubtract'

export type NormalKeys =
  | `Key${LetterKeys}`
  | 'Backspace'
  | 'Escape'
  | 'Delete'
  | 'Space'
  | NumberKeys
  | ArithmeticKeys
  | EnterKeys
  | ArrowKeys

export type ComboKeys =
  | `${ModifierKeys}+${NormalKeys}`
  | `Ctrl+Shift+${NormalKeys}`
  | `Cmd+${NormalKeys}`
  | `Cmd+Shift+${NormalKeys}`

interface ForceKeyUpData {
  prevKey: string
  timerId: NodeJS.Timeout | number
}

export interface HotKeyMap {
  enabled?: boolean
  hotKey: NormalKeys | ComboKeys
  handler: () => void
  /** @warn Ctrl+ __ 키는 제대로 동작하지 않을 수 있음 */
  cleanup?: () => void
  activateOnInput?: boolean
}

export function useHotKeys(hotKey: NormalKeys | ComboKeys, handler: () => void, activateOnInput?: boolean): void
export function useHotKeys(hotKeyMap: HotKeyMap[]): void

export function useHotKeys(
  hotKeys: NormalKeys | ComboKeys | HotKeyMap[],
  handler?: () => void,
  activateOnInput = false
) {
  const keyRecord = useRef(new Set<string>())
  const prevForceKeyupData = useRef<ForceKeyUpData>({ prevKey: '', timerId: 0 })

  const getHotKeys = useReactive<HotKeyMap[]>(isArray(hotKeys) ? hotKeys : [
    {
      hotKey: hotKeys,
      handler: () => handler?.(),
    },
  ])

  const isKeyMatched = (e: KeyboardEvent, keyCode: NormalKeys | ComboKeys) => {
    if (keyRecord.current.size < 1) {
      return false
    }
    const isCtrl = ifTrue(e.ctrlKey, 'Ctrl')
    const isMeta = ifTrue(e.metaKey, 'Cmd')
    const isAlt = ifTrue(e.altKey, 'Alt')
    const isShift = ifTrue(e.shiftKey, 'Shift')

    const matches = new ImmutableSet(keyCode.split('+'))
    const pressed = new ImmutableSet([
      ...[isCtrl, isMeta, isAlt, isShift].filter((v) => v),
      ...keyRecord.current,
    ])
    const diff = pressed.symmetricDifference(matches)

    return diff.size < 1 || isNormalizedKeyMatched(diff)
  }

  /**
   * MacOS에서 영문 키(ex. K)와 Cmd키를 함께 눌렀을 때, 영문 키를 떼더라도 영문 키에 대한 keyup 이벤트가 발생하지 않음.
   * Cmd+__ 조합키를 누르고 있더라도 그 동작이 keydown 이벤트 시 한번만 발생함을 가정해,
   * Cmd+__를 누르고 일정 시간이 지난 후 해당 조합키에 대한 가상의 keyup 이벤트를 발생시킴.
   * */
  const imitateMetaKeyUp = (key: string, isMetaKey: boolean) => {
    const { prevKey, timerId } = prevForceKeyupData.current

    /** 이전 keyRecord 삭제 타임아웃이 실행되기 전에 동일한 키를 눌렀을 때 */
    if (prevKey === key) {
      clearTimeout(timerId)
    } else {
      keyRecord.current.delete(prevKey)
    }

    if (isMetaKey) {
      const timerId = setTimeout(() => {
        keyRecord.current.delete(key)
      }, 100)
      prevForceKeyupData.current = { prevKey: key, timerId }
    }
  }

  const handleKeyPressEvent = (e: KeyboardEvent) => {
    if (!activateOnInput && shouldSuppressKeyEvent()) {
      return
    }
    e.preventDefault()
    if (e.repeat) {
      return
    }
    const k = filterModifierKeys(e)

    if (k) {
      keyRecord.current.add(k)
      imitateMetaKeyUp(k, e.metaKey)
    }
    getHotKeys().forEach(({ hotKey, handler, enabled = true }) => {
      if (enabled && handler && isKeyMatched(e, hotKey)) {
        handler()
      }
    })
  }

  const handleKeyUp = (e: KeyboardEvent) => {
    e.preventDefault()
    getHotKeys().forEach(({ hotKey, cleanup }) => {
      if (cleanup && isKeyMatched(e, hotKey)) {
        cleanup()
      }
    })
    keyRecord.current.delete(filterModifierKeys(e))
  }

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPressEvent)
    window.addEventListener('keyup', handleKeyUp)

    return () => {
      window.removeEventListener('keydown', handleKeyPressEvent)
      window.removeEventListener('keyup', handleKeyUp)
    }
  }, [activateOnInput])
}

const { os } = new UAParser().getResult()

export const isMacOS = os.name === 'Mac OS'

export const isWindows = os.name?.includes('Windows') ?? false

const MODIFIER_KEYS = new Set(['ShiftLeft', 'ShiftRight', 'ControlLeft', 'ControlRight', 'AltLeft', 'AltRight', 'MetaLeft'])

const NORMALIZABLE_KEYS = {
  Period: '.',
  NumpadDecimal: '.',
  Slash: '/',
  NumpadDivide: '/',
  Minus: '-',
  NumpadSubtract: '-',
  Equal: '+',
  NumpadAdd: '+',
}

const TEN_KEYS = /Digit|Numpad/

const filterModifierKeys = ({ code }: KeyboardEvent) => (MODIFIER_KEYS.has(code) ? '' : code)

const ifTrue = (condition: boolean, value: string) => (condition ? value : '')

const isNormalizedKeyMatched = (diffKeys: ImmutableSet<string>) => {
  const normalizedKeys = [...diffKeys].reduce((a, k) => {
    if (isKeyOf(k, NORMALIZABLE_KEYS)) {
      a.push(NORMALIZABLE_KEYS[k])
    } else if (k.match(TEN_KEYS)) {
      a.push(k.replace(TEN_KEYS, ''))
    }
    return a
  }, [] as string[])

  return normalizedKeys.length === 2 && new Set(normalizedKeys).size === 1
}
