export const RangeValues = {
  LAST_30_MIN: 'last_30_min',
  LAST_HOUR: 'last_1_hour',
  TODAY: 'today',
  YESTERDAY: 'yesterday',
  LAST_7_DAYS: 'last_7_day',
  LAST_14_DAYS: 'last_14_day',
  LAST_30_DAYS: 'last_30_day',
  LAST_90_DAYS: 'last_90_day',
  LAST_180_DAYS: 'last_180_day',
  THIS_WEEK: 'this_week',
  LAST_WEEK: 'last_week',
  THIS_MONTH: 'this_month',
  LAST_MONTH: 'last_month',
  CUSTOM: 'custom',
}

export const UnitValues = {
  MIN: 'minutes',
  HOUR: 'hours',
  DAY: 'days',
  MONTH: 'months',
}
