import { defer, LoaderFunctionArgs } from 'react-router-dom'

import { store } from '@/stores'
import { loadAllServerNodes, resetNodeInProgress, resetPipelineData } from '@/stores/slices/Pipeline/pipelineSlice'
import { invalidateProject } from '@/stores/slices/Pipeline/listeners/invalidate'

const resolveDataAsync = async (projectId: string) => {
  store.dispatch(resetNodeInProgress())
  const nodes = await invalidateProject(projectId)

  store.dispatch(loadAllServerNodes({ projectId, nodes }))
  return []
}

export const pipelineLoader = async ({ params }: LoaderFunctionArgs) => {
  const { projectId } = params

  store.dispatch(resetPipelineData())
  if (!projectId) {
    return null
  }
  return defer({ data: resolveDataAsync(projectId) })
}
