import { useRef } from 'react'

export const useReactive = <T>(value: T) => {
  const ref = useRef(value)

  ref.current = value
  return () => ref.current
}

export const useEffectEvent = <F extends (...args: unknown[]) => void>(fn: F) => {
  const functionRef = useRef(fn)

  functionRef.current = fn
  return (...args: Parameters<F>) => functionRef.current(args)
}
