import BaseApi from '@/api/Base/BaseApi'
import {
  CreateFolderResponse,
  FileData,
  FileListResponse,
  FolderData,
  FolderListResponse,
  GetDownloadTokenResponse,
  GetFilesOnPagePayload,
  GetItemsPayload,
  ItemCategory,
  TaggedFileData,
  TaggedFolderData,
  UploadFileResponse
} from '@/api/DataStorage/types'

const COUNT_PER_PAGE = 20

/**
 * root 폴더의 id를 빈 문자열로 설정할 수 있지만
 * `SiteUrls.Main.Storage.folder()`에 빈 문자열을 전달하면 URL 끝에 `:id`가 붙는 문제가 있음.
 * 전역에서 사용되는 `SiteUrls`을 수정하는 대신 root 폴더의 id를 `0`으로 설정함.
 * */
export const ROOT_DIR_ID = '0'

const DEFAULT_GET_ITEMS_PAYLOAD: Required<Omit<GetItemsPayload, 'type'>> = {
  dirId: '',
  page: 0,
  sortKey: 'name',
  order: 'asc',
  itemsPerPage: COUNT_PER_PAGE,
  search: '',
}

const tagItems = <D extends FolderData | FileData, T extends ItemCategory>(
  items: D[],
  type: T
) => items.map((item) => ({ ...item, type }))

/** @deprecated */
export default class DataStorageApi extends BaseApi {
  /**
   * API 요청을 보내기 전까지는 루트 디렉토리의 id를 알 수 없으므로
   * 이후 mutation 요청에 사용할 루트 디렉토리의 id를 저장해둠.
   * */
  #rootDirId = ''

  #setRootDirId(data: FolderData[]) {
    if (!!data.length && !this.#rootDirId) {
      this.#rootDirId = data[0].parent._id
    }
  }

  #toApiDirId(id: string) {
    return id === ROOT_DIR_ID ? this.#rootDirId : id
  }

  /** 해당 폴더에 있는 특정 페이지의 파일을 가져오는 API. */
  async getFilesOnPage(payload: GetFilesOnPagePayload) {
    const { dirId, page, type, sortKey, order, search, itemsPerPage } = payload
    const dirPath = dirId !== ROOT_DIR_ID ? `/${dirId}` : ''

    const { list: fileList, paging } = await this.agent.get<FileListResponse>(`/file/list${dirPath}`, {
      params: {
        page,
        type,
        sort_key: sortKey,
        sort_dir: order,
        num_per_page: itemsPerPage ?? COUNT_PER_PAGE,
        search,
      },
    })
    const taggedFiles: TaggedFileData[] = tagItems(fileList, 'FILE')

    return { files: taggedFiles, paging }
  }

  async getFolders(payload: Required<Omit<GetItemsPayload, 'type'>>) {
    const { dirId, search, sortKey, order } = payload
    const dirPath = dirId !== ROOT_DIR_ID ? `/${dirId}` : ''

    const { list: dirList } = await this.agent.get<FolderListResponse>(`/directory/list${dirPath}`, {
      params: {
        search,
        sort_key: sortKey,
        sort_dir: order,
      },
    })
    const taggedFolders: TaggedFolderData[] = tagItems(dirList, 'DIRECTORY')

    this.#setRootDirId(dirList)
    return { folders: taggedFolders }
  }

  async getFilesOnAllPages(payload?: Omit<GetItemsPayload, 'page'>) {
    const preparedPayload = { ...DEFAULT_GET_ITEMS_PAYLOAD, ...payload }
    const { files: firstPage, paging } = await this.getFilesOnPage(preparedPayload)
    const { total_count: totalCount } = paging ?? {}

    const restPages = await Promise.all(
      Array.from(
        { length: Math.ceil(totalCount / COUNT_PER_PAGE) - 1 },
        (_, idx) => this.getFilesOnPage({ ...preparedPayload, page: idx + 1 })
      )
    )
    const restItems = restPages.flatMap(({ files }) => files)

    return { data: [...firstPage, ...restItems], paging }
  }

  /**
   * 해당 폴더에 있는 특정 페이지의 하위 폴더와 파일을 가져오는 API.
   * 폴더는 `page = 0`일 때만 가져옴.
   * */
  async getItemsOnPage(payload?: GetItemsPayload) {
    const preparedPayload = { ...DEFAULT_GET_ITEMS_PAYLOAD, ...payload }

    /* TODO:ksh: 정렬 기준만 바꿀 때 folder 목록은 호출하지 않도록 수정 - 2024.01.16 */
    if (preparedPayload.page < 1) {
      const { folders } = await this.getFolders(preparedPayload)
      const { files, paging } = await this.getFilesOnPage(preparedPayload)

      return Promise.resolve({ data: [...folders, ...files], paging })
    }
    const { files, paging } = await this.getFilesOnPage(preparedPayload)

    return { data: files, paging }
  }

  /**
   * 해당 폴더에 있는 모든 페이지의 하위 폴더와 파일을 가져오는 API.
   * 폴더는 `page = 0`일 때만 가져옴.
   * */
  async getItemsOnAllPages(payload?: Omit<GetItemsPayload, 'page'>) {
    const preparedPayload = { ...DEFAULT_GET_ITEMS_PAYLOAD, ...payload }
    const { data: firstPage, paging } = await this.getItemsOnPage(preparedPayload)
    const { total_count: totalCount } = paging ?? {}

    const restPages = await Promise.all(
      Array.from(
        { length: Math.ceil(totalCount / COUNT_PER_PAGE) - 1 },
        (_, idx) => this.getItemsOnPage({ ...preparedPayload, page: idx + 1 })
      )
    )
    const restItems = restPages.flatMap(({ data }) => data)

    return { data: [...firstPage, ...restItems], paging }
  }

  getDirectoryDetail(dirId: string) {
    if (dirId === ROOT_DIR_ID) {
      return Promise.resolve({} as FolderData)
    }
    return this.agent.get<FolderData>(`/directory/${dirId}`)
  }

  createDirectory(name: string, parentId: string) {
    return this.agent.post<CreateFolderResponse>('/directory', {
      parent_id: this.#toApiDirId(parentId),
      path: name,
    })
  }

  deleteDirectory(dirId: string) {
    return this.agent.delete(`/directory/${dirId}`)
  }

  /**
   * @param dirId 이동할 폴더의 id
   * @param destDirId 이동 타겟인 폴더의 id(destination directory id)
   */
  moveDirectory(dirId: string, destDirId: string) {
    return this.agent.put('/directory/move', {
      directory_id: dirId,
      dest_directory_id: this.#toApiDirId(destDirId),
    })
  }

  renameDirectory(dirId: string, name: string) {
    return this.agent.put('/directory/rename', {
      directory_id: dirId,
      new_name: name,
    })
  }

  /* ------------- 파일 관련 API ------------- */
  /**
   * @param files
   * @param dirId 빈 문자열을 전달하면 루트 디렉토리로 인식해 클래스에 저장해둔 id를 API 요청에 사용.
   * @returns
   */
  uploadFiles(files: File[], dirId: string) {
    const formData = new FormData()

    files.forEach((file) => {
      formData.append('file', file)
    })
    formData.append('directory_id', this.#toApiDirId(dirId))

    return this.agent.post<UploadFileResponse>('/file/direct-upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }

  getFileDetail(fileId: string) {
    return this.agent.get(`/file/${fileId}`)
  }

  deleteFile(fileId: string) {
    return this.agent.delete(`/file/${fileId}`)
  }

  renameFile(fileId: string, name: string) {
    return this.agent.put('/file/rename', {
      file_id: fileId,
      new_name: name,
    })
  }

  moveFiles(fileId: string, destDirId: string) {
    return this.agent.put('/file/move', {
      file_id: fileId,
      dest_directory_id: this.#toApiDirId(destDirId),
    })
  }

  getDownloadToken(fileId: string) {
    return this.agent.get<GetDownloadTokenResponse>(`/file/download-token/${fileId}`)
  }

  downloadFile(fileId: string, token: string) {
    return this.agent.get(`/file/download/${fileId}`, {
      params: { token },
    })
  }
}
