import { CampTheme } from '@ahha/theme/CampTheme'

export const DarkTheme: CampTheme = {
  themeId: 'dark',
  themeName: 'Dark Theme',
  theme: {
    bg: {
      primary: '#0A0A0A',
      primarySub: '#898989',
      primaryTertiary: '#3D3D3D',
      primaryHover: '#242424',
      secondary: '#F97316',
      secondaryHover: '#E36914',
      secondarySub: '#FEF1E8',
      select: '#EAF2FE',
      hover: '#F7F7F8',
      disabled: '#F3F3F3',
      error: '#E51919',
      errorSub: '#FDF2F2',
      info: '#0066FF',
      infoSub: '#EAF2FE',
      inverse: '#FFFFFF',
      warning: '#F2BC1A',
      warningSub: '#FFFAEB',
      success: '#1FB356',
      successSub: '#F3FCF6',
    },
    text: {
      primary: '#0A0A0A',
      secondary: '#3D3D3D',
      tertiary: '#575757',
      disabled: '#BDBDBD',
      inverse: '#FFFFFF',
      secondaryOrange: '#B15210',
      error: '#C40000',
      info: '#0054D1',
      success: '#136C34',
      warning: '#8F6B00',
    },
    border: {
      border1: '#0A0A0A',
      border2: '#3D3D3D',
      border3: '#898989',
      border4: '#DFDFE0',
      border5: '#F3F3F3',
      borderWhite: '#FFFFFF',
      secondary: '#F97316',
      disabled: '#F0F0F0',
      error: '#E51919',
      select: '#0066FF',
      success: '#22C55E',
      warning: '#F2BC1A',
    },
    icon: {
      primary: '#0A0A0A',
      secondary: '#575757',
      tertiary: '#898989',
      secondaryOrange: '#F97316',
      inverse: '#FFFFFF',
      disabled: '#DFDFE0',
      error: '#E51919',
      info: '#0066FF',
      warning: '#F2BC1A',
      success: '#1FB356',
    },
    page: {
      primary: '#FFFFFF',
      secondary: '#F7F7F8',
      tertiary: '#F3F3F3',
      inverse: '#000000',
    },
    variants: {
      info300: '#69A5FF',
      warning500: '#F2BC1A',
      success500: '#22C55E',
      secondary500: '#F97316',
    },
    dim: '#000000B3',
  },
}
