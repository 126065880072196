import BaseApi from '@/api/Base/BaseApi'
import {
  AddProjectMembersPayload,
  CreateProjectPayload,
  EditProjectPayload,
  GetProjectListPayload,
  GetProjectListResponse,
  ProjectDetail,
  ProjectType,
  RemoveProjectMemberPayload,
  UpdateMemberPermissionPayload
} from '@/api/Project/types'
import { UserInfo } from '@/api/User/types'

const ROOT = '/project'
const COUNT_PER_PAGE = 20

// TODO: 하드코딩 되어 있던 아이디 추후 변경 필요
const WORKSPACE_ID = '6688b2331b8440137af8e417'

export default class ProjectApi extends BaseApi {
  getProjectList(payload: GetProjectListPayload) {
    const { page, itemsPerPage, sortKey, sortDir, workspaceId } = payload
    return this.agent.get<GetProjectListResponse>(`/workspace/${workspaceId}/project/list`, {
      params: {
        page,
        num_per_page: itemsPerPage ?? COUNT_PER_PAGE,
        sort_key: sortKey,
        sort_dir: sortDir,
      },
    })
  }

  getProjectDetail(projectId: string) {
    return this.agent.get<ProjectDetail>(`${ROOT}/${projectId}`)
  }

  createProject(payload: CreateProjectPayload) {
    return this.agent.post<ProjectType>(`/workspace/${payload.workspaceId}/project`, payload)
  }

  deleteProject(projectId: string) {
    return this.agent.delete(`${ROOT}/${projectId}`)
  }

  editProject(payload: EditProjectPayload) {
    const { projectId, ...rest } = payload
    return this.agent.put(`${ROOT}/${projectId}`, rest)
  }

  getProjectMembers(projectId: string) {
    return this.agent.get<UserInfo[]>(`${ROOT}/${projectId}/member/list`)
  }

  addProjectMembers(payload: AddProjectMembersPayload) {
    const { projectId, ...rest } = payload
    return this.agent.post(`${ROOT}/${projectId}/members`, rest)
  }

  updateMemberPermission(payload: UpdateMemberPermissionPayload) {
    const { projectId, ...rest } = payload
    return this.agent.put(`${ROOT}/${projectId}/member`, rest)
  }

  removeProjectMember(payload: RemoveProjectMemberPayload) {
    const { projectId, userId } = payload
    return this.agent.delete(`${ROOT}/${projectId}/member/${userId}`)
  }
}
