import { Draft, PayloadAction } from '@reduxjs/toolkit'
import { v4 as uuidv4 } from '@lukeed/uuid'

import { LabelingState, Comment } from '@/stores/slices/Labeling/types'

export const updateObjectReducers = {
  destroyComment: (state: Draft<LabelingState>, action: PayloadAction<string>) => {
    const fileId = state.files[state.currentIndex]?._id
    if (fileId) {
      state.comments[fileId] = state.comments[fileId].filter((c) => c.id !== action.payload)
    }
  },
  addComment: (state: Draft<LabelingState>, action: PayloadAction<{ id: string, comment: string, user: { id: string, name: string } }>) => {
    const now = new Date()
    const newComment = {
      id: uuidv4(),
      user: action.payload.user,
      text: action.payload.comment,
      createdAt: now,
      modifiedAt: now,
    }

    const fileId = state.files[state.currentIndex]?._id || ''
    state.comments[fileId] = state.comments[fileId].map((c) => {
      if (c.id === action.payload.id) {
        return { ...c, comments: [newComment, ...(c.comments ?? [])] }
      }
      return c
    })
  },
  removeComment: (state: Draft<LabelingState>, action: PayloadAction<{ id: string, commentId: string }>) => {
    const fileId = state.files[state.currentIndex]?._id || ''
    state.comments[fileId] = state.comments[fileId].map((c) => {
      const next = c.comments?.filter((comment) => comment.id !== action.payload.commentId) ?? []

      if (c.id === action.payload.id) {
        return {
          ...c,
          comments: next,
          serverId: next.length >= 1 ? c.serverId : undefined,
        }
      }
      return c
    })
  },
  toggleResolveComment: (state: Draft<LabelingState>, action: PayloadAction<{ id: string, resolved: boolean }>) => {
    const fileId = state.files[state.currentIndex]?._id || ''
    state.comments[fileId] = state.comments[fileId].map((c) => {
      if (c.id === action.payload.id) {
        return { ...c, resolved: action.payload.resolved }
      }
      return c
    })
  },
  updateCommentData: (state: Draft<LabelingState>, action: PayloadAction<{ fileId: string, commentId: string, data: Partial<Comment> }>) => {
    const { fileId, commentId, data } = action.payload
    state.comments[fileId] = state.comments[fileId].map((c) => {
      if (c.id === commentId) {
        return { ...c, ...data }
      }
      return c
    })
  },
  repositionComment: (state: Draft<LabelingState>, action: PayloadAction<{ id: string, x: number, y: number, width: number, height: number }>) => {
    const { id, x, y, width, height } = action.payload
    const fileId = state.files[state.currentIndex]?._id || ''

    state.comments[fileId] = state.comments[fileId].map((c) => {
      if (c.id === id) {
        return { ...c, x, y, width, height }
      }
      return c
    })
  },
}
