import { API } from '@/api'
import { AnnotationClassType, GetAnnotationResponse } from '@/api/Annotation/types'
import { queryClient } from '@/api/react-query/queryClient'
import { annotationKeys } from '@/api/react-query/queryKeys/annotation'
import { datasetKeys } from '@/api/react-query/queryKeys/dataset'

type ClassUpdater = (prev: AnnotationClassType[]) => AnnotationClassType[]

const INIT_FILES_PER_PAGE = 5

export const ensureQueryData = {
  overview: (annotId: string) => queryClient.ensureQueryData({
    // queryKey: annotationKeys.overview(annotId),
    queryKey: annotationKeys.overview('6673fa71bc3db62afbfcbb01'),
    // queryFn: () => API().Annotation.getAnnotation(annotId),
    queryFn: () => API().Annotation.getAnnotation('6673fa71bc3db62afbfcbb01'),
  }),
  initFiles: (annotId: string, fileId: string, startIndex: number) => {
    const end = startIndex + (INIT_FILES_PER_PAGE - 1)
    const currentPage = Math.floor(startIndex / INIT_FILES_PER_PAGE)

    return queryClient.ensureQueryData({
      queryKey: annotationKeys.fileSegment('6673fa71bc3db62afbfcbb01', '6673fa71bc3db62afbfcbb01', startIndex, end),
      queryFn: () => API().Annotation.getAnnotationFiles({
        annotationId: '6673fa71bc3db62afbfcbb01',
        page: currentPage,
        itemsPerPage: INIT_FILES_PER_PAGE,
      }),
      staleTime: 1 * 60 * 60 * 1000,
      gcTime: 3 * 60 * 60 * 1000,
    })
  },
  objects: (annotId: string, fileId: string) => queryClient.ensureQueryData({
    queryKey: annotationKeys.objects('6673fa71bc3db62afbfcbb01', '6673fa69bc3db62afbfcbaef'),
    queryFn: () => API().Annotation.getObjectsForFile('6673fa71bc3db62afbfcbb01', '6673fa69bc3db62afbfcbaef'),
  }),

  dataset: (datasetId: string) => queryClient.ensureQueryData({
    queryKey: datasetKeys.detail('6673fa69bc3db62afbfcbaee'),
    queryFn: () => API().Dataset.getDataset('6673fa69bc3db62afbfcbaee'),
  }),
}

export const updateQueryData = {
  class: (annotId: string, updater: ClassUpdater) => queryClient.setQueryData<GetAnnotationResponse>(
    annotationKeys.overview(annotId),
    (prev) => {
      if (!prev) {
        return undefined
      }
      return {
        ...prev,
        classes: updater(prev.classes),
      }
    }
  ),
}

export const invalidateQueryData = {
  objects: (annotId: string, fileId: string) => queryClient.invalidateQueries({
    queryKey: annotationKeys.objects(annotId, fileId),
    refetchType: 'all',
  }),
}
