import { isPlainObject } from '@ahha/utils/@types/typeChecks'

export type StateDispatcher<T> = (prev: T) => T

export type State<T> = T | StateDispatcher<T>

export type InitialState<T, D = unknown[]> = T | ((deps: D | undefined, prevState?: T) => T)

export type CompareFn<T> = (prev: T, next: T) => boolean

export type Dependencies = unknown[]

export interface EnhancedStateOptions<T, D = unknown[]> {
  initialValue: InitialState<T, D>
  compareFn?: CompareFn<T>
  deps?: D
}

export type EnhancedStateParams<T, D = unknown[]> =
  | [InitialState<T>]
  | [InitialState<T, D>, D]
  | [EnhancedStateOptions<T, D>]

export type EnhancedStateReturn<T> = [
  T,
  (next: State<T>, isSnapshot?: boolean) => void,
  (next?: T) => void
]

type Selected<T> = T extends infer R ? R : never

export interface SyncWithPropsOptions {
    deps?: Dependencies
    select?: <T>(...deps: Dependencies) => T extends infer R ? R : never
    subscribe: (data: Dependencies) => void
  }

/* type guards */
export const isOptionParam = <T>(param: unknown): param is EnhancedStateOptions<T> => isPlainObject(param) && ('initialValue' in param)

export const isArrayState = <T>(snapshotType: string, state: unknown): state is T[] => snapshotType === 'array'
