import { useEffect } from 'react'

import { registerGlobalEvent, unregisterGlobalEvent } from '@ahha/components/Canvas/utils/hooks/useCursors/utils'
import { isElementTagOf } from '@ahha/utils/reactDOM'
import useStageCursor from '@ahha/components/Canvas/utils/hooks/useCursors/useStageCursor'

const useRevertCursorOnQuit = (enabled: boolean, onRevert?: () => void) => {
  const [, revertCursor] = useStageCursor()

  useEffect(() => {
    const revertCursorOutsideOfCanvas = (e: MouseEvent) => {
      if (!isElementTagOf(e.target, 'canvas')) {
        revertCursor()
        onRevert?.()
      }
    }

    if (enabled) {
      registerGlobalEvent('mouseup', revertCursorOutsideOfCanvas)
    }

    return () => unregisterGlobalEvent('mouseup', revertCursorOutsideOfCanvas)
  }, [enabled])
}

export default useRevertCursorOnQuit
