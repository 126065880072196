export const ZoomBehavior = {
  ZOOM_IN: 'zoom_in',
  ZOOM_OUT: 'zoom_out',
} as const

export const AnnotationLabelingType = {
  ANOMALY_DETECTION: 'ANOMALY_DETECTION',
  SEGMENTATION: 'SEGMENTATION',
  CLASSIFICATION: 'CLASSIFICATION',
  OBJECT_DETECTION: 'OBJECT_DETECTION',
}
