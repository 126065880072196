import { AxiosResponse, InternalAxiosRequestConfig } from 'axios'
import { mapKeys, set } from 'lodash'

import { isObjectArray, isPlainObject } from '@ahha/utils/@types/typeChecks'
import { API } from '@/api'

import { SuccessResponse, TransformedResponse } from './types'

export const setToken = (config: InternalAxiosRequestConfig<any>) => {
  const token = API().getToken()

  if (token) {
    set(config.headers, 'Authorization', `Bearer ${token}`)
  }
  return config
}

export const removeIdUnderScore = <T extends SuccessResponse<any>>(config: AxiosResponse<T>) => {
  const { data } = config.data

  if (isPlainObject(data)) {
    const underScoreRemoved = mapKeys(data, (_, k) => (k === '_id' ? 'id' : k))

    return {
      ...config,
      data: {
        ...config.data,
        data: underScoreRemoved,
      },
    }
  }
  if (isObjectArray(data)) {
    const underScoreRemoved = data.map((d) => mapKeys(d, (_, k) => (k === '_id' ? 'id' : k)))

    return {
      ...config,
      data: {
        ...config.data,
        data: underScoreRemoved,
      },
    }
  }
  return config
}

export const flattenResponseData = <T extends SuccessResponse<any>>(response: AxiosResponse<T>) => {
  /**
    * response의 `data` 속성이 객체이면 해당 객체를 spread해서 반환, 배열이면 `data` 속성에 그대로 반환.
    * 속성을 추가하거나 axios config, headers, request 객체가 필요하다면
    * 아래 코드를 수정하고 {@link ./types.ts}의 `TransformedResponse` 타입도 수정할 것.
    */
  if (isPlainObject(response.data.data)) {
    return ({
      ...response.data.data,
      // status: response.status,
    }) as TransformedResponse<T>
  }
  return ({
    data: response.data.data,
    // status: response.status,
  }) as TransformedResponse<T>
}
