import BaseApi from '@/api/Base/BaseApi'
import {
  CreatePayload,
  GetModelListPayload,
  GetModelListResponse,
  GetModelInfoResponse,
  GetModelEvaluationListResponse,
  CreateEvaluationPayload,
  GetAvailableAnnotationListResponse,
  GetAvailableAnnotationListPayload,
  GetEvaluationResultFileListPayload,
  GetEvaluationResultFileListResponse,
  GetEvaluationResultFileInfoResponse,
  GetEvaluationDetailResponse,
  GetEvaluationListPayload
} from '@/api/Model/types'

const ROOT = '/model'
export default class ModelApi extends BaseApi {
  getModelList(payload: GetModelListPayload) {
    const { keyword, page, itemsPerPage, sortKey, sortDir } = payload
    return this.agent.get<GetModelListResponse>(`${ROOT}/list`, {
      params: {
        page,
        num_per_page: itemsPerPage,
        sort_key: sortKey,
        sort_dir: sortDir,
      },
    })
  }

  createModel(payload: CreatePayload) {
    return this.agent.post<any>(`${ROOT}/create`, payload)
  }

  getModelInfo(modelId: string) {
    return this.agent.get<GetModelInfoResponse>(`${ROOT}/${modelId}`)
  }

  deleteModel(modelId: string) {
    return this.agent.delete(`${ROOT}/${modelId}`)
  }

  getModelEvaluationList(modelId: string, payload: GetEvaluationListPayload) {
    const { page, itemsPerPage, sortKey, sortDir } = payload
    return this.agent.get<GetModelEvaluationListResponse>(`${ROOT}/${modelId}/evaluation/list`, {
      params: {
        page,
        num_per_page: itemsPerPage,
        sort_key: sortKey,
        sort_dir: sortDir,
      },
    })
  }

  createEvaluation(modelId: string, payload: CreateEvaluationPayload) {
    return this.agent.post<any>(`${ROOT}/${modelId}/evaluation/create`, payload)
  }

  getAvailableAnnotationList(modelId: string, payload: GetAvailableAnnotationListPayload) {
    const { page, itemsPerPage, sortKey, sortDir } = payload
    return this.agent.get<GetAvailableAnnotationListResponse>(`${ROOT}/${modelId}/evaluation/available-annotations`, {
      params: {
        page,
        num_per_page: itemsPerPage,
        sort_key: sortKey,
        sort_dir: sortDir,
      },
    })
  }

  getEvaluationResultFileList(modelId: string, evaluationId: string, payload: GetEvaluationResultFileListPayload) {
    const { page, itemsPerPage, sortKey, sortDir } = payload
    return this.agent.get<GetEvaluationResultFileListResponse>(`${ROOT}/${modelId}/evaluation/${evaluationId}/inference_file/list`, {
      params: {
        page,
        num_per_page: itemsPerPage,
        sort_key: sortKey,
        sort_dir: sortDir,
      },
    })
  }

  getEvaluationResultFileInfo(modelId: string, evaluationId: string, fileId: string) {
    return this.agent.get<GetEvaluationResultFileInfoResponse>(`${ROOT}/${modelId}/evaluation/${evaluationId}/inference_file/${fileId}`)
  }

  getEvaluationDetail(modelId: string, evaluationId: string) {
    return this.agent.get<GetEvaluationDetailResponse>(`${ROOT}/${modelId}/evaluation/${evaluationId}`)
  }
}
