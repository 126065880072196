/* eslint-disable class-methods-use-this */
/* eslint-disable no-constructor-return */
import BaseApi from '@/api/Base/BaseApi'
import DataStorageApi from '@/api/DataStorage/DataStorageApi'
import UserApi from '@/api/User/UserApi'
import WorkspaceApi from '@/api/Workspace/WorkspaceApi'
import NotificationApi from '@/api/Notification/NotificationApi'
import DatasetApi from '@/api/Dataset/DatasetApi'
import AnnotationApi from '@/api/Annotation/AnnotationApi'
import DatabaseApi from '@/api/Database/DatabaseApi'
import DashboardApi from '@/api/Dashboard/DashboardApi'
import ModelApi from '@/api/Model/ModelApi'
import TaskApi from '@/api/Task/TaskApi'
import ProjectApi from '@/api/Project/ProjectApi'
import StorageApi from '@/api/Storage/StorageApi'
import DatasetNodeApi from '@/api/DatasetNode/DatasetNodeApi'
import LabelingNodeApi from '@/api/LabelingNode/LabelingNodeApi'
import LabelingNodeReviewApi from '@/api/LabelingNode/LabelingNodeReviewApi'
import ClassGroupApi from '@/api/ClassGroup/ClassGroupApi'
import ModelNodeApi from '@/api/ModelNode/ModelNodeApi'
import ValidationNodeApi from '@/api/ValidationNode/ValidationNodeApi'
import InferenceNodeApi from '@/api/InferenceNode/InferenceNodeApi'
import HyperparametersApi from '@/api/Hyperparameters/HyperparametersApi'
import InvitationApi from '@/api/Invitation/InvitationApi'

class Api extends BaseApi {
  static proto: Api

  #instance: Api | undefined

  constructor(signal?: AbortSignal, isInstance = false) {
    super(signal)
    this.#instance = this

    if (isInstance) {
      return this.#instance
    }
    if (Api.proto) {
      return Api.proto
    }
    Api.proto = this
  }

  withSignal(signal: AbortSignal) {
    return new Api(signal, true)
  }

  User = new UserApi(this.signal)

  Workspace = new WorkspaceApi(this.signal)

  Notifications = new NotificationApi(this.signal)

  Project = new ProjectApi(this.signal)

  Dataset = new DatasetApi(this.signal)

  DataStorage = new DataStorageApi(this.signal)

  Annotation = new AnnotationApi(this.signal)

  Database = new DatabaseApi(this.signal)

  Dashboard = new DashboardApi(this.signal)

  /** @deprecated */
  Model = new ModelApi(this.signal)

  Task = new TaskApi(this.signal)

  Storage = new StorageApi(this.signal)

  DatasetNode = new DatasetNodeApi(this.signal)

  Labeling = new LabelingNodeApi(this.signal)

  LabelingReview = new LabelingNodeReviewApi(this.signal)

  ClassGroup = new ClassGroupApi(this.signal)

  ModelNode = new ModelNodeApi(this.signal)

  ValidationNode = new ValidationNodeApi(this.signal)

  InferenceNode = new InferenceNodeApi(this.signal)

  Hyperparameters = new HyperparametersApi(this.signal)

  Invitation = new InvitationApi(this.signal)
}

export const API = () => new Api()
