export const LAYER_NAMES = {
  block: 'block',
  editUnfocused: 'edit:unfocused',
  editFocused: 'edit:focused',
  draw: 'draw',
  image: 'image',
  debug: 'debug',
  bottom: 'bottom-layer',
  top: 'top-layer',
}

export const GROUP_NAMES = {
  selectable: 'selectable-group',
  top: 'top-group-on-layer',
  bottom: 'bottom-group-on-layer',
  portalOuter: '_outer_portal',
  portalInner: '_inner_portal',
}
