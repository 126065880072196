export const LABEL_FILE_STATUS = {
  /** labeler */
  NOT_STARTED: 'NOT_STARTED',
  IN_PROGRESS: 'IN_PROGRESS',
  IN_PROGRESS_HOLD: 'IN_PROGRESS_HOLD',
  FINISHED: 'FINISHED',
  REQUEST_REVIEW: 'REQUEST_REVIEW',

  /** reviewer */
  REVIEW_IN_PROGRESS: 'REVIEW_IN_PROGRESS',
  REVIEW_HOLD: 'REVIEW_HOLD',
  REJECTED: 'REJECTED',
  COMPLETED: 'COMPLETED',
} as const

/** @deprecated */
export const LABEL_OK_STATUS = {
  waiting: 'WAITING',
  ok: 'OK',
  ng: 'NG',
} as const

export const LABEL_FINISHED = [LABEL_FILE_STATUS.FINISHED, LABEL_FILE_STATUS.REQUEST_REVIEW]

export const LABEL_ONGOING = [LABEL_FILE_STATUS.IN_PROGRESS, LABEL_FILE_STATUS.NOT_STARTED]
