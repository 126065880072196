import { useStage } from '@ahha/components/Canvas/utils/Context/Stage/useStage'
import { v4 } from '@lukeed/uuid'
import { useRef } from 'react'

const useStageCursor = () => {
  const [stage] = useStage((s) => s.node)
  const id = useRef(v4()).current

  if (!stage) {
    throw new Error('Stage is not initialized. Please use this hook inside StageContext provider.')
  }

  /** TODO:ksh: 단시간 내에 여러 곳에서 setCursor가 호출될 경우 우선순위 정해서 설정할 수 있도록 - 2024.06.05 */
  const setCursor = (cursor: string) => {
    stage.content.style.cursor = cursor
  }

  /** TODO:ksh: 현재 커서가 해당 컴포넌트에서 설정한(id 값으로 구분) 커서일 때만 revert 되도록 - 2024.06.05 */
  const revertCursor = () => {
    stage.content.style.cursor = 'default'
  }

  return [setCursor, revertCursor, stage] as const
}

/**
 * 1. 가장 나중에 설정된 커서가 적용.(기본)
 * 2. 우선 순위 설정 가능해야 함. 낮은 우선 순위는 나중에 설정되었더라도 적용되어서는 안 됨.
 *   -> 기본적인 우선 순위는 모듈 객체로 관리.
 * 3. revert 시 자신이 설정한 커서만 원래로 되돌려야 함.
 *
 * [레이블링]
 *
 *
 * [파이프라인]
 * 1. anchor 클릭하면 'pointer'로 바꿔야 하는데 노드 포커스가 되어 'move'로 커서가 바뀜
 */

const CURSOR_PRIORITYS = {
  move: 0,
  resize: 0,
}

export default useStageCursor
