import { Draft } from '@reduxjs/toolkit'
import { v4 as uuidv4 } from 'uuid'

import { AnnotationObjectData, AnnotationState, ClassDefinition, InternalAnnotFileInfo, RawAnnotFile, ThunkConditionAPI } from './types'
import { AnnotationClassType, GetObjectsForFileResponse, ObjectToolType } from '@/api/Annotation/types'
import { AnnotationType } from '@/pages/Lisa/Label/types'
import { calcRotatedPointsForCanvas } from '@/pages/Lisa/Label/utils/canvasUtils'

export const transformAnnotClasses = (
  classes: (AnnotationClassType | ClassDefinition)[],
  setVisibility?: (classId: string) => boolean
) => classes.map((c) => {
  const id = 'id' in c ? c.id : c._id

  return ({
    id,
    name: c.name,
    color: c.color,
    isVisible: setVisibility?.(id) ?? true,
    visibleConfiguredAt: new Date(),
  })
})

export const transformAnnotFiles = (data: RawAnnotFile) => (data ? ({
  id: data._id,
  filename: data.name,
  rawPath: data.accessibleUrl,
  width: data.fileMeta.mediaInfo.width || -1,
  height: data.fileMeta.mediaInfo.height || -1,
  labelOk: !!data.annotationInfo?.isSkipped,
  objectCount: data.numOfLabelObjects ?? data.annotationInfo?.annotationObjects.length ?? 0,
  mimeType: data.fileMeta.mimeType,
}) : null)

export const transformAnnotObjects = (data: GetObjectsForFileResponse): AnnotationObjectData[] => data.annotationInfo?.annotationObjects.map((o) => {
  const common = {
    id: o._id || uuidv4(),
    label: o?.label?._id,
    createdAt: o.metadata?.createdAt || new Date(),
    modifiedAt: o.metadata?.modifiedAt || new Date(),
  }

  switch (o.type) {
    case ObjectToolType.RECTANGLE: {
      const t: AnnotationType = 'box'
      // konva 좌표계에 맞는 형식으로 변환해줌.
      // 회전하지 않은 tl(xtl, ytl), br(xbr, ybr)점을 사용하여
      // 회전한 점(x, y)과 사각형의 너비(width)와 높이(height)을 반환함.
      const {
        x: xtl,
        y: ytl,
        width: w,
        height: h,
      } = calcRotatedPointsForCanvas(o.region?.xtl, o.region?.ytl, o.region?.xbr, o.region?.ybr, o.region?.rotation)
      return {
        ...common,
        t,
        x: xtl,
        y: ytl,
        width: w,
        height: h,
        rotation: o.region?.rotation,
        isFixedRatio: o.isFixedRatio || false,
      }
    }
    case ObjectToolType.POLYGON: {
      const t: AnnotationType = 'polygon'
      return {
        ...common,
        t,
        points: o.region?.points?.split(';').map((p: string) => {
          const c = p.split(',')
          return {
            x: parseFloat(c[0]),
            y: parseFloat(c[1]),
          }
        }),
        isPathEditable: o.isPathEditable || false,
      }
    }
    case ObjectToolType.ELLIPSE: {
      const t: AnnotationType = 'ellipse'
      return {
        ...common,
        t,
        cx: o.region?.cx,
        cy: o.region?.cy,
        rx: o.region?.rx,
        ry: o.region?.ry,
        isFixedRatio: o.region?.isFixedRatio || false,
        rotation: o.region?.rotation,
      }
    }
    case ObjectToolType.TAG: {
      const t: AnnotationType = 'tag'
      return {
        ...common,
        t,
      }
    }
    default: {
      const { region = {}, ...others } = o
      return {
        ...others,
        id: o._id || uuidv4(),
        ...region,
      }
    }
  }
}) ?? []

export function getSettledFiles(state: Draft<AnnotationState>): InternalAnnotFileInfo[]
export function getSettledFiles(state: Draft<AnnotationState>, index: number): InternalAnnotFileInfo
export function getSettledFiles({ files }: Draft<AnnotationState>, index?: number) {
  const isInvalidIndex = index !== undefined && files[index] === null

  if ((files.length < 1 && process.env.NODE_ENV === 'development') || isInvalidIndex) {
    throw new Error(
      'Access to uninitialized annotation file is not allowed. '
      + `Annotation file of index ${index} is null or undefined. `
      + 'Check if the annotation file is properly loaded before access.'
    )
  }
  if (index !== undefined) {
    return files[index] as InternalAnnotFileInfo
  }
  return files as InternalAnnotFileInfo[]
}

export const getAnnotDefaultCondition = <A, E>(args: A, thunkAPI: ThunkConditionAPI<E>) => {
  if (thunkAPI.getState().annotation.isInProgress) {
    console.error('Another request is in progress')
    return false
  }
  return true
}
