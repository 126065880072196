import { validate as uuidValidate } from 'uuid'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { API } from '@/api'
import { InternalAnnotFileInfo, ThunkConfig } from '../types'
import { ObjectToolType } from '@/api/Annotation/types'
import { calcOriginalPointsForDB } from '@/pages/Lisa/Label/utils/canvasUtils'
import { invalidateQueryData } from '@/stores/slices/Lisa/annotation/queryData'
import { getAnnotDefaultCondition } from '@/stores/slices/Lisa/annotation/utils'

/** file 단위로 저장 핸들링 하기 위해 넣어둔 임시 코드 */
export const saveFile = createAsyncThunk<any, any, ThunkConfig>(
  'annotate/save-file',
  async ({ index }, thunkAPI) => {
    const { id, files, annotation } = thunkAPI.getState().annotation
    const annotId = id ?? ''
    const file = files[index]
    const fileId = file?.id || ''
    const history = annotation[fileId]
    const snapshotIndex = history.count - history.index
    // if (history.index <= 0) {
    //   return
    // }
    if (snapshotIndex < 0 || snapshotIndex > history.snapshot.length) {
      console.warn('unexpected snapshot index. ignore it')
      return
    }
    const snapshot = history.snapshot[snapshotIndex]
    const filteredObjects = snapshot.object.filter((o) => !o.isDeleted)
    const payload: any = {
      annotationId: annotId,
      datasetFileId: fileId,
      annotationObjects: filteredObjects.map(_serializeObject),
    }
    try {
      await API().Annotation.saveObjects(payload)
      invalidateQueryData.objects(annotId, fileId)
    } catch (e) {
      console.error(e)
    }
  },
  {
    condition: getAnnotDefaultCondition,
  }
)

export const saveProject = createAsyncThunk<any, any, ThunkConfig>(
  'annotate/save-project',
  async (args, thunkAPI) => {
    const { id, files, annotation, currentIndex } = thunkAPI.getState().annotation
    const annotId = id ?? ''
    const filesToSave = files.filter((f) => {
      const fileId = f?.id ?? ''
      const snapshot = annotation[fileId]

      return fileId && snapshot && snapshot.index > 0
    }) as InternalAnnotFileInfo[]

    // 1. 마지막 편집 이미지의 인덱스 저장
    // API().updateAnnotIndex(id, currentIndex)
    // 2. 이미지 파일별 데이터 저장
    await Promise.all(filesToSave.map(async (f) => {
      const fileId = f.id
      const history = annotation[fileId]
      const snapshotIndex = history.count - history.index

      if (history.index <= 0) {
        return
      }
      if (snapshotIndex < 0 || snapshotIndex > history.snapshot.length) {
        console.warn('unexpected snapshot index. ignore it')
        return
      }
      const snapshot = history.snapshot[snapshotIndex]
      const filteredObjects = snapshot.object.filter((o) => !o.isDeleted)
      const payload: any = {
        annotationId: annotId,
        datasetFileId: fileId,
        annotationObjects: filteredObjects.map(_serializeObject),
      }

      await API().Annotation.saveObjects(payload)
      invalidateQueryData.objects(annotId, fileId)
    }))
  },
  {
    condition: getAnnotDefaultCondition,
  }
)

const _serializeObject = (o: any) => {
  const common = {
    annotationClassId: o.label,
    annotationObjectId: uuidValidate(o.id) ? undefined : o.id,
    zOrder: 0,
  }
  const meta = {
    createdAt: o.createdAt,
    modifiedAt: o.modifiedAt,
  }

  switch (o.t) {
    case 'box': {
      /**
       * konva에서 사각형의 좌표 `x`, `y`는 `rotation`이 적용된 top-left 점이지만
       * backend에서의 `x`, `y`는 `rotation`이 0일 때의 top-left 점으로 다르기 때문에
       * `rotation`이 0일 때의 top-left 점으로 변환해 API 호출.
       */
      const {
        xtl, ytl, xbr, ybr,
      } = calcOriginalPointsForDB(o.x, o.y, o.width, o.height, o.rotation)
      return {
        ...common,
        // type: 2,
        type: ObjectToolType.RECTANGLE as keyof typeof ObjectToolType,
        isFixedRatio: o.isFixedRatio,
        isPathEditable: o.isPathEditable || false,
        region: {
          xtl,
          ytl,
          xbr,
          ybr,
          rotation: o.rotation,
        },
        metadata: { ...meta },
      }
    }
    case 'ellipse': {
      return {
        ...common,
        type: ObjectToolType.ELLIPSE as keyof typeof ObjectToolType,
        isFixedRatio: o.isFixedRatio,
        isPathEditable: o.isPathEditable,
        region: {
          cx: o.cx,
          cy: o.cy,
          rx: o.rx,
          ry: o.ry,
          rotation: o.rotation,
        },
        metadata: { ...meta },
      }
    }
    case 'polygon':
      return {
        ...common,
        type: ObjectToolType.POLYGON as keyof typeof ObjectToolType,
        region: { points: o.points.map((p: { x: any; y: any; }) => `${p.x},${p.y}`).join(';') },
        isFixedRatio: o.isFixedRatio,
        isPathEditable: o.isPathEditable,
        metadata: { ...meta },
      }
    case 'tag':
      return {
        ...common,
        type: ObjectToolType.TAG,
      }
    default:
      console.error('cannot reach here')
      return {
        annotationClassId: '',
        annotationId: '',
        datasetFileId: '',
        isFixedRatio: false,
        isPathEditable: false,
        metadata: undefined,
        region: undefined,
        type: ObjectToolType.RECTANGLE as keyof typeof ObjectToolType,
        zOrder: 0,
      }
  }
}
