import BaseApi from '@/api/Base/BaseApi'

import {
  AddClassGroupReferencePayload,
  CreateLabelingNodePayload,
  CreateObjectPayload,
  GetLabelingNodeItemsResponse,
  GetMagicWandObjectPayload,
  GetMagicWandObjectResponse,
  GetUserMetaResponse,
  GetUserShortcutsResponse,
  LabelingNodeInfo,
  LabelingNodeItem,
  LabelingObject,
  SaveObjectsPayload,
  UndoRedoResponse,
  UpdateAssignedLabelersPayload,
  UpdateLabelingNodePayload,
  UpdateLabelingNodeSettingsPayload,
  UpdateObjectPayload,
  UpdateUserMetaPayload,
  UpdateUserShortcutsPayload
} from '@/api/LabelingNode/types'
import { LabelingClass } from '@/api/ClassGroup/types'
import { CommonListPayload } from '@/api/DatasetNode/types'

const ROOT = '/labeling'
const defaultPaginationOptions = {
  itemsPerPage: 40,
  page: 0,
  sortDir: 'desc',
  sortKey: 'createdAt',
}

export default class LabelingNodeApi extends BaseApi {
  createLabelingNode(payload: CreateLabelingNodePayload) {
    const { projectId, ...rest } = payload
    return this.agent.post<LabelingNodeInfo>(`/project/${projectId}/labeling/create`, rest)
  }

  getNodeDetails(nodeId: string) {
    return this.agent.get<LabelingNodeInfo>(`${ROOT}/${nodeId}`)
  }

  updateNodeDetails(payload: UpdateLabelingNodePayload) {
    const { id, datasetNodeId, name, area } = payload
    const metadata = area ? { area } : undefined

    return this.agent.put<LabelingNodeInfo>(`${ROOT}/${id}`, { datasetNodeId, name, metadata })
  }

  updateNodeSettings(nodeId: string, payload: UpdateLabelingNodeSettingsPayload) {
    return this.agent.put<LabelingNodeInfo>(`${ROOT}/${nodeId}/setting`, payload)
  }

  updateAssignedLabelers(payload: UpdateAssignedLabelersPayload) {
    const { labelingNodeId, ...rest } = payload
    return this.agent.put(`${ROOT}/${labelingNodeId}/change-labeler`, rest)
  }

  addClassGroupReference(payload: AddClassGroupReferencePayload) {
    const { labelingNodeId, classGroupId } = payload
    return this.agent.put(`${ROOT}/${labelingNodeId}/classgroup`, { classGroupId })
  }

  removeClassGroupReference(labelingNodeId: string) {
    return this.agent.delete(`${ROOT}/${labelingNodeId}/classgroup`)
  }

  getFiles(nodeId: string, options?: CommonListPayload) {
    const { itemsPerPage, page, sortDir, sortKey } = options ?? defaultPaginationOptions
    return this.agent.get<GetLabelingNodeItemsResponse>(`${ROOT}/${nodeId}/items`, {
      params: {
        page,
        num_per_page: itemsPerPage,
        sort_key: sortKey,
        sort_dir: sortDir,
      },
    })
  }

  getFileDetails(sourceId: string) {
    return this.agent.get<LabelingNodeItem>(`/labeling-item/${sourceId}`)
  }

  toggleFileFinished(sourceId: string) {
    return this.agent.post<LabelingNodeItem>(`/labeling-item/${sourceId}/toggle-finished`)
  }

  // --------- LABELING ---------
  getUserClasses(labelingNodeId: string) {
    return this.agent.get<LabelingClass[]>(`${ROOT}/${labelingNodeId}/user/classes`)
  }

  updateUserClassOrder(labelingNodeId: string, classIds: string[]) {
    return this.agent.put<LabelingClass[]>(`${ROOT}/${labelingNodeId}/user/classes/reorder`, { classIds })
  }

  getObjectsOnFile(sourceId: string) {
    return this.agent.get<any>(`/labeling-item/${sourceId}/objects`)
  }

  saveObjects(payload: SaveObjectsPayload) {
    return this.agent.put<LabelingObject[]>(`${ROOT}/objects`, payload)
  }

  createObject(payload: CreateObjectPayload) {
    return this.agent.post<LabelingObject>(`${ROOT}/object`, payload)
  }

  updateObject(payload: UpdateObjectPayload) {
    return this.agent.put<LabelingObject>(`${ROOT}/object`, payload)
  }

  deleteObject(objectId: string) {
    return this.agent.delete<LabelingObject>(`/labeling-object/${objectId}`)
  }

  getMagicWandObject(payload: GetMagicWandObjectPayload) {
    const { labelingItemId, ...rest } = payload
    return this.agent.post<GetMagicWandObjectResponse>(`/labeling-item/${labelingItemId}/magic-wand`, rest)
  }

  getUserMeta(labelingNodeId: string) {
    return this.agent.get<GetUserMetaResponse>(`${ROOT}/${labelingNodeId}/user-meta`)
  }

  updateUserMeta(payload: UpdateUserMetaPayload) {
    return this.agent.post(`${ROOT}/user-meta`, payload)
  }

  undoAction(labelingItemId: string) {
    return this.agent.put<UndoRedoResponse>(`/labeling-item/${labelingItemId}/undo`)
  }

  redoAction(labelingItemId: string) {
    return this.agent.put<UndoRedoResponse>(`/labeling-item/${labelingItemId}/redo`)
  }

  finishLabeling(labelingNodeId: string) {
    return this.agent.post(`${ROOT}/${labelingNodeId}/finish-labeling`)
  }

  /** @deprecated */
  skipItem(sourceId: string) {
    return this.agent.post(`/labeling-item/${sourceId}/skip`)
  }

  // --------- shortcut ---------
  getUserShortcuts() {
    return this.agent.get<GetUserShortcutsResponse>(`${ROOT}/shortcut-key`)
  }

  updateUserShortcuts(payload: UpdateUserShortcutsPayload) {
    return this.agent.put(`${ROOT}/shortcut-key`, payload)
  }

  deleteLabelingNode(labelingNodeId: string) {
    return this.agent.delete(`${ROOT}/${labelingNodeId}`)
  }
}
