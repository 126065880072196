import BaseApi from '@/api/Base/BaseApi'
import {
  ActivateAccountPayload,
  CheckEmailVerificationPayload,
  LoginPayload,
  LoginResponse,
  SendEmailVerificationPayload,
  SignUpPayload,
  UpdateInfoPasswordPayload,
  UpdatePasswordPayload,
  UpdateUserPayload,
  UserInfo
} from '@/api/User/types'

const ROOT = '/user'

export default class UserApi extends BaseApi {
  login(payload: LoginPayload) {
    return this.agent.post<LoginResponse>(`${ROOT}/sign-in`, payload)
  }

  isAvailableEmail(email: string) {
    return this.agent.get<boolean>(`${ROOT}/is-available-email`, {
      params: { email },
    })
  }

  sendEmailVerification(payload: SendEmailVerificationPayload) {
    return this.agent.post(`${ROOT}/send-email-verification`, payload)
  }

  checkEmailVerification(payload: CheckEmailVerificationPayload) {
    return this.agent.post(`${ROOT}/check-email-verification`, payload)
  }

  signup(payload: SignUpPayload) {
    return this.agent.post(`${ROOT}/sign-up`, payload)
  }

  updatePassword(payload: UpdatePasswordPayload) {
    return this.agent.put(`${ROOT}/password`, payload)
  }

  getAllUsers() {
    return this.agent.get<UserInfo[]>(`${ROOT}/all`)
  }

  getUserInfo() {
    return this.agent.get<UserInfo>(`${ROOT}/info`)
  }

  updateUserInfo(payload: UpdateUserPayload) {
    const { name, phoneNumber, profileColor, profileImage } = payload
    const formData = new FormData()
    if (name) {
      formData.append('name', name)
    }
    if (phoneNumber) {
      formData.append('phoneNumber', phoneNumber)
    }
    if (profileColor) {
      formData.append('profileColor', profileColor)
    }
    if (profileImage) {
      formData.append('profileImage', profileImage)
    }
    return this.agent.put<UserInfo>(`${ROOT}/info`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }

  deleteProfileImage() {
    return this.agent.delete<UserInfo>(`${ROOT}/profile-image`)
  }

  updateInfoPassword(payload: UpdateInfoPasswordPayload) {
    return this.agent.put(`${ROOT}/info/change-password`, payload)
  }

  // CBT용 api
  activateAccount(payload: ActivateAccountPayload) {
    return this.agent.post(`${ROOT}/activate-account`, payload)
  }

  getIsUserActivated(email: string) {
    return this.agent.get<boolean>(`${ROOT}/is-activated`, {
      params: { email },
    })
  }
}
