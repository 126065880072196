export const clampMinMax = (value: number, min: number, max: number) => {
  if (value <= min) {
    return min
  }
  if (value >= max) {
    return max
  }
  return value
}

export const safeNumber = (value: number) => (Number.isNaN(value) || !Number.isFinite(value) ? 0 : value)

export const minAbs = (...numbers: number[]) => numbers.reduce((a, c) => (Math.abs(a) < Math.abs(c) ? a : c))

export const maxAbs = (...numbers: number[]) => numbers.reduce((a, c) => (Math.abs(a) > Math.abs(c) ? a : c))

export const divide = (a: number, b: number) => (b !== 0 ? a / b : 0)

export const hasNaN = (...numbers: number[]) => numbers.some(Number.isNaN)
