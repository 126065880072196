type SetLike<T> = Set<T> | ImmutableSet<T>

type ForEachCallback<T> = (value: T, key: T, set: Set<T>) => void

const ES_2024_SUPPORTED = !!Set.prototype.difference

const isSetLike = <T>(value: unknown): value is SetLike<T> => value instanceof Set || value instanceof ImmutableSet

const isImmutableSet = <T>(value: unknown): value is ImmutableSet<T> => value instanceof ImmutableSet

/**
 * 1. `Set` 자료 구조는 mutable하게 값을 변경하기 때문에 React에서 state로 사용할 때
 *   `new Set()`으로 새 Set 객체를 생성해줘야 하는 불편함이 있음.
 * 2. `Set`의 메서드에 따라 반환하는 타입이 다르는 불편함이 있음.(`add()`는 기존 Set 객체, `delete()`는 boolean)
 *
 * `ImmutableSet`은 `Set`을 Immutable하게 변경해 React에서 `Set`을 사용하는 state 업데이트 로직을 간단하게 작성할 수 있도록 도와줌.
 *
 * @note `Set`의 각 메서드별로 반환 타입이 다르므로 `ImmutableSet`은 `Set`을 상속받지 않음.
 * @example
 * ```ts
 * const [items, setItems] = useState(new ImmutableSet<string>())
 *
 * const handleAddItem = (id: string) => {
 *  setItems((prev) => prev.add(id))
 * }
 *
 * const handleDeleteItem = (id: string) => {
 *  setItems((prev) => prev.delete(id))
 * }
 * ```
 */
class ImmutableSet<T> {
  #set: Set<T>

  constructor(values?: Iterable<T>) {
    this.#set = new Set(values)
  }

  add(value: T) {
    /**
     * set에 변경 사항이 없다면 불필요하게 새 객체를 반환하지 않고 주소값이 그대로인 set 반환.
     * -> `ImmutableSet`의 역할을 벗어나는 게 아닐까? 고민 필요.
     * */
    if (this.#set.has(value)) {
      return this
    }
    return new ImmutableSet(new Set(this.#set).add(value))
  }

  delete(value: T) {
    if (!this.#set.has(value)) {
      return this
    }
    const updated = new Set(this.#set)

    updated.delete(value)
    return new ImmutableSet(updated)
  }

  clear() {
    if (this.#set.size < 1) {
      return this
    }
    const updated = new Set(this.#set)

    updated.clear()
    return new ImmutableSet(updated)
  }

  keys() {
    return this.#set.keys()
  }

  has(value: T) {
    return this.#set.has(value)
  }

  /** @warn `Set`에 존재하지 않는 메서드 */
  hasAny(...values: T[]) {
    return values.some((value) => this.#set.has(value))
  }

  union(values: T[]): ImmutableSet<T>

  union(values: SetLike<T>): ImmutableSet<T>

  union(values: T[] | SetLike<T>) {
    const size = isSetLike(values) ? values.size : values.length

    if (size < 1) {
      return this
    }
    return new ImmutableSet([...this.#set, ...values])
  }

  difference(values: T[]): ImmutableSet<T>

  difference(values: SetLike<T>): ImmutableSet<T>

  difference(values: T[] | SetLike<T>) {
    const valuesSet = isImmutableSet(values) ? values : new Set(values)

    if (valuesSet.size < 1) {
      return this
    }
    if (ES_2024_SUPPORTED) {
      return new ImmutableSet(this.#set.difference(valuesSet))
    }
    return new ImmutableSet([...this.#set].filter((value) => !valuesSet.has(value)))
  }

  symmetricDifference(values: T[]): ImmutableSet<T>

  symmetricDifference(values: SetLike<T>): ImmutableSet<T>

  symmetricDifference(values: T[] | SetLike<T>) {
    const valuesSet = isImmutableSet(values) ? values : new Set(values)
    const mergedSet = new Set([...this.#set, ...valuesSet])

    if (ES_2024_SUPPORTED) {
      return new ImmutableSet(this.#set.symmetricDifference(valuesSet))
    }
    return new ImmutableSet([...mergedSet].filter((value) => this.#set.has(value) !== valuesSet.has(value)))
  }

  intersection(values: T[]): ImmutableSet<T>

  intersection(values: SetLike<T>): ImmutableSet<T>

  intersection(values: T[] | SetLike<T>) {
    const valuesSet = isSetLike(values) ? values : new Set(values)
    const mergedSet = new Set([...this.#set, ...valuesSet])

    if (mergedSet.size === this.#set.size) {
      return this
    }
    if (ES_2024_SUPPORTED) {
      return new ImmutableSet(this.#set.intersection(valuesSet))
    }
    return new ImmutableSet([...this.#set].filter((value) => valuesSet.has(value)))
  }

  forEach(callback: ForEachCallback<T>, thisArg?: unknown) {
    this.#set.forEach(callback, thisArg)
  }

  get size() {
    return this.#set.size
  }

  [Symbol.iterator]() {
    return this.#set[Symbol.iterator]()
  }
}

export default ImmutableSet
