import { KonvaEventObject } from 'konva/lib/Node'
import { useRef } from 'react'

import { KonvaPoint2D, Point2D } from '@ahha/components/Canvas/types/point'
import { useDragShape } from '@ahha/components/Canvas/utils/hooks/useDragShape/useDragShape'

export const useDragRect = (enabled?: boolean) => {
  const { initialize, getDragDelta } = useDragShape(enabled)

  const initialPosition = useRef<KonvaPoint2D | null>(null)
  const isDragging = useRef(false)

  const initializeInfo = (e: KonvaEventObject<MouseEvent>) => {
    initialize(e)
    initialPosition.current = e.target.position()
    isDragging.current = true
  }

  const getPosition = (e: KonvaEventObject<MouseEvent>): Point2D => {
    const { x, y } = initialPosition.current ?? { x: 0, y: 0 }
    const [dX, dY] = getDragDelta(e)

    return [x + dX, y + dY]
  }

  const handleEndDrag = () => {
    isDragging.current = false
  }

  return {
    initialize: initializeInfo,
    getPosition,
    end: handleEndDrag,
    isDragging: () => isDragging.current,
  }
}
