import { createAsyncThunk } from '@reduxjs/toolkit'
import { API } from '@/api'
import { getSettledFiles, transformAnnotObjects } from '@/stores/slices/Lisa/annotation/utils'
import { queryClient } from '@/api/react-query/queryClient'
import { annotationKeys } from '@/api/react-query/queryKeys/annotation'
import { ThunkConfig, AnnotationObjectData } from '../types'

interface LoadAnnotObjectsParams {
  index: number;
  /**
   * 불필요한 API 요청을 보내지 않기 위해 현재 이미지의 스냅샷이 존재하면 `loadAnnotObjects`를 호출하더라도 API 요청을 보내지 않음.
   * 클래스 삭제 등의 동작으로 기존 스냅샷이 유효하지 않은 상태가 되면 스냅샷이 존재하더라도 API 요청을 보낼 수 있도록 함.
  */
  invalidate?: boolean;
}

export const loadAnnotationObjects = createAsyncThunk<AnnotationObjectData[] | undefined, LoadAnnotObjectsParams, ThunkConfig>(
  'annotate/load-annotation-objects',
  async ({ index }, thunkAPI) => {
    const state = thunkAPI.getState().annotation
    const { id, annotation: prevSnapshot } = state

    if (!id) {
      return []
    }

    const file = getSettledFiles(state, index)

    if (file.id in prevSnapshot) {
      thunkAPI.fulfillWithValue(undefined)
    }

    const object = await queryClient.ensureQueryData({
      queryKey: annotationKeys.objects(id, file.id),
      queryFn: () => API().Annotation.getObjectsForFile(id, file.id),
    })

    return transformAnnotObjects(object)
  }
  // {
  //   condition: ({ index, invalidate = false }, thunkAPI) => {
  //     const state = thunkAPI.getState().annotation
  //     const prevSnapshot = state.annotation
  //     const { id } = getSettledFiles(state, index)
  //
  //     if (invalidate) {
  //       return true
  //     }
  //     if (id in prevSnapshot) {
  //       return false
  //     }
  //     return true
  //   },
  // }
)
