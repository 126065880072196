import { Dependencies, EnhancedStateOptions, EnhancedStateParams, EnhancedStateReturn, InitialState, State, SyncWithPropsOptions, isArrayState } from '@ahha/utils/hooks/useEnhancedState/types'
import { distributeParams, resolveInitialValue, getNextState, getTypeOf, isArrayEqual, isSameValue } from '@ahha/utils/hooks/useEnhancedState/utils'

import { useCallback, useRef, useState } from 'react'

const MAX_INTEGER = Number.MAX_SAFE_INTEGER

const useSnapshot = <T>(initialValue: T) => {
  const snapshot = useRef({
    value: initialValue,
    type: getTypeOf(initialValue),
  })

  const takeSnapshot = (v: T) => {
    snapshot.current = { value: v, type: getTypeOf(v) }
  }

  return {
    snapshot: snapshot.current.value,
    snapshotType: snapshot.current.type,
    takeSnapshot,
  }
}

const useSyncWithProps = ({ deps, subscribe, select }: SyncWithPropsOptions) => {
  const prevDeps = useRef<Dependencies | undefined>(deps)

  if (deps && !isArrayEqual(prevDeps.current, deps, isSameValue)) {
    const data = select?.(...deps) ?? deps

    prevDeps.current = deps
    subscribe(data as Dependencies)
  }
}

export function useEnhancedState<T, D>(options: EnhancedStateOptions<T, D>): EnhancedStateReturn<T>
export function useEnhancedState<T>(initialValue: InitialState<T>): EnhancedStateReturn<T>
export function useEnhancedState<T, D = unknown[]>(initialValue: InitialState<T, D>, deps: D): EnhancedStateReturn<T>

export function useEnhancedState<T>(...params: EnhancedStateParams<T>): EnhancedStateReturn<T> {
  const {
    initialValue,
    compareFn,
    deps,
  } = distributeParams(params)

  const [, update] = useState(0)
  const prevState = useRef(resolveInitialValue(deps, initialValue))

  const { snapshot, snapshotType, takeSnapshot } = useSnapshot(prevState.current)

  useSyncWithProps({
    deps,
    subscribe: (d) => {
      prevState.current = resolveInitialValue(d, initialValue, prevState.current)
    },
  })

  const forceUpdate = () => update((p) => (p + 1) % MAX_INTEGER)

  const updateState = useCallback((next: State<T>, isSnapshot = false) => {
    const nextValue = getNextState(prevState.current, next)

    if (isSameValue(prevState.current, nextValue)) {
      return
    }
    if (isSnapshot) {
      takeSnapshot(nextValue)
    }

    prevState.current = nextValue === undefined ? snapshot : nextValue
    forceUpdate()
  }, [])

  const isStateEqual = (prev: T, next: T) => {
    if (isArrayState<T>(snapshotType, prev) && isArrayState<T>(snapshotType, next)) {
      return isArrayEqual(prev, next, (p, n) => !compareFn?.(p, n))
    }
    /* if (snapshotType === 'array') {
      return isArrayEqual(prev, next, (p, n) => !compareFn(p, n))
    } */
    if (compareFn) {
      return !compareFn(prev, next)
    }

    return isSameValue(prev, next)
  }

  // snapshot으로 저장한 값과 달라졌다면 true, 같다면 false
  const isMutated = useCallback(
    (next = prevState.current) => !isStateEqual(snapshot, next),
    []
  )

  return [prevState.current, updateState, isMutated]
}
