// redux-saga와 같이 쓸 수 있도록 임시로 구현해둔 auth slice
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type AuthType = {
  loginProcess: boolean,
  authToken: string | null;
  error: any;
}

const INITAL_AUTH: AuthType = {
  loginProcess: false,
  authToken: null,
  error: null,
}

export const auth = createSlice({
  name: 'auth',
  initialState: INITAL_AUTH,
  reducers: {
    loginStart(state, action: PayloadAction<{ userId: string, password?: string }>) {
      return {
        loginProcess: true,
        authToken: null,
        error: null,
      }
    },
    loginComplete(state, action: PayloadAction<{ authToken: string }>) {
      return {
        loginProcess: false,
        authToken: action.payload.authToken,
        error: null,
      }
    },
    loginError(state) {
      return {
        loginProcess: false,
        authToken: null,
        error: true,
      }
    },
  },
})

export const {
  loginStart,
  loginComplete,
  loginError,
} = auth.actions

export default auth.reducer
