import { UAParser } from 'ua-parser-js'
import { CornerAnchors } from '@ahha/components/Canvas/types/shape'

const { os } = new UAParser().getResult()

const isMac = os.name === 'Mac OS'

/** @deprecated 사실상 name이 id로서 사용되고 있으므로 prefix를 붙일 필요성이 적음. */
export const NAME_PREFIX = 'shape'

/** @deprecated */
export const GROUP_SEPARATOR = ':'

export const CHILDREN_SEPARATOR = '#'

export const POLYGON_POINT_NAME_PREFIX = (id: string) => `${id}${CHILDREN_SEPARATOR}`

export const ANCHOR_NAME_SUFFIX = 'anchor'

export const ROTATE_ANCHOR_PREFIX = 'rotater'

export const ANCHOR_ALIAS_NAME: Record<string, string> = {
  'top-left': 'top-left _anchor',
  'top-center': 'top-center _anchor',
  'middle-left': 'middle-left _anchor',
  'bottom-center': 'bottom-center _anchor',
  'bottom-right': 'bottom-right _anchor',
  'middle-right': 'middle-right _anchor',
  'top-right': 'top-right _anchor',
  'bottom-left': 'bottom-left _anchor',
}

export const PINNED_ANCHOR_ALIAS_NAME: Record<CornerAnchors, string> = {
  br: 'bottom-right _anchor',
  bl: 'bottom-left _anchor',
  tl: 'top-left _anchor',
  tr: 'top-right _anchor',
}

export const PINNED_ANCHOR: Record<string, CornerAnchors> = {
  'top-left': 'br',
  'top-center': 'bl',
  'middle-left': 'br',
  'bottom-center': 'tl',
  'bottom-right': 'tl',
  'middle-right': 'bl',
  'top-right': 'bl',
  'bottom-left': 'tr',
}

export const SHAPE_HOT_KEYS = {
  polygon: {
    finish: 'Enter',
    undo: isMac ? 'Cmd+KeyZ' : 'Ctrl+KeyZ',
    redo: isMac ? 'Cmd+Shift+KeyZ' : 'Ctrl+Shift+KeyZ',
  },
} as const
