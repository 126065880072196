import { ValueOf } from '@ahha/utils/@types/typeUtils'
import { FieldColumnType, FieldType } from '@/pages/DataCamp/Database/types'

export const AvailableConditionals = {
  AND: '$and',
  OR: '$or',
}

export type ConditionalType = ValueOf<typeof AvailableConditionals>

// FILTER
export type FilterRowType = {
  id: string,
  field: string,
  operator: any,
  value: any
}
export type FilterBlockType = { rows: FilterRowType[], conditional: ConditionalType }
export type FilterListType = FilterBlockType[]

// ADVANCED FILTER
export type AdvancedFieldConfigType = {
  fieldId: string,
  fieldType: ValueOf<typeof FieldType> | string,
  operator: string,
  value: string | number | any,
  newName: string,
}

export type AdvancedFilterConfigType = {
  functionType: string,
  fields: AdvancedFieldConfigType[],
  group: Omit<AdvancedFieldConfigType, 'newName'>,
}

export type QueryConfigType = {
  referenceSetting: {
    startDay: number;
    startHour: number;
  },
  filter: {
    list: FilterListType,
    condition: ConditionalType,
  };
  advancedFilter: AdvancedFilterConfigType,
  sortList: { field: string, sort: number }[];
  fields: FieldColumnType[];
  // TODO: remove optional flag
  sourceCollection?: { id: string, name: string };
}

export const INITIAL_QUERY_CONFIG: QueryConfigType = {
  referenceSetting: { startDay: 0, startHour: 0 },
  filter: { list: [], condition: '$and' },
  advancedFilter: { functionType: '', fields: [], group: { fieldId: '', fieldType: '', operator: '', value: '' } },
  sortList: [],
  fields: [], // index, sort, fields
  sourceCollection: { id: '', name: '' },
}

// STORE
export type AppliedQueryConfig = {
  id?: string,
  name: string,
  pipeline: string, // result from JSON.stringify(buildPipeline(...))
  config: QueryConfigType,
}
