import { TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'

export type TKeyOptions = {
  [key: string]: unknown
}

export type TWrappedFunction = (t: TFunction) => string

export type TKey = TWrappedFunction | string

const isTKey = (k: string): k is string => /(\w+_[^\s])+/.test(k)

export const useI18Next = () => {
  const { t: i18t } = useTranslation()

  const t = (key: TKey, options?: TKeyOptions) => {
    // 1. (t) => t('some.kind.of.key')
    if (typeof key === 'function') {
      return key(i18t)
    }
    // 2. 'some.kind.of.key'
    if (isTKey(key)) {
      return i18t(key, options)
    }

    // 3. 'some plain text'
    return key
  }

  return { t }
}
