import { RefObject, useEffect } from 'react'

interface Options {
  refs: RefObject<HTMLElement>[],
  onUnmount: (() => void) | ((e: MouseEvent) => void),
  enabled?: boolean
  /**
   * `true`: refs 안에 있는 요소를 클릭했을 때 언마운트
   * `false`: refs 밖에 있는 요소를 클릭했을 때 언마운트
   */
  invert?: boolean
  when?: 'mousedown' | 'mouseup'
}

type UseUnmountOnBlurParams =
  | [RefObject<HTMLElement>[], (() => void)
  | ((e: MouseEvent) => void), Omit<Options, 'refs' | 'onUnmount'>?]
  | [Options]

export function useUnmountOnBlur(
  refs: RefObject<HTMLElement>[],
  onUnmount: (() => void) | ((e: MouseEvent) => void),
  options?: Omit<Options, 'refs' | 'onUnmount'>
): void
export function useUnmountOnBlur(options: Options): void

export function useUnmountOnBlur(...params: UseUnmountOnBlurParams) {
  const {
    refs,
    enabled = true,
    invert,
    onUnmount,
    when = 'mouseup',
  } = distributeParams(params)

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      const { target } = e
      if (!isElement(target) || !enabled) return

      /* refs 중 하나 이상의 요소가 DOM에서 제거가 가능한 경우(ex. MUI backdrop) */
      const mountedRefs = refs.filter(({ current }) => !!current)
      const isBlurred = mountedRefs.length > 0
        && mountedRefs.every(({ current }) => {
          if (invert) {
            return current?.contains(target)
          }
          return !current?.contains(target)
        })

      if (isBlurred) {
        onUnmount(e)
      }
    }

    document.addEventListener(when, handleClickOutside)

    return () => document.removeEventListener(when, handleClickOutside)
  }, [onUnmount, enabled])
}

const DEFAULT_OPTIONS = {
  refs: [],
  onUnmount: () => null,
  invert: false,
}

function isElement(target: EventTarget | null): target is Element {
  return target instanceof Element
}

const distributeParams = (params: UseUnmountOnBlurParams) => {
  const [p, q, r] = params

  if (Array.isArray(p) && typeof q === 'function') {
    const { invert } = r || {}

    return {
      refs: p,
      onUnmount: q,
      invert,
    }
  }
  if (!Array.isArray(p) && !q && !r) {
    return p
  }

  return DEFAULT_OPTIONS
}
