import { isArray, isFunction, isPlainObject } from '@ahha/utils/@types/typeChecks'
import { Dependencies, EnhancedStateParams, InitialState, State, isOptionParam } from '@ahha/utils/hooks/useEnhancedState/types'

export const getTypeOf = <T>(value: T) => {
  if (isArray(value)) {
    return 'array'
  }
  if (isPlainObject(value)) {
    return 'object'
  }
  if (value === null) {
    return 'null'
  }

  return typeof value
}

export const getNextState = <T>(prev: T, next: State<T>) => {
  if (isFunction(next)) {
    return next(prev)
  }
  return next
}

export const resolveInitialValue = <T, D>(
  deps: D | undefined,
  initialValue: InitialState<T, D>,
  prevState?: T
) => (isFunction(initialValue) ? initialValue(deps, prevState) : initialValue)

export const isArrayEqual = <T>(
  prev: T[] | undefined,
  next: T[] | undefined,
  compareFn: (nextItem: T, prevItem: T) => boolean = isSameValue
) => {
  if (!prev || !next) {
    return false
  }
  if (prev.length !== next.length) {
    return false
  }
  return next.every((e, i) => compareFn(e, prev[i]))
}

export const isSameValue = <T>(prev: T, next: T) => Object.is(prev, next)

export const distributeParams = <T>(params: EnhancedStateParams<T>) => {
  const [p0, p1] = params

  if (isOptionParam<T>(p0)) {
    return p0
  }
  if (isArray(p1)) {
    return {
      initialValue: p0,
      deps: p1,
    }
  }
  /* if (isFunction(p1)) {
    return {
      initialValue: p0,
      compareFn: p1,
      deps: p2,
    }
  } */

  return { initialValue: p0 }
}
