import React from 'react'
import { Navigate, RouteObject } from 'react-router-dom'
import loadable from '@loadable/component'

import { SiteUrls } from '@/routes/siteUrls'
import { pipelineLoader } from '@/routes/loader/pipeline'

const MainLayout = loadable(() => import('@/layouts/MainLayout'))
const NotSignedInLayout = loadable(() => import('@/layouts/NotSignedInLayout'))
const LandingPage = loadable(() => import('@/pages/landing/LandingPage'))

// Login
const LoginLayout = loadable(() => import('@/pages/Login/LoginLayout'))
const LoginMain = loadable(() => import('@/pages/Login/Login'))
const SignUp = loadable(() => import('@/pages/Login/SignUp'))
const RecoverPassword = loadable(() => import('@/pages/Login/RecoverPassword'))
const ExpiredUrl = loadable(() => import('@/pages/Login/ExpiredUrl'))

// Workspace
const NoWorkspace = loadable(() => import('@/pages/Workspace/NoWorkspace'))
const JoinWorkspace = loadable(() => import('@/pages/Workspace/Join'))
const CreateWorkspace = loadable(() => import('@/pages/Workspace/Create'))
const WorkspaceMain = loadable(() => import('@/pages/Workspace/Main'))
const WorkspaceSettings = loadable(() => import('@/pages/Workspace/Settings'))
const WorkspaceNotificationSettings = loadable(() => import('@/pages/Workspace/NotificationSettings'))
const WorkspaceMembers = loadable(() => import('@/pages/Workspace/Members'))
const WorkspacePricing = loadable(() => import('@/pages/Workspace/Pricing'))

// Project
const Projects = loadable(() => import('@/pages/Project/Projects'))

// dashboard
const DashboardEdit = loadable(() => import('@/pages/DataCamp/Dashboard/Edit'))
const DashboardMain = loadable(() => import('@/pages/DataCamp/Dashboard/Main'))
const WidgetConfig = loadable(() => import('@/pages/DataCamp/Dashboard/WidgetConfig/WidgetConfig'))

// Lisa
const DatasetList = loadable(() => import('@/pages/Lisa/Dataset/List'))
const DatasetCreate = loadable(() => import('@/pages/Lisa/Dataset/Create/Create'))
const DatasetDetail = loadable(() => import('@/pages/Lisa/Dataset/Detail/Detail'))

// const ValidationResult = loadable(() => import('@/pages/Lisa/Validation/Result'))

const LiveDashboard = loadable(() => import('@/pages/Lisa/Live/Dashboard'))
const LiveResult = loadable(() => import('@/pages/Lisa/Live/Result/Result'))

// model
const ModelList = loadable(() => import('@/pages/Lisa/Model/List'))
const ModelCreate = loadable(() => import('@/pages/Lisa/Model/Create'))
const ModelDetail = loadable(() => import('@/pages/Lisa/Model/Detail'))
// label
// const LabelList = loadable(() => import('@/pages/Lisa/Label/List'))
const LabelDetail = loadable(() => import('@/pages/Lisa/Label/AnomalyDetection/Detail'))
const Classification = loadable(() => import('@/pages/Lisa/Label/Classification/Detail'))
const ObjectDetection = loadable(() => import('@/pages/Lisa/Label/ObjectDetection/Detail'))
const LabelResult = loadable(() => import('@/pages/Lisa/Label/Validation/Result'))

// DataStorage
const Storage = loadable(() => import('@/pages/Storage/Storage'))

// Database
const DatabaseList = loadable(() => import('@/pages/DataCamp/Database/List'))
const CollectionList = loadable(() => import('@/pages/DataCamp/Database/Collections/List'))
const CollectionCreate = loadable(() => import('@/pages/DataCamp/Database/Collections/Create'))
const CollectionDetail = loadable(() => import('@/pages/DataCamp/Database/Collections/Detail'))

// Task
const TaskManagement = loadable(() => import('@/pages/Lisa/Task/Management'))

const Pipeline = loadable(() => import('@/pages/Pipeline/Pipeline'))

const LabelingAnom = loadable(() => import('@/pages/Labeling/AnomalyDetection/Detail'))
const LabelingCls = loadable(() => import('@/pages/Labeling/Classification/Detail'))
const LabelingObjDet = loadable(() => import('@/pages/Labeling/ObjectDetection/Detail'))

const ValidationResult = loadable(() => import('@/pages/Result/ValidationResult'))
const InferenceResult = loadable(() => import('@/pages/Result/InferenceResult'))

// user settings
const UserSettings = loadable(() => import('@/pages/User/UserSettings'))

// new project
const ProjectMain = loadable(() => import('@/pages/Project/Main'))
const ProjectSettings = loadable(() => import('@/pages/Project/ProjectSettings'))
const ProjectNotificationSettings = loadable(() => import('@/pages/Project/NotificationSettings'))

const routes = (isSignIn?: boolean): RouteObject[] => [
  {
    path: SiteUrls.Landing,
    // element: isSignIn ? <Navigate to={SiteUrls.Main._} replace /> : <NotSignedInLayout />,
    element: <NotSignedInLayout />,
    children: [
      {
        path: '',
        element: <LandingPage />,
      },
      {
        path: SiteUrls.Login._,
        element: <LoginLayout />,
        children: [
          {
            path: SiteUrls.Login._,
            element: <LoginMain />,
          },
          {
            path: SiteUrls.Login.SignUp,
            element: <SignUp />,
          },
          {
            path: SiteUrls.Login.RecoverPassword,
            element: <RecoverPassword />,
          },
          {
            path: SiteUrls.Login.ExpiredInvitation,
            element: <ExpiredUrl />,
          },
        ],
      },
    ],
  },
  {
    path: SiteUrls.Main._,
    // element: isSignIn ? <MainLayout /> : <Navigate to={SiteUrls.Login._} replace />,
    element: <MainLayout />,
    children: [
      {
        path: SiteUrls.Main.Workspace.Join,
        element: <JoinWorkspace />,
      },
      {
        path: SiteUrls.Main.Workspace.Create,
        element: <CreateWorkspace />,
      },
      {
        path: SiteUrls.Main.Workspace.NoWorkspace,
        element: <NoWorkspace />,
      },
      {
        path: SiteUrls.Main.Workspace.Main,
        element: <WorkspaceMain />,
        children: [
          {
            path: SiteUrls.Main.Workspace.Settings,
            element: <WorkspaceSettings />,
          },
          {
            path: SiteUrls.Main.Workspace.NotificationSettings,
            element: <WorkspaceNotificationSettings />,
          },
          {
            path: SiteUrls.Main.Workspace.Projects,
            element: <Projects />,
          },
          {
            path: SiteUrls.Main.Workspace.Members,
            element: <WorkspaceMembers />,
          },
          {
            path: SiteUrls.Main.Workspace.Pricing,
            element: <WorkspacePricing />,
          },
          {
            path: SiteUrls.Main.Workspace.Storage._,
            element: <Storage />,
          },
          {
            path: SiteUrls.Main.Workspace.Storage.folder(':id'),
            element: <Storage />,
          },
        ],
      },
      {
        path: SiteUrls.Main.Workspace.Invited(':invitationCode'),
        element: <WorkspaceMain />,
      },
      {
        path: SiteUrls.Main.Project.Main(':projectId'),
        element: <ProjectMain />,
        children: [
          {
            path: SiteUrls.Main.Project.Settings(':projectId'),
            element: <ProjectSettings />,
          },
          {
            path: SiteUrls.Main.Project.NotificationSettings(':projectId'),
            element: <ProjectNotificationSettings />,
          },
          {
            path: SiteUrls.Main.Project.Pipeline(':projectId'),
            loader: pipelineLoader,
            element: <Pipeline />,
          },
          {
            path: SiteUrls.Main.Project.ModelManagement(':projectId'),
            element: <p>모델 관리</p>,
          },
          {
            path: SiteUrls.Main.Project.Dashboard(':projectId'),
            element: <p>대시보드</p>,
          },
        ],
      },
      // FIXME: 걷어내기
      {
        path: '',
        element: <Projects />,
      },
      {
        path: SiteUrls.Main.Dashboard._,
        element: <DashboardMain />,
      },
      {
        path: SiteUrls.Main.Dashboard.Edit,
        element: <DashboardEdit />,
      },
      {
        path: SiteUrls.Main.Dashboard.WidgetConfig,
        element: <WidgetConfig />,
      },
      {
        path: SiteUrls.Main.Lisa.Dataset._,
        element: <DatasetList />,
      },
      {
        path: SiteUrls.Main.Lisa.Dataset.Create,
        element: <DatasetCreate />,
      },
      {
        path: SiteUrls.Main.Lisa.Dataset.Detail(':type', ':id'),
        element: <DatasetDetail />,
      },
      // {
      //   path: SiteUrls.Main.Lisa.Validation,
      //   element: <ValidationResult />,
      // },
      {
        path: SiteUrls.Main.Lisa.Live.dashboard,
        element: <LiveDashboard />,
      },
      {
        path: SiteUrls.Main.Lisa.Live.result,
        element: <LiveResult />,
      },
      {
        path: SiteUrls.Main.Lisa.Model._,
        element: <ModelList />,
      },
      {
        path: SiteUrls.Main.Lisa.Model.Create,
        element: <ModelCreate />,
      },
      {
        path: SiteUrls.Main.Lisa.Model.Detail(':id'),
        element: <ModelDetail />,
      },
      // {
      //   path: SiteUrls.Main.Lisa.Label._,
      //   element: <LabelList />,
      // },
      {
        path: SiteUrls.Main.Lisa.Label.Detail(':id'),
        element: <LabelDetail />,
      },
      {
        path: SiteUrls.Main.Lisa.Label.DetailClassification(':id'),
        element: <Classification />,
      },
      {
        path: SiteUrls.Main.Lisa.Label.DetailObjDet(':id'),
        element: <ObjectDetection />,
      },
      {
        path: SiteUrls.Main.Lisa.Label.Result(':modelId', ':evaluationId'),
        element: <LabelResult />,
      },
      // {
      //   path: SiteUrls.Main.Storage._,
      //   element: <Storage />,
      // },
      // {
      //   path: SiteUrls.Main.Storage.folder(':id'),
      //   element: <Storage />,
      // },
      {
        path: SiteUrls.Main.Database._,
        element: <DatabaseList />,
      },
      {
        path: SiteUrls.Main.Database.Collections(':id'),
        element: <CollectionList />,
      },
      {
        path: SiteUrls.Main.Database.CollectionsCreate(':id'),
        element: <CollectionCreate />,
      },
      {
        path: SiteUrls.Main.Database.CollectionsDetail(':databaseId', ':collectionId'),
        element: <CollectionDetail />,
      },
      {
        path: SiteUrls.Main.Lisa.Task.Management,
        element: <TaskManagement />,
      },
      // // FIXME: 걷어내기
      // {
      //   path: SiteUrls.Main.Project.Detail(':projectId'),
      //   loader: pipelineLoader,
      //   element: <Pipeline />,
      // },
      {
        path: SiteUrls.Main.Labeling.Anomaly(':id'),
        element: <LabelingAnom />,
      },
      {
        path: SiteUrls.Main.Labeling.Classification(':id'),
        element: <LabelingCls />,
      },
      {
        path: SiteUrls.Main.Labeling.ObjDet(':id'),
        element: <LabelingObjDet />,
      },
      {
        path: SiteUrls.Main.Result.Validation(':projectId', ':nodeId'),
        element: <ValidationResult />,
      },
      {
        path: SiteUrls.Main.Result.Inference(':projectId', ':nodeId'),
        element: <InferenceResult />,
      },
      {
        path: SiteUrls.Main.User.Settings,
        element: <UserSettings />,
      },
    ],
  },
  {
    path: '*',
    element: <Navigate to={SiteUrls.Main._} replace />,
  },
]

export default routes
