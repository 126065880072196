import { ReactNode, createContext } from 'react'
import { useStageStore } from '@ahha/components/Canvas/utils/Context/Stage/useStageStore'
import { hasProperty } from '@ahha/utils/@types/typeChecks'

export type CanvasMode = 'observe-source' | 'sandbox'

interface CommonProps {
  mode?: CanvasMode
  children: ReactNode
}

export interface SourceObserverProps extends CommonProps {
  mode?: 'observe-source'
  source: string
}

export interface SandboxObserverProps extends CommonProps {
  mode: 'sandbox'
  sandboxArea: { W: number, H: number }
}

type StageProviderProps = SourceObserverProps | SandboxObserverProps

export type StageStoreReturns = ReturnType<typeof useStageStore>

export const StageStoreContext = createContext<StageStoreReturns | null>(null)

export const StageContext = ({
  mode = 'observe-source',
  children,
  ...rest
}: StageProviderProps) => {
  const src = hasProperty(rest, 'source') ? rest.source : undefined
  const sandboxArea = hasProperty(rest, 'sandboxArea') ? rest.sandboxArea : undefined

  return (
    <StageStoreContext.Provider value={useStageStore({ mode, src, sandboxArea })}>
      {children}
    </StageStoreContext.Provider>
  )
}

StageStoreContext.displayName = 'StageContext'
