import { KonvaEventObject } from 'konva/lib/Node'
import { useState } from 'react'

import { useDragShape } from '@ahha/components/Canvas/utils/hooks/useDragShape/useDragShape'

export const useDragPolygon = (enabled?: boolean) => {
  const { initialize, getDragDelta } = useDragShape(enabled, true)

  const [isDragging, setIsDragging] = useState(false)

  const initializeInfo = (e: KonvaEventObject<DragEvent>) => {
    initialize(e)
    setIsDragging(true)
  }

  const endDrag = () => setIsDragging(false)

  const resetState = (e: KonvaEventObject<DragEvent>) => {
    e.target.position({ x: 0, y: 0 })
  }

  return {
    isDragging,
    initialize: initializeInfo,
    reset: resetState,
    endDrag,
    getDelta: getDragDelta,
  }
}
