/* eslint-disable no-param-reassign */
import { isElement, isFunction, isNotNullish } from '@ahha/utils/@types/typeChecks'
import { MutableRefObject, RefCallback } from 'react'

type MutableRefList<T> = (RefCallback<T> | MutableRefObject<T> | undefined | null)[];

/** DOM에서 현재 포커스되어 있는 HTMLElement를 반환. 없다면 null을 반환. */
export const getActiveElement = () => (isElement(document.activeElement) ? document.activeElement : null)

/** DOM 요소에 설정한 `data-*` 속성값을 가져옴. */
export const getElementData = (element: HTMLElement | null, key: string) => element?.dataset[key]

export const isElementTagOf = (
  element: EventTarget | HTMLElement | null,
  tag: string
) => isElement(element) && element?.tagName.toLowerCase() === tag.toLowerCase()

export const mergeRefs = <T, >(...refs: MutableRefList<T>): RefCallback<T> => (node: T) => setRef(node, ...refs)

export const setRef = <T, >(node: T, ...refs: MutableRefList<T>) => {
  refs.forEach((ref) => {
    if (isFunction(ref)) {
      ref(node)
    } else if (isNotNullish(ref)) {
      ref.current = node
    }
  })
}
