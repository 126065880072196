import { RefObject, useCallback, useEffect, useRef } from 'react'
import Konva from 'konva'
import { KonvaEventObject } from 'konva/lib/Node'

import useStageCursor from '@ahha/components/Canvas/utils/hooks/useCursors/useStageCursor'
import { registerEvent, unregisterEvent } from '@ahha/components/Canvas/utils/hooks/useCursors/utils'
import { useFlagMode } from '@ahha/components/Canvas/utils/hooks/useCursors/useFlagMode'
import { isAnchor, isRotateAnchor } from '@ahha/components/Canvas/utils/konva'

interface ResizeCursorParams {
  enabled: boolean
  transformer: RefObject<Konva.Transformer>
}

const ANCHOR_CURSOR_MAP = {
  'top-left': 'nwse-resize',
  'top-center': 'ns-resize',
  'top-right': 'nesw-resize',
  'middle-left': 'ew-resize',
  'middle-right': 'ew-resize',
  'bottom-left': 'nesw-resize',
  'bottom-center': 'ns-resize',
  'bottom-right': 'nwse-resize',
} as const

export const useResizeCursor = ({
  enabled,
  transformer,
}: ResizeCursorParams) => {
  const [setCursor, revertCursor, stage] = useStageCursor()
  const [isResizing, startResize, endResize] = useFlagMode()

  const activeCursor = useRef('')
  const startAnchor = useRef('')

  const isRotateAnchorActive = () => {
    const activeAnchor = transformer.current?.getActiveAnchor() ?? ''

    return isRotateAnchor(activeAnchor)
  }

  const setResizeCursorInfo = useCallback(() => {
    const anchorCursor = stage.content.style.cursor

    if (isRotateAnchorActive()) {
      return
    }
    if (anchorCursor !== 'default') {
      activeCursor.current = anchorCursor
    }
    startResize()
  }, [])

  const setActiveAnchor = useCallback((e: KonvaEventObject<MouseEvent>) => {
    if (!isResizing()) {
      startAnchor.current = e.target.name()
    }
  }, [])

  const setResizeCursor = useCallback(() => {
    setCursor(activeCursor.current)
  }, [])

  const revertResizeCursor = useCallback((e: KonvaEventObject<MouseEvent>) => {
    /** Stage 이벤트 핸들러이므로 resize transform이 아닐 경우 아무 작업도 하지 않음 */
    if (!isResizing()) {
      return
    }
    const targetName = e.target.name()
    const endAnchor = isAnchor(targetName) ? targetName : ''
    const shouldRevert = !endAnchor || endAnchor !== startAnchor.current

    if (shouldRevert) {
      revertCursor()
      startAnchor.current = ''
    } else {
      setCursor(activeCursor.current)
    }
    endResize()
  }, [])

  /* TODO:ksh: transformend 후 커서가 다른 anchor 위에 있으면 해당 anchor 커서로 변경하기 - 2024.02.21 */
  useEffect(() => {
    const tr = transformer.current

    if (enabled && tr) {
      registerEvent(tr, 'transformstart', setResizeCursorInfo)
      registerEvent(tr, 'transform', setResizeCursor)
      registerEvent(stage, 'mouseup', revertResizeCursor)

      /** transform 종료 후에도 커서가 transformstart anchor 위에 존재하면 커서를 유지할 수 있도록 activeAnchor 저장 */
      registerEvent(tr, 'mouseover', setActiveAnchor)
    }

    return () => {
      unregisterEvent(tr, 'transformstart', setResizeCursorInfo)
      unregisterEvent(tr, 'transform', setResizeCursor)
      unregisterEvent(stage, 'mouseup', revertResizeCursor)
      unregisterEvent(tr, 'mouseover', setActiveAnchor)
    }
  }, [enabled])
}
