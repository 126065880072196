import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '@/stores/slices/Labeling/types'
import { API } from '@/api'

const loadAllLabelingItems = async (labelingNodeId: string, length: number) => {
  const MAX_FILES_PER_PAGE = 50
  const itemsPerPage = Math.min(length, MAX_FILES_PER_PAGE)
  const MAX_PAGE = Math.ceil(length / itemsPerPage)

  const result = await Promise.all(
    Array.from({ length: MAX_PAGE }, (_, page) => API().Labeling.getFiles(labelingNodeId, {
      page,
      itemsPerPage,
    }))
  )

  return result.flatMap((f) => f.list)
}

export const loadLabelingItems = createAsyncThunk<any, string, ThunkConfig>(
  'labeling/load-labeling-items',
  async (labelingNodeId, thunkAPI) => {
    const prevFiles = thunkAPI.getState().labeling.files
    const labelingItems = await loadAllLabelingItems(labelingNodeId, prevFiles.length)

    return prevFiles.map((f, i) => (labelingItems[i] || f))
  }
)

/**
 * 이미지를 모두 불러온 후,
 * Annotation(이미지를 넘길 때마다 history 초기화)과
 * Classification(모든 이미지의 history를 초기화)에서의 동작이 달라 별도의 action으로 분리
 *  */
export const loadClassificationItems = createAsyncThunk<any, string, ThunkConfig>(
  'labeling/load-classification-items',
  async (labelingNodeId, thunkAPI) => {
    const prevFiles = thunkAPI.getState().labeling.files
    const labelingItems = await loadAllLabelingItems(labelingNodeId, prevFiles.length)

    return prevFiles.map((f, i) => (f || labelingItems[i]))
  }
)
