import { I18nAvailableLanguage } from '@ahha/i18n/i18n'

import dictKo from '@/assets/dictionary/ko.json'
import dictEn from '@/assets/dictionary/en.json'

const i18nConfig: I18nAvailableLanguage[] = [
  {
    language: 'en',
    dictionary: dictEn,
    language_display_name: 'English',
  },
  {
    language: 'ko',
    dictionary: dictKo,
    language_display_name: '한국어',
  },
]

export default i18nConfig
