import BaseApi from '@/api/Base/BaseApi'

import {
  CheckNewResponse,
  GetNotificationListPayload,
  GetNotificationListResponse,
  Notification, ProjectNotificationSettings,
  UpdateProjectNotificationSettingsPayload,
  UpdateWorkspaceNotificationSettingsPayload,
  WorkspaceNotificationSettings
} from '@/api/Notification/types'

const ROOT = '/notification'
const COUNT_PER_PAGE = 20

export default class NotificationApi extends BaseApi {
  getNotificationList(payload: GetNotificationListPayload) {
    const { page, itemsPerPage, sortKey, sortDir, isRead } = payload
    return this.agent.get<GetNotificationListResponse>(`${ROOT}/list`, {
      params: {
        isRead,
        page,
        num_per_page: itemsPerPage ?? COUNT_PER_PAGE,
        sort_key: sortKey,
        sort_dir: sortDir,
      },
    })
  }

  markAsRead(notiId: string) {
    return this.agent.post<Notification>(`${ROOT}/${notiId}/read`)
  }

  markAllAsRead() {
    return this.agent.post(`${ROOT}/read-all`)
  }

  checkNew() {
    return this.agent.get<CheckNewResponse>(`${ROOT}/check-new`)
  }

  getWorkspaceNotificationSettings(workspaceId: string) {
    return this.agent.get<WorkspaceNotificationSettings>(`${ROOT}/workspace/${workspaceId}/settings`)
  }

  updateWorkspaceNotificationSettings(payload: UpdateWorkspaceNotificationSettingsPayload) {
    const { workspaceId, ...rest } = payload
    return this.agent.put(`${ROOT}/workspace/${workspaceId}/settings`, rest)
  }

  getProjectNotificationSettings(projectId: string) {
    return this.agent.get<ProjectNotificationSettings>(`${ROOT}/project/${projectId}/settings`)
  }

  updateProjectNotificationSettings(payload: UpdateProjectNotificationSettingsPayload) {
    const { projectId, ...rest } = payload
    return this.agent.put(`${ROOT}/project/${projectId}/settings`, rest)
  }
}
