export const isPlainObject = <T extends Record<string, unknown>>(value: unknown): value is T => value?.constructor === Object

export const isFunction = (value: unknown): value is (...args: any[]) => any => typeof value === 'function'

export const isArray = <T>(value: unknown): value is T[] => Array.isArray(value)

export const isString = (value: unknown): value is string => typeof value === 'string'

export const isNumber = (value: unknown): value is number => typeof value === 'number'

export const isNullish = <T>(value: T | null | undefined): value is null | undefined => value === null || value === undefined

export const isNotNullish = <T>(value: T): value is Exclude<T, null | undefined> => value !== null && value !== undefined

export const isPromise = <T>(value: unknown): value is Promise<T> => value instanceof Promise

export const isKeyOf = <T extends Record<string, unknown>>(key: PropertyKey | undefined, obj: T): key is keyof T => {
  if (!key) {
    return false
  }
  return key in obj
}

export const isElement = (value: unknown): value is Element => value instanceof Element
