// import Transformable from './transformers/Transformable'

// const affixHexPrefix = (color) => {
//   if (!color || color.startsWith('#')) return color

//   return `#${color}`
// }

// export const transformAnnotClasses = (classes) => classes
//   .filter(({ name }) => name !== 'background')
//   .map((e, i) => {
//     const { color, id } = e

//     return {
//       ...e,
//       id: (id || i),
//       color: affixHexPrefix(color),
//     }
//   })

// export const transformQueuedList = (list, queue, itemIdKey) => {
//   // 진행중(true), 대기중(false) 작업 2개인 모델이 있을 경우
//   // queue에는 진행중인 작업, 대기중인 작업 순으로 들어가므로 역순으로 순회하여 진행중인 작업이 최종 상태이도록 함
//   const queuedTaskStatus = new Map(
//     [...queue].reverse().map((a) => [a[itemIdKey], a.isRunning]),
//   )
//   const ongoingTaskId = list.reduce((a, { id }) => (queuedTaskStatus.get(id) ? id : a), '')

//   return list.map((item) => {
//     const { id } = item

//     if (id === ongoingTaskId) {
//       return { ...item, queueTag: 'in-progress' }
//     }
//     if (queuedTaskStatus.has(id)) {
//       return { ...item, queueTag: 'waiting' }
//     }
//     return item
//   })
// }

// export const transformLocalImageInfo = (name, rawPath, blob, resolution = {}) => ({
//   filename: name,
//   rawPath,
//   preview: URL.createObjectURL(blob),
//   isLocal: true,
//   isSelected: true,
//   labelOk: true,
//   width: resolution.width,
//   height: resolution.height,
// })

type Multiplier = (value: any, multiply: number) => any

type Scalable<T extends Record<PropertyKey, any>> = readonly (readonly [keyof T] | readonly [keyof T, Multiplier])[]

export const multiplyEntryNumbers = <T extends Record<PropertyKey, any>>(
  obj: T,
  scalableKeys: Scalable<T>,
  multiple: number
) => {
  const scalable = new Map<keyof T, undefined | Multiplier>(scalableKeys.map(([k, m]) => [k, m] as const))

  /**
   * `v`는 `multiple`을 곱해주기 위해 `number` 타입이어야 함.
   * 하지만, points와 같이 `[number, number][]` 타입일 때, 'v'가 `number` 타입은 아니지만 `multiple`이 문제없이 동작하는 경우가 있으므로
   * `toBeMultiplied`에서 `typeof v === "number"`로 강제할 수 없음.
   * */
  const toBeMultiplied = (k: PropertyKey, v: unknown): v is number => scalable.has(k)

  return Object.entries<keyof T, T[keyof T]>(obj).reduce((a, [k, v]) => {
    if (toBeMultiplied(k, v)) {
      const multiplier = scalable.get(k) ?? ((n: number) => n * multiple)

      return { ...a, [k]: multiplier(v, multiple) }
    }
    return { ...a, [k]: v }
  }, {} as T)
}
