import { KonvaEventObject } from 'konva/lib/Node'
import { useRef } from 'react'
import Konva from 'konva'

import { Point2D } from '@ahha/components/Canvas/types/point'
import { useStage } from '@ahha/components/Canvas/utils/Context/Stage/useStage'
import { getUnscaledPointerPosition } from '@ahha/components/Canvas/utils/konva'
import { hasProperty } from '@ahha/utils/@types/typeChecks'

const INITIAL_POINT_2D: Point2D = [0, 0]
const FALLBACK_ORIGIN = { normalizedX: 0, normalizedY: 0 }

/** TODO:ksh: drag할 때만 store 구독하도록 변경 - 2024.06.05 */
/**
 * @param enabled 드래그 가능 여부 설정.
 * @param optimize 점 500개 이상의 폴리곤 등에서 성능 저하가 발생할 때 사용 가능. 경우에 따라 도형 드래그 중 스케일 변경 시 도형이 정상적으로 그려지지 않을 수 있으므로 주의.
 */
export const useDragShape = (enabled = false, optimize = false) => {
  const [stage] = useStage((s) => s.node)
  const [origin] = useStage((s) => (enabled && !optimize ? s.origin : undefined))
  const [scale] = useStage((s) => (enabled && !optimize ? s.scale : undefined))

  const dragStart = useRef(INITIAL_POINT_2D)
  const initialOrigin = useRef(INITIAL_POINT_2D)

  const getNormalizedOrigin = (): Point2D => {
    if (!stage) {
      return INITIAL_POINT_2D
    }
    const scale = stage.scaleX()
    const { normalizedX, normalizedY } = origin ?? FALLBACK_ORIGIN

    if (optimize) {
      return [stage.x() / scale, stage.y() / scale]
    }
    return [normalizedX, normalizedY]
  }

  const getScale = () => (optimize ? stage?.scaleX() : scale) ?? 1

  const initialize = (e: KonvaEventObject<MouseEvent>) => {
    initialOrigin.current = getNormalizedOrigin()

    if (isClustered(e)) {
      dragStart.current = [e.group.x(), e.group.y()]
    } else {
      dragStart.current = getUnscaledPointerPosition(e)
    }
  }

  /**
   * `dragStart`는 드래그 시작 시점의 좌표계를 기준으로 한 포인터 위치이기 때문에
   * 드래그 중에 Stage 확대/축소 및 이동을 하는 경우, 좌표계가 달라지게 되므로 변환 전후 좌표계의 기준점을 맞춰주는 작업이 적용됨.
   * 반면, 도형이 그룹으로서 드래그되는 경우, 해당 Group의 x, y 위치는 Stage의 scale에 따라 달라지지 않으므로 위치 그대로 반환.
   * */
  const getAdjustedStartPoint = (e: KonvaEventObject<MouseEvent>) => {
    const [x, y] = dragStart.current
    const [normX, normY] = getNormalizedOrigin()
    const [prevNormX, prevNormY] = initialOrigin.current

    const diffX = normX - prevNormX
    const diffY = normY - prevNormY

    if (isClustered(e)) {
      return [x, y]
    }
    return [x + diffX, y + diffY]
  }

  const getEndPoint = (e: KonvaEventObject<MouseEvent>) => {
    if (isClustered(e)) {
      return [e.group.x(), e.group.y()]
    }
    return getUnscaledPointerPosition(e, getScale())
  }

  const getDragDelta = (e: KonvaEventObject<MouseEvent>): Point2D => {
    const [sX, sY] = getAdjustedStartPoint(e)
    const [eX, eY] = getEndPoint(e)

    return [eX - sX, eY - sY]
  }

  return {
    initialize,
    getDragDelta,
  }
}

interface ClusterEvent {
  isClustered: boolean
  group: Konva.Rect
}

const isClustered = (e: KonvaEventObject<MouseEvent>): e is KonvaEventObject<MouseEvent> & ClusterEvent => hasProperty(e, 'isClustered')
