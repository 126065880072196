import { isFunction } from '@ahha/utils/@types/typeChecks'
import { warn } from '@ahha/utils/log'

interface RetryOptions<R> {
  fn: () => Promise<R>
  max?: number
  delay?: number
  continueOnError?: boolean
}

type RetryParams<R> = [RetryOptions<R>] | [() => Promise<R>]

const DEFAULT_OPTIONS = { max: 3, delay: 2000, continueOnError: false }

export function retry<R>(fn: () => Promise<R>): Promise<R>
export function retry<R>(options: RetryOptions<R>): Promise<R>

export function retry<R>(...params: RetryParams<R>): Promise<R> {
  const { fn, max, delay } = distributeParams(...params)

  let count = 1
  const exec = async (): Promise<R> => {
    try {
      return await fn()
    } catch (e) {
      if (count >= max) {
        warn('max retry count reached.')
        throw e
      }
      count += 1
      await new Promise((r) => { setTimeout(r, delay) })

      return exec()
    }
  }

  return exec()
}

const distributeParams = <R>(...options: RetryParams<R>) => {
  const p = options[0]

  if (isFunction(p)) {
    return { ...DEFAULT_OPTIONS, fn: p }
  }
  return { ...DEFAULT_OPTIONS, ...p }
}
