import { createAsyncThunk } from '@reduxjs/toolkit'
import { API } from '@/api'
import { AnnotationFile } from '@/api/Annotation/types'
import { queryClient } from '@/api/react-query/queryClient'
import { annotationKeys } from '@/api/react-query/queryKeys/annotation'
import { LoadedAnnotFiles } from './initialization'
import { ThunkConfig, InternalAnnotFileInfo } from '../types'

export const isInternalAnnotFile = (data: AnnotationFile | InternalAnnotFileInfo | null): data is InternalAnnotFileInfo => !!data && 'id' in data

const loadAllAnnotFiles = async (annotId: string, length: number) => {
  // NOTE: 캐시된 파일들을 불러오면 save하기 전의 데이터가 보여짐. 캐시가 꼭 필요하다면 save 하는 시점에 캐시를 한번 지우도록 removeQueries 해줘야함
  // const cachedFiles = queryClient.getQueryData<InternalAnnotFileInfo[]>(annotationKeys.files(annotId))
  //
  // if (cachedFiles) {
  //   return cachedFiles
  // }
  const MAX_FILES_PER_PAGE = 50
  const itemsPerPage = Math.min(length, MAX_FILES_PER_PAGE)
  const MAX_PAGE = Math.ceil(length / itemsPerPage)

  const fileResponse = await Promise.all(
    Array.from({ length: MAX_PAGE }, (_, page) => API().Annotation.getAnnotationFiles({
      annotationId: annotId,
      page,
      itemsPerPage,
    }))
  )
  const files = fileResponse.flatMap((f) => f.list)

  queryClient.setQueryData(annotationKeys.files(annotId), files)
  return files
}

export const loadAnnotationFiles = createAsyncThunk<LoadedAnnotFiles, string, ThunkConfig>(
  'annotate/load-annotation-files',
  async (annotId, thunkAPI) => {
    const prevFiles = thunkAPI.getState().annotation.files
    const annotFiles = await loadAllAnnotFiles(annotId, prevFiles.length)

    return prevFiles.map((f, i) => (f || annotFiles[i]))
  }
)

/**
 * 이미지를 모두 불러온 후,
 * Annotation(이미지를 넘길 때마다 history 초기화)과
 * Classification(모든 이미지의 history를 초기화)에서의 동작이 달라 별도의 action으로 분리
 *  */
export const loadClassificationFiles = createAsyncThunk<LoadedAnnotFiles, string, ThunkConfig>(
  'annotate/get-classification-files',
  async (annotId, thunkAPI) => {
    const prevFiles = thunkAPI.getState().annotation.files
    const annotFiles = await loadAllAnnotFiles(annotId, prevFiles.length)

    return prevFiles.map((f, i) => (f || annotFiles[i]))
  }
)
