import { createAsyncThunk } from '@reduxjs/toolkit'
import { API } from '@/api'
import { updateQueryData } from '@/stores/slices/Lisa/annotation/queryData'
import { initializeProject } from './initialization'
import { ThunkConfig } from '../types'
import { loadAnnotationObjects } from './objects'
import { AnnotationClassType } from '@/api/Annotation/types'
import { getAnnotDefaultCondition } from '@/stores/slices/Lisa/annotation/utils'

interface AddClassPayload {
  annotId?: string;
  name: string;
  color: string;
}

export interface AddClassFulfilledData {
  addedClass: AnnotationClassType;
}

export const addClass = createAsyncThunk<AddClassFulfilledData, AddClassPayload, ThunkConfig>(
  'annotate/add-class',
  async ({ annotId, name, color }, thunkAPI) => {
    // TODO: check if modified
    const annotationId = thunkAPI.getState().annotation.id || annotId

    if (!annotationId) {
      throw new Error('Annotation ID is not set.')
    }
    const classInfo = await API().withSignal(thunkAPI.signal).Annotation.addClass({ annotationId, color, name })

    updateQueryData.class(annotationId, (prev) => [...prev, classInfo])
    return { addedClass: classInfo || {} }
  },
  {
    condition: getAnnotDefaultCondition,
  }
)

export const removeClass = createAsyncThunk<any, string, ThunkConfig>(
  'annotate/remove-class',
  async (classId, thunkAPI) => {
    await API().Annotation.deleteClass(classId)

    // TODO: check if modified
    const { currentIndex } = thunkAPI.getState().annotation
    const annotId = thunkAPI.getState().annotation.id || ''

    thunkAPI.dispatch(loadAnnotationObjects({ index: currentIndex, invalidate: true }))
    updateQueryData.class(annotId, (prev) => prev.filter((c) => c._id !== classId))

    const loadedProject = await initializeProject(annotId, currentIndex)

    return { ...loadedProject, removedClassId: classId }
  },
  {
    condition: getAnnotDefaultCondition,
  }
)
interface UpdateClassParams {
  classId: string;
  name: string;
  color: string;
}

interface UpdateClassReturns {
  classId: string;
  name: string;
  color: string;
}

export const updateClass = createAsyncThunk<UpdateClassReturns, UpdateClassParams, ThunkConfig>(
  'annotate/update-class',
  async ({ classId, name, color }, thunkAPI) => {
    const annotStore = thunkAPI.getState().annotation
    const annotId = annotStore.id || ''
    // TODO: check if modified
    updateQueryData.class(annotId, (prev) => prev.map((c) => (c._id === classId ? { ...c, name, color } : c)))
    await API().Annotation.updateClass({ annotationClassId: classId, name, color })

    return { classId, name, color }
  },
  {
    condition: getAnnotDefaultCondition,
  }
)

export const updateClassOrder = createAsyncThunk<AnnotationClassType[], string[], ThunkConfig>(
  'annotate/update-class-order',
  async (classIds, thunkAPI) => {
    const annotId = thunkAPI.getState().annotation.id || ''
    const { data } = await API().Annotation.updateClassOrder({
      annotationId: annotId,
      classIds: arrangeClasses(classIds),
    })

    updateQueryData.class(annotId, () => arrangeClasses(data))
    return data
  },
  {
    condition: getAnnotDefaultCondition,
  }
)

export const arrangeClasses = <T extends unknown[]>(cls: T) => [...cls].reverse()
