import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { find } from 'lodash'

import { AppliedQueryConfig, INITIAL_QUERY_CONFIG, QueryConfigType } from '@/pages/DataCamp/components/DataQuery/types'
import { buildPipeline } from '@/pages/DataCamp/components/DataQuery/aggregateUtils'

export type CollectionInfoType = {
  dbId: string;
  dbName: string;
  collectionId: string;
  collectionName: string;
}

interface QueryState {
  selectedCollection: CollectionInfoType;
  selectedQuery: AppliedQueryConfig;
  queryList: AppliedQueryConfig[];
  isEditQuery: boolean;
  currentQueryConfig: QueryConfigType, // save query api
}

const initialState: QueryState = {
  selectedCollection: { dbId: '', dbName: '', collectionId: '', collectionName: '' },
  selectedQuery: {
    name: '',
    pipeline: '[]',
    config: INITIAL_QUERY_CONFIG,
  },
  queryList: [],
  isEditQuery: false,
  currentQueryConfig: INITIAL_QUERY_CONFIG, // save query api
}

export const queryConfig = createSlice({
  name: 'queryConfig',
  initialState,
  reducers: {
    setSelectedCollectionId(state, action: PayloadAction<CollectionInfoType>) {
      return {
        ...state,
        selectedCollection: action.payload,
      }
    },
    setSelectedCollection(state, action: PayloadAction<CollectionInfoType>) {
      const query: AppliedQueryConfig = { name: 'original', pipeline: '[]', config: INITIAL_QUERY_CONFIG }
      return {
        ...state,
        selectedCollection: action.payload,
        selectedQuery: query,
        queryList: [query],
        isEditQuery: false,
      }
    },
    selectOriginalQuery(state, action: PayloadAction) {
      const query: AppliedQueryConfig = { name: 'original', pipeline: '[]', config: INITIAL_QUERY_CONFIG }
      return {
        ...state,
        selectedQuery: query,
        queryList: [query],
        isEditQuery: false,
      }
    },
    setSelectedQuery(state, action: PayloadAction<string>) {
      const selectedQuery = find(state.queryList, { name: action.payload })
      if (selectedQuery) {
        return { ...state, selectedQuery }
      }
      return state
    },
    setQueryList(state, action: PayloadAction<QueryConfigType & { name?: string, queryId?: string }>) {
      const query: AppliedQueryConfig = {
        id: action.payload?.queryId || '',
        name: action.payload?.name || `query${state.queryList.length}`,
        pipeline: JSON.stringify(buildPipeline(action.payload)),
        config: action.payload,
      }
      // TODO: 이미 있는 쿼리면 바로 concat x
      return { ...state, selectedQuery: query, queryList: [...state.queryList, query], isEditQuery: false }
    },
    resetQueryState(state, action: PayloadAction) {
      return initialState
    },
    setEditQuery(state, action: PayloadAction<boolean>) {
      return { ...state, isEditQuery: action.payload }
    },
    // save query
    setCurrentQueryConfig(state, action: PayloadAction<QueryConfigType>) {
      return {
        ...state,
        currentQueryConfig: action.payload,
      }
    },
  },
})

export const {
  setSelectedCollectionId,
  setSelectedCollection,
  selectOriginalQuery,
  setSelectedQuery,
  setQueryList,
  resetQueryState,
  setEditQuery,
  setCurrentQueryConfig,
} = queryConfig.actions

export default queryConfig.reducer
