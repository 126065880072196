import { createAsyncThunk } from '@reduxjs/toolkit'
import { find } from 'lodash'
import { ThunkConfig } from '@/stores/slices/Labeling/types'
import { API } from '@/api'
import { LabelingClass } from '@/api/ClassGroup/types'
import { getLabelingDefaultCondition } from '@/stores/slices/Labeling/utils'
import { AddClassToGroupPayload } from '@/api/ClassGroup/types'

export const addClass = createAsyncThunk<LabelingClass | undefined, AddClassToGroupPayload, ThunkConfig>(
  'labeling/add-class',
  async (payload, thunkAPI) => {
    const id = thunkAPI.getState().labeling.labelingNodeId
    if (!id) {
      throw new Error('Labeling node id not found')
    }

    // const addedClass = await API().ClassGroup.addClassToGroup(payload)
    // return addedClass
    const classGroup = await API().ClassGroup.addClassToGroup(payload)
    return find(classGroup.labelingClasses, { name: payload.name })
  }
)

export const updateClassOrder = createAsyncThunk<LabelingClass[], string[], ThunkConfig>(
  'labeling/update-class-order',
  async (classIds, thunkAPI) => {
    const labelingNodeId = thunkAPI.getState().labeling.labelingNodeId || ''
    const { data: classList } = await API().Labeling.updateUserClassOrder(labelingNodeId, classIds)
    return classList
  },
  { condition: getLabelingDefaultCondition }
)

export const getUserClasses = createAsyncThunk<LabelingClass[], string, ThunkConfig>(
  'labeling/get-user-classes',
  async (labelingNodeId, thunkAPI) => {
    const { data: classList } = await API().Labeling.getUserClasses(labelingNodeId)
    return classList
  }
)
