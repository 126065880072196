import { API } from '@/api'
import { queryClient } from '@/api/react-query/queryClient'
import { datasetNodeKeys } from '@/api/react-query/queryKeys/datasetNode'
import { labelingNodeKeys } from '@/api/react-query/queryKeys/labelingNode'
import { projectKeys } from '@/api/react-query/queryKeys/project'
import { AppListenerMiddleware } from '@/stores'
import { NodeData, loadAllServerNodes, invalidateNode, updateNode, updateNodesInBulk } from '@/stores/slices/Pipeline/pipelineSlice'
import {
  isDatasetNode,
  isInferenceNode,
  isLabelingNode,
  isModelNode,
  isReviewNode,
  isValidationNode
} from '@/stores/slices/Pipeline/types/typeGuards'
import { transform } from '@/stores/slices/Pipeline/utils/transform'
import { reviewNodeKeys } from '@/api/react-query/queryKeys/reviewNode'
import { modelNodeKeys } from '@/api/react-query/queryKeys/modelNode'
import { validationNodeKeys } from '@/api/react-query/queryKeys/validationNode'
import { inferenceNodeKeys } from '@/api/react-query/queryKeys/inferenceNode'

export const nodeInvalidateListener = (listener: AppListenerMiddleware) => listener.startListening({
  actionCreator: invalidateNode,
  effect: async (action, api) => {
    const { id, type } = action.payload
    const { projectId, nodes } = api.getState().pipeline
    const selfData = nodes[id]
    const { parent } = selfData

    if (type === 'all') {
      const updated = await invalidateProject(projectId)

      api.dispatch(loadAllServerNodes({ projectId, nodes: updated }))
    } else if (type === 'self') {
      const [updated] = await invalidateSelf(selfData, projectId)

      api.dispatch(updateNode({ ...updated, id }))
    } else if (type === 'self/children') {
      /** ex. 부모 데이터셋 노드에서 이미지 용도를 바꾸거나 삭제한 경우 */
      /** ex. 라벨러가 2명 이상이도록 노드 설정을 최초로 완료한 경우 */
      const childrenData = selfData.children?.map((n) => nodes[n]) ?? []
      const updated = await invalidateSelfAndChildren([selfData, ...childrenData], projectId)

      api.dispatch(updateNodesInBulk(updated))
    } else if (type === 'children') {
      /** ex.  */
    } else if (type === 'parent/self/siblings') {
      /** 자신, 부모, 모든 형제를 invalidate */
      const parentData = parent ? nodes[parent] : selfData
      const updated = await invalidateSelf(parentData, projectId, true)
      console.log(updated)
      api.dispatch(updateNodesInBulk(updated))
    } else if (type === 'parent/self') {
      const parentData = parent ? nodes[parent] : selfData
      /** 자신, 부모를 invalidate */
      /** ex. 하위 데이터셋 노드에서 이미지를 삭제한 경우, 하위 노드를 삭제한 경우 */
    }
  },
})

export const invalidateProject = async (projectId: string) => {
  const {
    datasetNodeList,
    labelingNodeList,
    reviewNodeList,
    modelNodeList,
    validationNodeList,
    inferenceNodeList,
  } = await queryClient.fetchQuery({
    queryKey: projectKeys.detail(projectId),
    queryFn: () => API().Project.getProjectDetail(projectId),
  })

  const datasetNodes = await Promise.all(datasetNodeList.map((n) => queryClient.fetchQuery({
    queryKey: datasetNodeKeys.detail(projectId, n._id),
    queryFn: () => API().DatasetNode.getDatasetNodeDetail(n._id),
  })))

  const labelingNodes = await Promise.all(labelingNodeList.map((n) => queryClient.fetchQuery({
    queryKey: labelingNodeKeys.detail(n._id),
    queryFn: () => API().Labeling.getNodeDetails(n._id),
  })))

  const reviewNodes = await Promise.all(reviewNodeList.map((n) => queryClient.fetchQuery({
    queryKey: reviewNodeKeys.detail(projectId, n._id),
    queryFn: () => API().LabelingReview.getReviewNodeDetail(n._id),
  })))

  const modelNodes = await Promise.all(modelNodeList.map((n) => queryClient.fetchQuery({
    queryKey: modelNodeKeys.detail(projectId, n._id),
    queryFn: () => API().ModelNode.getModelNodeDetail(n._id),
  })))

  const validationNodes = await Promise.all(validationNodeList.map((n) => queryClient.fetchQuery({
    queryKey: validationNodeKeys.detail(projectId, n._id),
    queryFn: () => API().ValidationNode.getValidationNodeDetail(n._id),
  })))

  const inferenceNodes = await Promise.all(inferenceNodeList.map((n) => queryClient.fetchQuery({
    queryKey: inferenceNodeKeys.detail(projectId, n._id),
    queryFn: () => API().InferenceNode.getInferenceNodeDetail(n._id),
  })))

  return [
    ...transform.toDatasetNode(datasetNodes),
    ...transform.toLabelNode(labelingNodes),
    ...transform.toReviewNode(reviewNodes),
    ...transform.toModelNode(modelNodes),
    ...transform.toValidationNode(validationNodes),
    ...transform.toInferenceNode(inferenceNodes),
  ]
}

const invalidateSelf = async (nodeData: NodeData, projectId: string, includeSubNodes = false) => {
  const { id } = nodeData

  if (isDatasetNode(nodeData)) {
    const updated = await queryClient.fetchQuery({
      queryKey: datasetNodeKeys.detail(projectId, id),
      queryFn: () => API().DatasetNode.getDatasetNodeDetail(id),
    })
    return transform.toDatasetNode([updated])
  }
  if (isLabelingNode(nodeData)) {
    const updated = await queryClient.fetchQuery({
      queryKey: labelingNodeKeys.detail(id),
      queryFn: () => API().Labeling.getNodeDetails(id),
    })
    return transform.toLabelNode([updated])
  }
  if (isReviewNode(nodeData)) {
    const updated = await queryClient.fetchQuery({
      queryKey: reviewNodeKeys.detail(projectId, id),
      queryFn: () => API().LabelingReview.getReviewNodeDetail(id),
    })
    return transform.toReviewNode([updated])
  }
  if (isModelNode(nodeData)) {
    const updated = await queryClient.fetchQuery({
      queryKey: modelNodeKeys.detail(projectId, id),
      queryFn: () => API().ModelNode.getModelNodeDetail(id),
    })
    return transform.toModelNode([updated])
  }
  if (isValidationNode(nodeData)) {
    const updated = await queryClient.fetchQuery({
      queryKey: validationNodeKeys.detail(projectId, id),
      queryFn: () => API().ValidationNode.getValidationNodeDetail(id),
    })
    return transform.toValidationNode([updated])
  }
  if (isInferenceNode(nodeData)) {
    const updated = await queryClient.fetchQuery({
      queryKey: inferenceNodeKeys.detail(projectId, id),
      queryFn: () => API().InferenceNode.getInferenceNodeDetail(id),
    })
    return transform.toInferenceNode([updated])
  }
  /** TODO:ksh: 다른 타입의 노드 핸들러 추가 - 2024.08.21 */
  return [{ id: '' }]
}

const invalidateSelfAndChildren = async (nodeData: NodeData[], projectId: string) => {
  /* const childrenData = nodeData.children?.map((n) => nodes[n]) ?? [] */
  const updatedData = await Promise.all(nodeData.map((n) => invalidateSelf(n, projectId, true)))

  return updatedData.flatMap((n) => n)
}

const invalidateSelfAndParent = () => {}

const invalidateSelfWithAllNext = () => {}

/**
 * 1. 데이터셋 무효화
 *   - 상위 노드: 해당 노드 + 하위 노드 + 다음 link들의 모든 노드
 *   - 하위 노드:
 *     - 상위 노드 + 하위 노드 + 다음 link들의 모든 노드
 *     - 해당 노드 + 다음 link들의 모든 노드
 *     - 해당 노드
 *
 * 2. 레이블링 무효화
 *   - 상위 노드: 해당 노드 + 하위 노드 + 다음 link들의 모든 노드
 *   - 하위 노드:
 *     - 상위 노드 + 하위 노드 + 다음 link들의 모든 노드
 *     - 해당 노드 + 다음 link들의 모든 노드
 *     - 해당 노드
 */
