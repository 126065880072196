import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { ContainerType, WidgetType } from '@/pages/DataCamp/Dashboard/types'
import { RootState } from '@/stores'

export type DashboardPanelType = {
  isOpen: boolean,
  selectedContainerId: string | null,
  selectedWidget: WidgetType | null,
  selectedSubContainerId?: string | null | undefined,
}

const INITIAL_DATA: DashboardPanelType = {
  isOpen: false,
  selectedContainerId: null,
  selectedWidget: null,
  selectedSubContainerId: null,
}

export const dashboardPanel = createSlice({
  name: 'dashboardPanel',
  initialState: INITIAL_DATA,
  reducers: {
    openContainerPanel(state, action: PayloadAction<string>) {
      return { isOpen: true, selectedContainerId: action.payload, selectedWidget: null }
    },
    openWidgetPanel(state, action: PayloadAction<{ containerId: string, widget: WidgetType, subContainerId?: string | null | undefined }>) {
      const { containerId, widget, subContainerId } = action.payload
      return { isOpen: false, selectedContainerId: containerId, selectedWidget: widget, selectedSubContainerId: subContainerId }
    },
    closePanel(state, action: PayloadAction) {
      return { isOpen: false, selectedContainerId: null, selectedWidget: null, selectedSubContainerId: null }
    },
  },
})

export const selectDashboardPanel = createSelector(
  (state: RootState) => state.dashboardPanel,
  (dashboard) => ({
    isOpen: dashboard.isOpen,
    containerId: dashboard.selectedContainerId,
    widget: dashboard.selectedWidget,
    subContainerId: dashboard.selectedSubContainerId,
  })
)

export const selectDashboardWidget = 0

export const {
  openContainerPanel,
  openWidgetPanel,
  closePanel,
} = dashboardPanel.actions

export default dashboardPanel.reducer
