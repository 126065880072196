import { useCallback, useEffect } from 'react'

import useStageCursor from '@ahha/components/Canvas/utils/hooks/useCursors/useStageCursor'
import useRevertCursorOnQuit from '@ahha/components/Canvas/utils/hooks/useCursors/useRevertCursorOnQuit'
import { registerEvent, unregisterEvent } from '@ahha/components/Canvas/utils/hooks/useCursors/utils'
import { useTimeout } from '@ahha/utils/hooks'

interface BrushCursorParams {
  enabled: boolean
  size: number
  fill: string
}

export const useBrushCursor = ({
  enabled,
  size,
  fill,
}: BrushCursorParams) => {
  const [setCursor, revertCursor, stage] = useStageCursor()
  const timeout = useTimeout()

  useRevertCursorOnQuit(enabled)

  const defer = useCallback((fn: () => void) => () => timeout.start(fn, 5), [])

  const setBrushCursor = useCallback(() => {
    const scaledSize = stage.scaleX() * size

    if (scaledSize < 6) {
      /* TODO:ksh: 잘 안보일 때 붓 커서로 보여주기 - 2024.02.20 */
      setCursor('crosshair')
    } else {
      setCursor(`url("${createBrushCursor(scaledSize, fill)}") ${scaledSize / 2} ${scaledSize / 2}, auto`)
    }
  }, [size, fill])

  useEffect(() => {
    /** wheel 이벤트로 캔버스 스케일 변경 시, 스케일 변경 이벤트가 나중에 발생해 `stage.scaleX()`가 이전 값을 나타내는 문제가 있어 지연 설정. */
    const deferredSetCursor = defer(setBrushCursor)

    if (enabled) {
      setBrushCursor()

      registerEvent(stage, 'mouseover', setBrushCursor)
      /* registerEvent(stage, 'mousemove', setBrushCursor) */
      registerEvent(stage, 'wheel', deferredSetCursor)
      registerEvent(stage, 'mouseout', revertCursor)
    }

    return () => {
      unregisterEvent(stage, 'mouseover', setBrushCursor)
      /* unregisterEvent(stage, 'mousemove', setBrushCursor) */
      unregisterEvent(stage, 'wheel', deferredSetCursor)
      unregisterEvent(stage, 'mouseout', revertCursor)
      revertCursor()
      timeout.clearAll()
    }
  }, [enabled, setBrushCursor])
}

const createBrushCursor = (size: number, color: string) => {
  const svg = `<svg width="${size}px" height="${size}px" viewBox="0 0 ${size} ${size}" xmlns="http://www.w3.org/2000/svg">
    <rect x="0" y="0" width="${size}" height="${size}" fill="${color}" stroke="white"></rect>
  </svg>`

  return `data:image/svg+xml,${encodeURIComponent(svg)}`
}
