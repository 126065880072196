import BaseApi from '@/api/Base/BaseApi'
import {
  CreateWorkspacePayload,
  InvitationInfo,
  InvitedWorkspace,
  SendInvitationsPayload,
  Workspace,
  WorkspaceInviteUrl,
  UpdateWorkspacePayload,
  UpdateWorkspaceThumbnailPayload
} from '@/api/Workspace/types'
import { UserInfo } from '@/api/User/types'

const ROOT = '/workspace'

export default class WorkspaceApi extends BaseApi {
  createWorkspace(payload: CreateWorkspacePayload) {
    return this.agent.post<Workspace>(ROOT, payload)
  }

  getWorkspace(workspaceId: string) {
    return this.agent.get<Workspace>(`${ROOT}/${workspaceId}`)
  }

  /** 수락하지 않은 초대받은 워크스페이스 리스트 */
  getInvitationList() {
    return this.agent.get<InvitedWorkspace[]>(`${ROOT}/invitation-list`)
  }

  acceptInvitation(invitationCode: string) {
    return this.agent.post<Workspace>(`${ROOT}/invitation-acceptance`, { invitationCode })
  }

  createInviteUrl(workspaceId: string) {
    return this.agent.post<WorkspaceInviteUrl>(`${ROOT}/invitation-url`, { workspaceId })
  }

  getInvitationInfo(invitationCode: string) {
    return this.agent.get<InvitationInfo>(`${ROOT}/invitation/${invitationCode}/info`)
  }

  sendInvitations(payload: SendInvitationsPayload) {
    return this.agent.post(`${ROOT}/send-email-invitation`, payload)
  }

  getWorkspaceList() {
    return this.agent.get<Workspace[]>(`${ROOT}/list`)
  }

  updateWorkspace(payload: UpdateWorkspacePayload) {
    const { workspaceId, name, description } = payload
    return this.agent.put<Workspace>(`${ROOT}/${workspaceId}`, { name, description })
  }

  updateWorkspaceThumbnail(payload: UpdateWorkspaceThumbnailPayload) {
    const { workspaceId, file } = payload
    const formData = new FormData()
    if (file) {
      formData.append('file', file)
    }
    return this.agent.put<Workspace>(`${ROOT}/${workspaceId}/thumbnail`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  }

  deleteWorkspaceThumbnail(workspaceId: string) {
    return this.agent.delete<Workspace>(`${ROOT}/${workspaceId}/thumbnail`)
  }

  getWorkspaceMembers(workspaceId: string) {
    return this.agent.get<UserInfo[]>(`${ROOT}/${workspaceId}/member/list`)
  }
}
