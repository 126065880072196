import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { v4 as uuidv4 } from 'uuid'

import { multiplyEntryNumbers } from '@/pages/Lisa/Label/utils/transformer'
import {
  NewRoiRegionPayload,
  RearrangeBasis,
  RegionIdPayload,
  RegionPayload,
  RoiInitialInfo,
  RoiState,
  isEllipseRoiRegion,
  isMaskingRegion,
  isRectRoiRegion
} from './types'
// import { loadAnnotationRoi, postAnnotationRoi } from './extraReducers'
import {
  roiInitializer,
  maskingInitializer,
  rearrange,
  getImageScale,
  RECT_ROI_SCALABLE_PROPS,
  MASKING_SCALABLE_PROPS,
  ELLIPSE_ROI_SCALABLE_PROPS
} from './utils'
import { ARRANGE_BASIS, FOCUSED_MASKING_ID, FOCUSED_ROI_ID, ROI_LAYER_TYPES } from '@/pages/Lisa/Label/RoiMasking/const'

export const initialState: RoiState = {
  imageSize: {
    naturalWidth: 0, // 이미지 naturalWidth
    editorWidth: 0, // ROI 에디터에서 최초로 렌더링된 이미지의 width(scale === 1일 때 canvas의 width)
  },
  annotId: null,
  currentRegionId: {
    ROI: FOCUSED_ROI_ID.inViewMode.none,
    masking: FOCUSED_MASKING_ID.inViewMode.none,
  },
  regions: [], // x, y, width, height 등은 이미지의 naturalSize 기준으로 스케일링 후 저장
  isInitialized: false, // DB를 불러오기 전까지는 ROI, Mask 사용할 수 없도록 플래그 설정
  isMutated: false,
}

export const roiSlice = createSlice({
  name: 'ROI',
  initialState,
  reducers: {
    initializeRoi: (state, { payload }: PayloadAction<RoiInitialInfo>) => {
      const { imageSize, annotId } = payload

      state.imageSize = { ...state.imageSize, ...imageSize }
      state.annotId = annotId ?? state.annotId

      if (imageSize/*  && annotId */) {
        state.isInitialized = true
      }
    },
    addRoiRegion: (state, { payload }: PayloadAction<NewRoiRegionPayload>) => {
      const { type, width, height, rotation, scale } = payload
      const imageScale = getImageScale(state)

      const id = uuidv4()
      const newRegion = roiInitializer({
        id,
        type,
        width: width * imageScale,
        height: height * imageScale,
        rotation,
        scale,
      })

      state.regions.push(newRegion)

      state.isMutated = true
      state.currentRegionId.ROI = id
    },
    addMaskingRegion: (state, { payload }: PayloadAction<RearrangeBasis | undefined>) => {
      const basis = payload || ARRANGE_BASIS.frontIs.roi
      const id = uuidv4()
      const newRegion = maskingInitializer(id)
      const rearranged = rearrange(
        [newRegion, ...state.regions],
        basis
      )

      state.regions = rearranged
      state.currentRegionId.masking = FOCUSED_MASKING_ID.inDrawingMode.none
    },
    setRegion: (state, { payload }: PayloadAction<RegionPayload>) => {
      const { id, data } = payload
      const imageScale = getImageScale(state)

      const regionIndex = state.regions.findIndex((r) => r.id === id)
      const prevRegion = state.regions[regionIndex]

      if (isRectRoiRegion(prevRegion) && isRectRoiRegion(data)) {
        const scaledData = multiplyEntryNumbers(data, RECT_ROI_SCALABLE_PROPS, imageScale)

        state.regions[regionIndex] = { ...prevRegion, ...scaledData }
      } else if (isEllipseRoiRegion(prevRegion) && isEllipseRoiRegion(data)) {
        const scaledData = multiplyEntryNumbers(data, ELLIPSE_ROI_SCALABLE_PROPS, imageScale)

        state.regions[regionIndex] = { ...prevRegion, ...scaledData }
      } else if (isMaskingRegion(prevRegion) && isMaskingRegion(data)) {
        const scaledData = multiplyEntryNumbers(data, MASKING_SCALABLE_PROPS, imageScale)

        state.regions[regionIndex] = { ...prevRegion, ...scaledData }
      }
      state.isMutated = true
    },
    setCurrentRegionId: (state, { payload }: PayloadAction<RegionIdPayload>) => {
      const { type, id } = payload

      if (!id) {
        const lastRegion = state.regions.filter((r) => r.variant === type).at(-1)

        state.currentRegionId[type] = lastRegion?.id ?? ''
      } else {
        state.currentRegionId[type] = id
      }
    },
    deleteRegion: (state, { payload }: PayloadAction<string>) => {
      const region = state.regions.find((r) => r.id === payload)
      const variant = region?.variant

      if (variant === ROI_LAYER_TYPES.roi) {
        state.currentRegionId.ROI = FOCUSED_ROI_ID.inDrawingMode.none
      } else if (variant === ROI_LAYER_TYPES.masking) {
        state.currentRegionId.masking = FOCUSED_MASKING_ID.inDrawingMode.none
      }
      state.regions = state.regions.filter((r) => r.id !== payload)

      state.isMutated = true
    },
    startDrawingMode: (state, { payload }: PayloadAction<string>) => {},
    endDrawingMode: (state) => {
      const { ROI, masking } = state.currentRegionId

      if (ROI) {
        state.currentRegionId.ROI = FOCUSED_ROI_ID.inViewMode.none
      }
      if (masking) {
        state.currentRegionId.masking = FOCUSED_MASKING_ID.inViewMode.none
      }

      state.regions = state.regions.filter((r) => ('isClosed' in r ? r.isClosed : true))
    },
    rearrangeRegions: (state, { payload }: PayloadAction<RearrangeBasis>) => {
      state.regions = rearrange(state.regions, payload)
    },
    resetCurrentRoi: () => initialState,
  },

  /* extraReducers: (builder) => {
    builder.addCase(loadAnnotationRoi.fulfilled, (state, { payload }) => {
      const { roi, mask } = payload

      state.regions = [...roi, ...mask]
      state.currentRegionId = {
        ROI: roi.length > 0 ? '' : null,
        masking: mask.length > 0 ? '' : null,
      }
    })
    builder.addCase(postAnnotationRoi.fulfilled, (state, action) => {
      state.isMutated = false
    })
  }, */
})

export const {
  initializeRoi,
  addRoiRegion,
  addMaskingRegion,
  setRegion,
  setCurrentRegionId,
  deleteRegion,
  startDrawingMode,
  endDrawingMode,
  rearrangeRegions,
  resetCurrentRoi,
} = roiSlice.actions

export default roiSlice.reducer
