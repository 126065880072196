import { createAsyncThunk } from '@reduxjs/toolkit'

import { ensureQueryData } from '@/stores/slices/Lisa/annotation/queryData'
import { RawAnnotFile, InternalAnnotFileInfo, ThunkConfig } from '../types'
import { DEFAULT_PALETTE } from '@/pages/Lisa/Label/Toolbar/ClassManager/const'
import { addClass } from '@/stores/slices/Lisa/annotation/thunks/class'

export type LoadedAnnotFiles = (RawAnnotFile | InternalAnnotFileInfo)[];

const initializeClassification = async (annotId: string, start?: number) => {
  const FILES_PER_PAGE = 5
  const startIndex = start ?? 0
  const endIndex = startIndex + (FILES_PER_PAGE - 1)
  const currentPage = Math.floor(startIndex / FILES_PER_PAGE)

  const startInFiles = startIndex - currentPage * FILES_PER_PAGE
  const endInFiles = endIndex - currentPage * FILES_PER_PAGE

  const [annotation, files] = await Promise.all([
    ensureQueryData.overview(annotId),
    ensureQueryData.initFiles(annotId, annotId, startIndex),
  ])

  const partialDocs = Array.from(
    { length: annotation.numOfDatasetFiles },
    (_, i) => (i >= startIndex && i <= endIndex ? files.list[i - startIndex] : null)
  )

  return {
    annotationId: annotId,
    annotation,
    files: partialDocs,
    start: startIndex,
    end: endIndex,
  }
}

export const initializeProject = async (annotId: string, start?: number) => {
  const FILES_PER_PAGE = 5
  const startIndex = start ?? 0
  const endIndex = startIndex + (FILES_PER_PAGE - 1)
  const currentPage = Math.floor(startIndex / FILES_PER_PAGE)

  const startInFiles = startIndex - currentPage * FILES_PER_PAGE
  const endInFiles = endIndex - currentPage * FILES_PER_PAGE

  const pageStart = currentPage * FILES_PER_PAGE
  const pageEnd = pageStart + (FILES_PER_PAGE - 1)

  // startIndex가 포함된 페이지의 파일 5개 불러오고있음
  const [annotation, files] = await Promise.all([
    ensureQueryData.overview(annotId),
    ensureQueryData.initFiles(annotId, annotId, startIndex),
  ])

  /* TODO:ksh: 초기 정보 다운로드, object 정보 다운로드 분리 - 2024.03.06 */
  // const annotObjects = await Promise.all(
  //   files.list.map((d, i) => (
  //     i >= startInFiles && i <= endInFiles
  //       ? ensureQueryData.objects(annotId, d._id)
  //       : Promise.resolve(null)
  //   ))
  // )
  const annotObjects = await Promise.all(
    files.list.map((d, i) => ensureQueryData.objects(annotId, d._id))
  )
  // const partialDocs = Array.from(
  //   { length: annotation.numOfDatasetFiles },
  //   (_, i) => (i >= startIndex && i <= endIndex ? annotObjects[i - startIndex] : null)
  // )
  const partialDocs = Array.from(
    { length: annotation.numOfDatasetFiles },
    (_, i) => (i >= pageStart && i <= pageEnd ? annotObjects[i - pageStart] : null)
  )

  const { datasetId } = annotation
  const datasetMeta = await ensureQueryData.dataset(datasetId)

  return {
    annotationId: annotId,
    annotation,
    files: partialDocs,
    // start: startIndex,
    // end: endIndex,
    start: pageStart,
    end: pageEnd,
    datasetMeta,
  }
}

export const loadAnnotation = createAsyncThunk<Awaited<ReturnType<typeof initializeProject>>, string, ThunkConfig>(
  'annotate/load-annotation',
  async (annotId, thunkAPI) => {
    const initialIndex = thunkAPI.getState().annotation.currentIndex

    return initializeProject(annotId, initialIndex)
  }
)

export const initializeClasses = createAsyncThunk<void, string, ThunkConfig>(
  'annotate/initialize-classes',
  async (annotId, thunkAPI) => {
    const { classes } = await ensureQueryData.overview(annotId)

    if (classes.length < 1) {
      thunkAPI.dispatch(addClass({
        annotId,
        name: 'DEFAULT',
        color: DEFAULT_PALETTE[0],
      }))
    }
  }
)

export const loadClassification = createAsyncThunk(
  'annotate/load-classification',
  async (annotId: string) => initializeClassification(annotId)
)
