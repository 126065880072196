import { RefObject } from 'react'
import Konva from 'konva'
import { KonvaEventObject } from 'konva/lib/Node'

import { ANCHOR_NAME_SUFFIX, CHILDREN_SEPARATOR, ROTATE_ANCHOR_PREFIX } from '@ahha/components/Canvas/const'
import { isNotEqual } from '@ahha/components/Canvas/utils/common'
import { DrawRectParams } from '@ahha/components/Canvas/types/shape'
import { clampMinMax, divide, maxAbs } from '@ahha/utils/number'
import { getDimensionSize } from '@ahha/components/Canvas/utils/math'
import { Nullable } from '@ahha/utils/@types/common'
import { GROUP_NAMES } from '@ahha/components/Canvas/Layer/const'
import { StageAttributes } from '@ahha/components/Canvas/utils/Context/Stage/useStageStore'
import { Point2D } from '../types/point'

export const normalize = (a: number = 0, b: number = 0, scale: number = 1): Point2D => [
  a / scale,
  b / scale,
]

export const getStageViewAttributes = (e: KonvaEventObject<MouseEvent>) => e.target.getStage()?.attrs as StageAttributes

export const getUnscaledStagePosition = (e: KonvaEventObject<MouseEvent>) => {
  const stage = e.target.getStage()
  const scale = stage?.scaleX() ?? 1
  const { x, y } = stage?.position() ?? {}

  return normalize(x, y, scale)
}

/**
 * `<canvas>`의 원점 `(0, 0)`에 대해 scale을 제거한 마우스 포인터의 위치를 반환.
 * @param scale Stage의 현재 scale.(기본값: `stage.scaleX()`) 기본값과 현재 scale이 일치하지 않는 특수한 상황에 사용할 수 있음.
 * @note 이미지의 원점은 `<canvas>`의 원점과 다를 수 있음에 주의.
 *  */
export const getUnscaledPointerPosition = (e: KonvaEventObject<MouseEvent>, scale?: number) => {
  const stage = e.target.getStage()
  const settledScale = scale ?? stage?.scaleX() ?? 1
  const { x, y } = stage?.getPointerPosition() ?? { x: 0, y: 0 }

  return normalize(x, y, settledScale)
}

export const getRelativePointerPosition = (e: KonvaEventObject<MouseEvent>) => {
  const stage = e.target.getStage()
  const scale = stage?.scaleX() ?? 1
  const x = stage?.x() ?? 0
  const y = stage?.y() ?? 0
  const pointer = stage?.getPointerPosition() ?? { x: 0, y: 0 }

  return normalize(pointer.x - x, pointer.y - y, scale)
}

const MIN_POSITION = 1

const getSquaredBoundingBox = (start: Point2D, end: Point2D) => {
  const [x0, y0] = start
  const [x1, y1] = end
  const dX = x1 - x0
  const dY = y1 - y0

  const size = Math.min(Math.abs(dX), Math.abs(dY))
  const scale = {
    x: Math.abs(divide(dY, maxAbs(dX, dY))),
    y: Math.abs(divide(dX, maxAbs(dX, dY))),
  }

  return {
    start,
    end: [x0 + dX * scale.x, y0 + dY * scale.y] as Point2D,
    width: size,
    height: size,
  }
}

export const getBoundingBoxByPointer = ({
  event,
  initialOrigin,
  start,
  viewportData,
  isShiftKey = false,
}: DrawRectParams) => {
  const { W, H, gutter, normalizeFactor } = viewportData
  const [sX0, sY0] = initialOrigin
  const [x0, y0] = start

  const [sX1, sY1] = getUnscaledStagePosition(event)
  const [x1, y1] = getUnscaledPointerPosition(event)
  const maxX = W / normalizeFactor
  const maxY = H / normalizeFactor

  const [startX, startY] = normalize(x0 - (sX0 + gutter.x), y0 - (sY0 + gutter.y), normalizeFactor)
  const [endX, endY] = normalize(x1 - (sX1 + gutter.x), y1 - (sY1 + gutter.y), normalizeFactor)

  const boundedStart: Point2D = [
    clampMinMax(startX, MIN_POSITION, maxX - MIN_POSITION),
    clampMinMax(startY, MIN_POSITION, maxY - MIN_POSITION),
  ]
  const boundedEnd: Point2D = [
    clampMinMax(endX, MIN_POSITION, maxX - MIN_POSITION),
    clampMinMax(endY, MIN_POSITION, maxY - MIN_POSITION),
  ]

  if (isShiftKey) {
    return getSquaredBoundingBox(boundedStart, boundedEnd)
  }
  const { width, height } = getDimensionSize(boundedStart, boundedEnd)

  return {
    start: boundedStart,
    end: boundedEnd,
    width,
    height,
  }
}

export const isAbruptlyTerminated = (e: KonvaEventObject<MouseEvent>) => !e.evt

/* 마우스 관련 유틸 함수 */
export const MOUSE_LEFT = { button: 0, buttons: 1 }

export const MOUSE_WHEEL = { button: 1, buttons: 4 }

export const MOUSE_RIGHT = { button: 2, buttons: 2 }

const isMouseMove = (e: KonvaEventObject<MouseEvent>) => e.evt.type === 'mousemove'

const isMoveClick = (e: KonvaEventObject<MouseEvent>) => ['mousedown', 'mouseup'].includes(e.evt.type)

export const isWheelClicked = (e: KonvaEventObject<MouseEvent>) => (isMouseMove(e)
  ? e.evt.buttons === MOUSE_WHEEL.buttons
  : e.evt.button === MOUSE_WHEEL.button)

export const isWheelDbClicked = (e: KonvaEventObject<MouseEvent>) => e.evt.detail === 2 && e.evt.button === MOUSE_WHEEL.button

export const isLeftClicked = (e: KonvaEventObject<MouseEvent>) => (isMouseMove(e)
  ? e.evt.buttons === MOUSE_LEFT.buttons
  : e.evt.button === MOUSE_LEFT.button)

export const isLeftDbClicked = (e: KonvaEventObject<MouseEvent>) => e.evt.detail === 2 && e.evt.button === MOUSE_LEFT.button

export const isRightClicked = (e: KonvaEventObject<MouseEvent>) => (isMouseMove(e)
  ? e.evt.buttons === MOUSE_RIGHT.buttons
  : e.evt.button === MOUSE_RIGHT.button)

export const isTrackPadPinch = (event: WheelEvent) => event.ctrlKey

/* 도형 Transform 관련 유틸 함수 */
export const isMutated = (...pairs: [prev: number, next: number][]) => pairs.some((p) => isNotEqual(...p))

/* 도형 이름 관련 유틸 함수 */
const reservedNames = new Set(Object.values(GROUP_NAMES))
const resolveUniqueName = (name: string) => (!reservedNames.has(name) ? name : '')

export const getShapeIdFromName = (target: Nullable<Konva.Stage | Konva.Group | Konva.Shape>) => {
  if (!target) {
    return null
  }
  let name = resolveUniqueName(target.name())
  let parent = target.getParent()

  while (parent && !(parent instanceof Konva.Layer) && !name) {
    name = resolveUniqueName(parent.name())
    parent = parent.getParent()
  }
  const baseShapeId = name.split(CHILDREN_SEPARATOR).at(0)
  const isFocusedOnShape = !!name && !!baseShapeId && !isAnchor(name)

  return isFocusedOnShape ? baseShapeId : null
}

/* Transformer, Anchor 관련 유틸 함수 */
export const isTransforming = (tr: RefObject<Konva.Transformer>) => tr.current?.isTransforming() ?? false

export const isTransformOf = (type: 'rotate' | 'scale', tr: RefObject<Konva.Transformer>) => {
  const trNode = tr.current

  if (!trNode || !trNode.isTransforming()) {
    return false
  }
  const activeAnchor = trNode?.getActiveAnchor() ?? ''
  const isRotating = isRotateAnchor(activeAnchor)

  if (isRotating && type === 'rotate') {
    return true
  }
  if (!isRotating && type === 'scale') {
    return true
  }
  return false
}

export const isAnchor = (name: string) => name.includes(ANCHOR_NAME_SUFFIX)

export const isRotateAnchor = (name: string) => name.includes(ROTATE_ANCHOR_PREFIX)

/**
 * Rectangle, Ellipse에 사용되는 anchor 목록을 반환.
 * @note anchors를 별도의 상수로 분리해 반환하면 각 도형에서 transform 도중 Stage 확대/축소 시 Transformer가 도형에 맞춰서 변하지 않는 문제가 있음
 */
export const getAnchors = (isShiftKey: boolean) => (isShiftKey ? [
  'top-left',
  'top-right',
  'bottom-left',
  'bottom-right',
] : [
  'top-left',
  'top-center',
  'top-right',
  'middle-right',
  'middle-left',
  'bottom-left',
  'bottom-center',
  'bottom-right',
])
