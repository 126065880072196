import { CommonListParams, withOptions } from '@/api/react-query/queryKeys/common'

export const labelingNodeKeys = {
  all: ['labelingNode'],
  detail: (nodeId: string) => [...labelingNodeKeys.all, 'details', nodeId],
  files: (nodeId: string, options?: CommonListParams) => withOptions(
    [...labelingNodeKeys.detail(nodeId), 'files'],
    options
  ),
  fileSegment: (nodeId: string, start: number, end: number) => [
    ...labelingNodeKeys.files(nodeId),
    { start, end },
  ],
  file: (nodeId: string, itemId: string) => [...labelingNodeKeys.files(nodeId), 'file', itemId],
  objects: (nodeId: string, itemId: string) => [...labelingNodeKeys.file(nodeId, itemId), 'objects'],
  classes: (nodeId: string) => [...labelingNodeKeys.detail(nodeId), 'userClasses'],
  userMeta: (nodeId: string) => [...labelingNodeKeys.detail(nodeId), 'userMeta'],
}
