import React, { forwardRef, FunctionComponent, MouseEvent, useLayoutEffect, useRef } from 'react'
import styled from 'styled-components'
import classNames from 'classnames'
import { get } from 'lodash'

import { buttonColor, buttonSize, buttonType, buttonVariant } from '@ahha/stableComponents/Button/types'
import { mergeRefs } from '@ahha/utils/reactDOM'

interface ImgButtonProps {
  src: string | FunctionComponent;
  alt: string;
  onClick: (e?: MouseEvent<HTMLButtonElement>) => void;
  width?: string;
  height?: string;
  id?: string;
  type?: buttonType;
  color?: buttonColor;
  size?: buttonSize;
  variant?: buttonVariant;
  shape?: 'circle' | 'square',
  disabled?: boolean;
  tooltip?: string;
  className?: string;
  selected?: boolean;
}

const StyledImgButton = styled.button<Pick<ImgButtonProps, 'width' | 'height'> & { color: buttonColor }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ width }) => width ?? 'auto'};
  height: ${({ height }) => height ?? 'auto'};
  position: relative;
  border-radius: 4px;

  span {
    position: absolute;
    top: calc(100% + 0.5rem);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${({ theme }) => theme.colors?.bg.primary};
    color: ${({ theme }) => theme.colors?.text?.inverse};
    border-radius: 4px;
    box-shadow: 0px 4px 4px rgba(17, 17, 17, 0.25);
    height: 2rem;
    width: max-content;
    line-height: 1rem;
    padding: 0.5rem;
    pointer-events: none;
    opacity: 0;
    transition: 500ms all;
    z-index: 5;
    @media (hover: none) {
      display: none;
    }
  }

  &:hover span {
    opacity: 1;
  }

  &.lg {
    width: ${({ theme, width }) => width || theme.variables.pxToRem(32)};
    height: ${({ theme, height }) => height || theme.variables.pxToRem(32)};
  }

  &.md {
    // TODO: change width, height
    width: ${({ theme, width }) => width || theme.variables.pxToRem(24)};
    height: ${({ theme, height }) => height || theme.variables.pxToRem(24)};
  }

  &.sm {
    // TODO: change width, height
    width: ${({ theme, width }) => width || theme.variables.pxToRem(32)};
    height: ${({ theme, height }) => height || theme.variables.pxToRem(32)};
  }

  &.filled {
    background-color: ${({ theme, color }) => get(theme, `colors.bg[${color}]`)};
    color: ${({ theme }) => theme.colors.text.inverse};
    border: ${({ theme, color }) => `1px solid ${get(theme, `colors.bg[${color}]`)}`};
    &.primary:hover {
      background-color: ${({ theme }) => theme.colors.bg.primaryHover};
    }
    &.disabled {
      background-color: ${({ theme, color }) => theme.colors.bg.disabled} !important;
    }
  }

  &.outlined {
    background-color: unset;
    border: ${({ theme, color }) => `1px solid ${get(theme, `colors.bg[${color}]`)}`};
    color: ${({ theme, color }) => theme.colors.text[color]};
    &.primary {
      border: ${({ theme, color }) => `1px solid ${theme.colors.border.border4}`};
    }
    &:hover {
      background-color: ${({ theme }) => theme.colors.bg.hover};
    }
    &.disabled {
      background-color: ${({ theme }) => theme.colors.bg.hover};
      path {
        stroke: ${({ theme }) => theme.colors.border.border4} !important;
      }
    }
  }

  &.transparent {
    background-color: unset;
    border: unset;
    color: ${({ theme, color }) => theme.colors.text[color]};
    &:not(.disabled):hover {
      background-color: ${({ theme }) => theme.colors.bg.hover};
    }
    &.selected {
      background-color: ${({ theme, color }) => theme.colors.bg.select};
    }
    &.disabled {
      path {
        stroke: ${({ theme }) => theme.colors.border.border4} !important;
      }
    }
  }

  &:not(.disabled) {
    cursor: pointer;
  }
  &.square {
    border-radius: 4px;
  }
  &.circle {
    border-radius: 50%;
  }
`

const ImgButton = forwardRef<HTMLButtonElement, ImgButtonProps>(({
  src: Src,
  alt,
  type = 'button',
  width,
  height,
  disabled = false,
  id,
  tooltip,
  className,
  selected = false,
  onClick,
  color = 'primary',
  size = 'lg',
  variant = 'filled',
  shape = 'circle',
}, ref) => {
  const imgButtonClass = classNames('common-img-button', {
    disabled,
    selected,
    [color]: true,
    [size]: true,
    [variant]: true,
    [shape]: true,
  })

  const buttonRef = useRef<HTMLButtonElement>(null)
  const tooltipRef = useRef<HTMLSpanElement>(null)

  useLayoutEffect(() => {
    if (!buttonRef.current || !tooltipRef.current) {
      return
    }

    const viewportW = document.documentElement.offsetWidth
    const tooltipW = tooltipRef.current.offsetWidth
    const buttonX = buttonRef.current.offsetLeft

    if (buttonX + tooltipW >= viewportW) {
      tooltipRef.current.style.left = `${-tooltipW / 1.5}px`
    }
  }, [])

  const isString = typeof Src === 'string'
  return (
    <StyledImgButton
      className={`${imgButtonClass} ${className}`}
      type={type}
      aria-label={isString ? undefined : alt}
      onClick={onClick}
      id={id}
      ref={mergeRefs(buttonRef, ref)}
      width={width}
      height={height}
      color={color}
      disabled={disabled}
    >
      {isString ? <img src={Src} alt={alt} /> : <Src />}
      {/** TODO:ksh: 툴팁 사용 안 하면 제거 - 2024.06.07 */}
      {tooltip && (
        <span
          className="common-img-button__tooltip"
          ref={tooltipRef}
        >
          {tooltip}
        </span>
      )}
    </StyledImgButton>
  )
})

export default ImgButton
