import { createAsyncThunk } from '@reduxjs/toolkit'

import {
  BoxObject,
  EllipseObject,
  LabelingObjectData,
  PolygonObject,
  ThunkConfig
} from '@/stores/slices/Labeling/types'
import { AnnotationType } from '@/pages/Labeling/types'
import { API } from '@/api'
import { serializeObject } from '@/stores/slices/Labeling/utils'
import { socket } from '@/socket'
import { LabelingObject } from '@/api/LabelingNode/types'

type AddBoxPayload = Omit<BoxObject, 't' | 'modifiedAt' | 'createdAt'> & { itemId: string }

type AddEllipsePayload = Omit<EllipseObject, 't' | 'modifiedAt' | 'createdAt'> & { itemId: string }

type AddPolygonPayload = Omit<PolygonObject, 't' | 'modifiedAt' | 'createdAt'> & { itemId: string }

type ObjectCreatedPayload = { object: LabelingObjectData, itemId: string }

// TODO: 로직 같이 쓸 수 있게 리팩토링?
export const addBox = createAsyncThunk<ObjectCreatedPayload, AddBoxPayload, ThunkConfig>(
  'labeling/add-box-object',
  async (payload, thunkAPI) => {
    const { itemId, ...box } = payload
    const t: AnnotationType = 'box'
    const now = new Date()
    const { user } = thunkAPI.getState().user
    const { tool: { class: currentClass }, labelingNodeId, annotation, files, currentIndex } = thunkAPI.getState().labeling
    const fileId = files[currentIndex]?._id
    const allObjects = fileId ? annotation[fileId].object : []
    const tagOrder = allObjects.length + 1

    const object: LabelingObjectData = {
      ...box,
      label: box.label ?? currentClass,
      t,
      modifiedAt: now,
      createdAt: now,
      name: tagOrder,
    }
    const res = await API().Labeling.createObject(serializeObject(object, itemId, tagOrder))
    socket.emitCreateObject({
      nodeId: labelingNodeId,
      userId: user._id,
      userName: user.name,
      itemId,
      createdObject: res,
    } as any)
    return { object: { ...object, id: res._id }, itemId }
  }
)

export const addEllipse = createAsyncThunk<ObjectCreatedPayload, AddEllipsePayload, ThunkConfig>(
  'labeling/add-ellipse-object',
  async (payload, thunkAPI) => {
    const { itemId, ...ellipse } = payload
    const t: AnnotationType = 'ellipse'
    const now = new Date()
    const { user } = thunkAPI.getState().user
    const { tool: { class: currentClass }, labelingNodeId, annotation, files, currentIndex } = thunkAPI.getState().labeling
    const fileId = files[currentIndex]?._id
    const allObjects = fileId ? annotation[fileId].object : []
    const tagOrder = allObjects.length + 1

    const object: LabelingObjectData = { ...ellipse, label: ellipse.label ?? currentClass, t, modifiedAt: now, createdAt: now, name: tagOrder }
    const res = await API().Labeling.createObject(serializeObject(object, itemId, tagOrder))
    socket.emitCreateObject({
      nodeId: labelingNodeId,
      userId: user._id,
      userName: user.name,
      itemId,
      createdObject: res,
    } as any)
    return { object: { ...object, id: res._id }, itemId }
  }
)

export const addPolygon = createAsyncThunk<ObjectCreatedPayload, AddPolygonPayload, ThunkConfig>(
  'labeling/add-polygon-object',
  async (payload, thunkAPI) => {
    const { itemId, ...polygon } = payload
    const t: AnnotationType = 'polygon'
    const now = new Date()
    const { user } = thunkAPI.getState().user
    const { tool: { class: currentClass }, labelingNodeId, annotation, files, currentIndex } = thunkAPI.getState().labeling
    const fileId = files[currentIndex]?._id
    const allObjects = fileId ? annotation[fileId].object : []
    const tagOrder = allObjects.length + 1

    const object: LabelingObjectData = { ...polygon, label: polygon.label ?? currentClass, t, modifiedAt: now, createdAt: now, name: tagOrder }
    const res = await API().Labeling.createObject(serializeObject(object, itemId, tagOrder))
    socket.emitCreateObject({
      nodeId: labelingNodeId,
      userId: user._id,
      userName: user.name,
      itemId,
      createdObject: res,
    } as any)
    return { object: { ...object, id: res._id }, itemId }
  }
)
