import { RefObject } from 'react'
import Konva from 'konva'
import { KonvaEventObject } from 'konva/lib/Node'

import { CornerAnchors, RectInfo, RectSnapshot } from '@ahha/components/Canvas/types/shape'
import { Point2D, Vector2D } from '@ahha/components/Canvas/types/point'
import { isKonvaEvent } from '@ahha/components/Canvas/types/typeGuard'

import { DEFAULT_SNAPSHOT_ID } from '@ahha/components/Canvas/utils/hooks'
import { SQUARE_SNAPSHOT_ID, MIN_RECT_SIZE } from '@ahha/components/Canvas/Rectangle/const'
import { isRotateAnchor } from '@ahha/components/Canvas/utils/konva'
import { PINNED_ANCHOR } from '@ahha/components/Canvas/const'

export const getRectInfo = (rect: Konva.Rect | null) => {
  if (!rect) {
    return undefined
  }
  return {
    ...rect.position(),
    ...rect.size(),
    rotation: rect.rotation(),
    scaleX: rect.scaleX(),
    scaleY: rect.scaleY(),
  }
}

export const updateRectInternalState = (
  node: KonvaEventObject<Event> | RefObject<Konva.Rect>,
  data: RectSnapshot,
  resetScale = true
) => {
  const shape = isKonvaEvent(node) ? node.target : node.current
  const { x, y, width, height, rotation, scaleX, scaleY } = data

  if (!shape) {
    return
  }
  shape.position({ x, y })
  shape.size({ width: width * scaleX, height: height * scaleY })
  shape.rotation(rotation)
  /** transform 도중 발생하는 skew 제거 */
  shape.skew({ x: 0, y: 0 })

  if (resetScale) {
    shape.scale({ x: 1, y: 1 })
  }
}

/**
 * @param tl `Rectangle`의 좌측 상단(top-left) 좌표
 * @param rotation `Rectangle`의 회전 각도(degree)
 */
export const getBoundingRect = ([x0, y0]: Point2D, width: number, height: number, rotation: number) => {
  const rad = rotation * (Math.PI / 180)
  const P1 = [x0 + width * Math.cos(rad), y0 + width * Math.sin(rad)]
  const P2 = [x0 + width * Math.cos(rad) - height * Math.sin(rad), y0 + width * Math.sin(rad) + height * Math.cos(rad)]
  const P3 = [x0 - height * Math.sin(rad), y0 + height * Math.cos(rad)]

  const minX = Math.min(x0, P1[0], P2[0], P3[0])
  const maxX = Math.max(x0, P1[0], P2[0], P3[0])
  const minY = Math.min(y0, P1[1], P2[1], P3[1])
  const maxY = Math.max(y0, P1[1], P2[1], P3[1])

  return {
    x: minX,
    y: minY,
    width: maxX - minX,
    height: maxY - minY,
  }
}

export const getRectangleCenter = ({ x, y, width, height, rotation }: RectInfo) => {
  const rad = rotation * (Math.PI / 180)

  return {
    cx: x + (width / 2) * Math.cos(rad) - (height / 2) * Math.sin(rad),
    cy: y + (width / 2) * Math.sin(rad) + (height / 2) * Math.cos(rad),
  }
}

export const getRectInitialSnapshot = (data: RectSnapshot) => ({
  [DEFAULT_SNAPSHOT_ID]: {
    x: data.x,
    y: data.y,
    width: data.width,
    height: data.height,
    rotation: data.rotation,
    scaleX: 1,
    scaleY: 1,
  },
  [SQUARE_SNAPSHOT_ID]: {
    x: data.x,
    y: data.y,
    width: Math.min(data.width, data.height),
    height: Math.min(data.width, data.height),
    rotation: data.rotation,
    scaleX: 1,
    scaleY: 1,
  },
}) as const

/**
 * 도형이 캔버스의 가장자리에 있을 때, 대각선 anchor를 캔버스 외부를 거쳐서 반대편 anchor 위치로 드래그하는 경우
 * 도형 크기 및 위치가 비정상적으로 변하는 문제가 있어 크기 조절 중 위치가 변하지 않는 점이 항상 존재한다는 점을 이용해 이를 방지.
 *
 * `memo.setLastValid(DEFAULT_SNAPSHOT_ID, updateDefaultSnapshot(e.fixedAt, e.transformer))`
 *  */
export const updateDefaultSnapshot = (fixedPoint: Point2D, transformer: Konva.Transformer | null) => (data: RectSnapshot, prev: RectSnapshot) => {
  const anchor = transformer?.getActiveAnchor() ?? ''
  const fixedAnchor = PINNED_ANCHOR[anchor]

  if (isRotateAnchor(anchor)) {
    return { ...data, rotation: data.rotation }
  }
  const { width, height, scaleX, scaleY } = data
  const currentW = width * scaleX
  const currentH = height * scaleY

  const [px, py] = fixedPoint
  const [vx, vy] = getVectorToTopLeft(fixedAnchor, prev.rotation, currentW, currentH)

  /**
   * FIXME:ksh:
   * 마우스가 캔버스 외부에 있는 상태에서 끌고 있는 anchor가 반대편으로 넘어갈 때,
   * width가 fixedPoint 기준이 아니라 마우스가 캔버스를 넘어가기 전의 측면 기준으로 전달되는 문제
   * - 2024.04.25
   * */
  /**
   * FIXME:ksh:
   * 대각선 anchor를 캔버스 외부로 끌고 나서 다시 캔버스로 돌아오면 도형 크기 조절이 멈추는 문제
   * 캔버스 안으로 더 많이 끌고 와야 크기 조절이 다시 시작됨
   * - 2024.04.25
   * */
  return {
    ...data,
    /** Shift를 누른 상태에서 middle anchor를 움직이면 회전 변환이 아님에도 rotation이 달라지는 문제가 있어 rotation 고정 */
    rotation: prev.rotation,
    x: px + vx,
    y: py + vy,
  }
}

/** @deprecated 생성된 도형에 대해서는 Shift로 정사각형 그리는 기능 제거됨. */
export const updateSquareSnapshot = (fixedAnchor?: CornerAnchors, fixedPoint?: Point2D) => (data: RectSnapshot, prev: RectSnapshot, defaultSnapshot: RectSnapshot) => {
  const { width, height, scaleX, scaleY } = defaultSnapshot

  const size = Math.max(MIN_RECT_SIZE, Math.min(width * scaleX, height * scaleY))
  const squareData = { width: size, height: size, scaleX: 1, scaleY: 1 }

  if (!fixedAnchor || !fixedPoint) {
    return { ...data, ...squareData }
  }
  const [tlX, tlY] = getSquareTopLeftPoint(defaultSnapshot, fixedAnchor, fixedPoint)

  return {
    ...defaultSnapshot,
    ...squareData,
    x: tlX,
    y: tlY,
  }
}

export const getRectFixedPoint = (data: RectSnapshot, fixedAnchor: CornerAnchors): Point2D => {
  const { x, y, width, height, rotation, scaleX, scaleY } = data
  const rad = rotation * (Math.PI / 180)
  const scaledW = width * scaleX
  const scaledH = height * scaleY

  const cosA = Math.cos(rad)
  const sinA = Math.sin(rad)

  if (fixedAnchor === 'tl') {
    return [x, y]
  }
  if (fixedAnchor === 'tr') {
    return [x + scaledW * cosA, y + scaledW * sinA]
  }
  if (fixedAnchor === 'bl') {
    return [x - scaledH * sinA, y + scaledH * cosA]
  }
  if (fixedAnchor === 'br') {
    return [x + scaledW * cosA - scaledH * sinA, y + scaledW * sinA + scaledH * cosA]
  }
  return [NaN, NaN]
}

/** fixedAnchor에서 Rectangle의 tl(top-left) 점을 향하는 방향 벡터를 계산 */
const getVectorToTopLeft = (
  fixedAnchor: CornerAnchors,
  rotation: number,
  width: number,
  height: number
): Vector2D => {
  const radian = rotation * (Math.PI / 180)
  const cosA = Math.cos(radian)
  const sinA = Math.sin(radian)

  if (fixedAnchor === 'tl') {
    return [0, 0]
  }
  if (fixedAnchor === 'tr') {
    return [-width * cosA, -width * sinA]
  }
  if (fixedAnchor === 'bl') {
    return [height * sinA, -height * cosA]
  }
  if (fixedAnchor === 'br') {
    return [-width * cosA + height * sinA, -width * sinA - height * cosA]
  }
  return [NaN, NaN]
}

export const getSquareTopLeftPoint = (data: RectSnapshot, fixedAnchor: CornerAnchors, fixedPoint: Point2D): Point2D => {
  const { width, height, rotation, scaleX, scaleY } = data
  const size = Math.max(MIN_RECT_SIZE, Math.min(width * scaleX, height * scaleY))

  /**
   * 사각형 anchor를 움직일 때 정반대에 있는 점의 위치는 반드시 일정해야 하므로
   * 고정점 `[px, py]`를 기준으로 사각형의 tl 지점의 좌표를 계산
   * */
  const [px, py] = fixedPoint
  const [vx, vy] = getVectorToTopLeft(fixedAnchor, rotation, size, size)

  return [px + vx, py + vy]
}
