import { Draft, PayloadAction } from '@reduxjs/toolkit'
import { v4 as uuidv4, validate as uuidValidate } from 'uuid'
import { get } from 'lodash'

import { AnnotationType } from '@/pages/Lisa/Label/types'
import { AnnotationObjectData, AnnotationState, BoxObject, EllipseObject, PolygonObject, TagObject } from '@/stores/slices/Lisa/annotation/types'
import { _addSnapshot, _getLastSnapshot } from '@/stores/slices/Lisa/annotationSlice'

type AddBoxPayload = Omit<BoxObject, 't' | 'modifiedAt' | 'createdAt'> & { fileId: string }

type AddEllipsePayload = Omit<EllipseObject, 't' | 'modifiedAt' | 'createdAt'> & { fileId: string }

type AddPolygonPayload = Omit<PolygonObject, 't' | 'modifiedAt' | 'createdAt'> & { fileId: string }

const _addObject = (state: Draft<AnnotationState>, fileId: string, object: AnnotationObjectData) => {
  if (state.isInProgress) {
    console.error('Other requests is in progress')
    return
  }
  const last = _getLastSnapshot(state, fileId)
  const updatedObjects = [
    ...last.object,
    {
      ...object,
      id: object.id ?? uuidv4(),
      label: state.tool.class,
    },
  ]

  const snapshot = {
    labelOk: false,
    object: updatedObjects,
  }
  _addSnapshot(state, fileId, snapshot)
}

export const createObjectReducers = {
  addBox: (state: Draft<AnnotationState>, action: PayloadAction<AddBoxPayload>) => {
    const { fileId, ...box } = action.payload
    const t: AnnotationType = 'box'
    const now = new Date()

    _addObject(state, fileId, { ...box, t, modifiedAt: now, createdAt: now })
  },
  addEllipse: (state: Draft<AnnotationState>, action: PayloadAction<AddEllipsePayload>) => {
    const { fileId, ...ellipse } = action.payload
    const t: AnnotationType = 'ellipse'
    const now = new Date()

    _addObject(state, fileId, { ...ellipse, t, modifiedAt: now, createdAt: now })
  },
  addPolygon: (state: Draft<AnnotationState>, action: PayloadAction<AddPolygonPayload>) => {
    const { fileId, ...polygon } = action.payload
    const t: AnnotationType = 'polygon'
    const now = new Date()

    _addObject(state, fileId, { ...polygon, t, modifiedAt: now, createdAt: now })
  },

  // for classification
  addTag: {
    reducer: (state: Draft<AnnotationState>, action: PayloadAction<{ fileId: string, classId: string }>) => {
      const { fileId, classId } = action.payload
      const last = _getLastSnapshot(state, fileId)
      const now = new Date()
      const objectId = get(last?.object[0], 'id', uuidv4())
      const object: TagObject = {
        id: objectId,
        label: classId,
        t: 'tag',
        createdAt: get(last?.object, '[0].createdAt', now),
        modifiedAt: now,
        isEdited: !uuidValidate(objectId),
      }
      const newObjects = [object]
      const snapshot = {
        labelOk: false,
        object: newObjects,
      }
      _addSnapshot(state, fileId, snapshot)
    },
    prepare: (fileId: string, classId: string) => ({
      payload: {
        fileId,
        classId,
      },
    }),
  },
}
