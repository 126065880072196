import { LookupTable } from '@ahha/utils/@types/typeUtils'
import { FileType } from '@/api/DataStorage/types'
import { Tool } from '@/stores/slices/Labeling/types'

export type LabelListType = {
  id: string,
  name: string,
  type: FileType,
  createdAt: string,
  generator: string,
  modifiedAt: string,
  changedBy: string,
  labeling: number[],
}

export const ObjDetToolType = {
  POINTER: 'pointer',
  RECTANGLE: 'rectangle',
} as const

export const ToolType: LookupTable<Exclude<Tool, 'classify'>, 'uppercase'> = {
  POINTER: 'pointer',
  RECTANGLE: 'rectangle',
  ELLIPSE: 'ellipse',
  POLYGON: 'polygon',
  BRUSH: 'brush',
  MAGICWAND: 'magicWand',
  COMMENT: 'comment',
}

export const LabelShortcuts = {
  POINTER: 'KeyA',
  CLASSIFY: 'KeyL',
  RECTANGLE: 'KeyS',
  ELLIPSE: 'KeyD',
  POLYGON: 'KeyF',
  BRUSH: 'KeyG',
  UNDO: 'Ctrl+KeyZ',
  REDO: 'Ctrl+Shift+KeyZ',
  OPACITY: 'KeyO',
  MAGICWAND: 'KeyM',
  CLASS_FILTER: 'KeyF',
  ZOOM_IN: ['Equal', 'NumpadAdd'],
  ZOOM_OUT: ['Minus', 'NumpadSubtract'],
  ESCAPE: 'Escape',
  PREVIOUS_TOOL: 'KeyN',
  PREVIOUS_IMAGE: 'KeyQ',
  NEXT_IMAGE: 'KeyW',
} as const

export type DrawingRegion = {
  points?: any[],
  cursorPosition?: any,
  lines?: any,
  id: string,
  date: Date,
  type: (typeof ToolType)[keyof typeof ToolType],
  fill: string,
  x: number,
  y: number,
  width: number,
  height: number,
  is_hide: boolean,
  isDrawing: boolean,
}

export type ViewPortType = {
  x: number;
  y: number;
  width: number;
  height: number;
}

export type OldViewPortType = {
  imageLeft: number,
  imageTop: number,
  oldWidth: number,
  oldHeight: number,
  pointerX: number,
  pointerY: number,
}

export type AnnotationType = 'box' | 'polygon' | 'ellipse' | 'tag'

export type FocusedObjectType = {
  className: string,
  color: string,
  isVisible: boolean,
  id?: string | undefined,
  t: AnnotationType,
  label?: string | null,
  createdAt: Date,
  modifiedAt: Date,
  isPathEditable?: boolean,
}
