import { map, isPlainObject, isEmpty, filter, values, zipObject, flatten, assign } from 'lodash'
import dayjs from 'dayjs'

import {
  AdvancedFieldConfigType,
  AdvancedFilterConfigType,
  QueryConfigType
} from '@/pages/DataCamp/components/DataQuery/types'
import { RangeValues } from '@/pages/DataCamp/components/DataQuery/const'
import { generateMonthFilter, generateSubtractFilter, generateWeekFilter } from '@/pages/DataCamp/utils/dateUtils'
import { queryConfig } from '@/stores/slices/Datacamp/querySlice'

const parseNumericString = (value: string | number) => {
  if (Number.isNaN(Number(value))) {
    return value
  }
  return Number(value)
}

// eslint-disable-next-line consistent-return
const getDateQuery = (value: any, referenceSetting: any, operator: any) => {
  const { dateValues, option } = value
  const { startDay, startHour } = referenceSetting
  let fromValue = dayjs().subtract(dateValues.startValue, dateValues.startUnit).toDate()
  let toValue = dayjs().subtract(dateValues.endValue, dateValues.endUnit).toDate()
  if (option === RangeValues.TODAY || option === RangeValues.YESTERDAY || option === RangeValues.LAST_7_DAYS || option === RangeValues.LAST_14_DAYS || option === RangeValues.LAST_30_DAYS || option === RangeValues.LAST_90_DAYS || option === RangeValues.LAST_180_DAYS) {
    const { from, to } = generateSubtractFilter(dateValues.startValue, dateValues.endValue, startHour)
    fromValue = from
    toValue = to
  }
  if (option === RangeValues.THIS_WEEK) {
    const { from, to } = generateWeekFilter(0, startDay, startHour)
    fromValue = from
    toValue = to
  }
  if (option === RangeValues.LAST_WEEK) {
    const { from, to } = generateWeekFilter(1, startDay, startHour)
    fromValue = from
    toValue = to
  }
  if (option === RangeValues.THIS_MONTH) {
    const { from, to } = generateMonthFilter(0, startHour)
    fromValue = from
    toValue = to
  }
  if (option === RangeValues.LAST_MONTH) {
    const { from, to } = generateMonthFilter(1, startHour)
    fromValue = from
    toValue = to
  }

  if (operator === '$gt' || operator === '$lte') {
    return { [operator]: toValue }
  }
  if (operator === '$lt' || operator === '$gte') {
    return { [operator]: fromValue }
  }
  return { $gte: fromValue, $lte: toValue }
}

const getTemplateValue = (stage: string, sign: string, operator: any, fieldName: any, blockIndex?: number, rowIndex?: number) => {
  const fromTemplate = `${sign}${stage}_start_of_${fieldName}_${blockIndex}_${rowIndex}${sign}`
  const toTemplate = `${sign}${stage}_end_of_${fieldName}_${blockIndex}_${rowIndex}${sign}`
  if (operator === '$gt' || operator === '$gte') {
    return { [operator]: fromTemplate }
  }
  if (operator === '$lt' || operator === '$lte') {
    return { [operator]: toTemplate }
  }

  return { $gte: fromTemplate, $lte: toTemplate }
}

export const getDateParameters = (filterConfig: QueryConfigType['filter'], referenceSetting: QueryConfigType['referenceSetting']) => {
  const { list } = filterConfig
  const parameters = map(list, (f, blockIndex) => (map(f.rows, (r, index) => {
    if (isPlainObject(r.value)) {
      const dateKeys = values(getTemplateValue('match', '', r.operator, r.field, blockIndex, index))
      const dateValues = values(getDateQuery(r.value, referenceSetting, r.operator))
      return zipObject(dateKeys, dateValues)
    }
    return {}
  })))
  return assign({}, ...filter(flatten(parameters), (p) => !isEmpty(p)))
}

const extractConditionStatement = (field: AdvancedFieldConfigType, referenceSettings: any, isInArray = false, trueVal?: any, falseVal?: any, isTemplate = false, index?: number) => {
  if (isPlainObject(field.value)) {
    const date: any = getDateQuery(field.value, referenceSettings, field.operator)
    const templateValue: any = getTemplateValue('group', '##', field.operator, field.newName || field.fieldId, index, 0)
    const templateKey: any = getTemplateValue('group', '', field.operator, field.newName || field.fieldId, index, 0)
    const parameter = zipObject(values(templateKey), values(date))
    if (field.operator === '$eq') {
      return {
        query: isInArray
          ? { $and: [{ $gte: ['$$this', isTemplate ? templateValue.$gte : date.$gte] }, { $lte: ['$$this', isTemplate ? templateValue.$lte : date.$lte] }] }
          : { $cond: [{ $and: [{ $gte: [`$${field.fieldId}`, isTemplate ? templateValue.$gte : date.$gte] }, { $lte: [`$${field.fieldId}`, isTemplate ? templateValue.$lte : date.$lte] }] }, trueVal, falseVal] },
        parameter,
      }
    }
    return {
      query: isInArray
        ? { [field.operator]: ['$$this', isTemplate ? templateValue[field.operator] : date[field.operator]] }
        : { $cond: [{ [field.operator]: [`$${field.fieldId}`, isTemplate ? templateValue[field.operator] : date[field.operator]] }, trueVal, falseVal] },
      parameter,
    }
  }
  return {
    query: isInArray
      ? { [field.operator]: ['$$this', parseNumericString(field.value)] }
      : { $cond: [{ [field.operator]: [`$${field.fieldId}`, parseNumericString(field.value)] }, trueVal, falseVal] },
    parameter: {},
  }
}

// TODO: check empty list validations
export const constructMatchStage = (filterConfig: QueryConfigType['filter'], referenceSetting: QueryConfigType['referenceSetting']) => {
  const { list, condition } = filterConfig

  if (!list.length) {
    return []
  }

  return [
    {
      $match: { [condition]: map(list, (f) => ({
        [f.conditional]: map(f.rows, (r) => ({
          [r.field]: isPlainObject(r.value) ? getDateQuery(r.value, referenceSetting, r.operator) : { [r.operator]: parseNumericString(r.value) },
        })),
      })) },
    },
  ]
}

export const constructTemplateMatchStage = (filterConfig: QueryConfigType['filter'], referenceSetting: QueryConfigType['referenceSetting']) => {
  const { list, condition } = filterConfig

  if (!list.length) {
    return {
      templateMatchStage: [],
      parameter: {},
    }
  }

  const parameters = getDateParameters(filterConfig, referenceSetting)

  const templateMatchStage = [
    {
      $match: { [condition]: map(list, (f, index) => ({
        [f.conditional]: map(f.rows, (r, i) => ({
          [r.field]: isPlainObject(r.value) ? getTemplateValue('match', '##', r.operator, r.field, index, i) : { [r.operator]: parseNumericString(r.value) },
        })),
      })) },
    },
  ]
  return { templateMatchStage, parameters }
}

export const constructGroupStage = (advancedFilterConfig: AdvancedFilterConfigType, referenceSetting: QueryConfigType['referenceSetting'], isTemplate = false) => {
  const { functionType, fields, group } = advancedFilterConfig
  if (!functionType || !fields.length) {
    return { advancedFilter: [], projections: {}, parameters: {} }
  }

  const parameters = {}
  const customFields: any = {}
  const groupId = (group.fieldId && group.fieldId !== 'none')
    ? {
      [group.fieldId]: (group.value && group.operator) ? {
        $dateTrunc: {
          date: { $convert: { input: `$${group.fieldId}`, to: 'date' } },
          unit: group.operator,
          binSize: parseNumericString(group.value),
        },
      } : `$${group.fieldId}`,
    }
    : null
  const projectionFields: any = {}
  if (group.fieldId) {
    projectionFields[group.fieldId] = `$_id.${group.fieldId}`
  }

  // COUNT
  if (functionType === 'count') {
    fields.forEach((field, index) => {
      const { query, parameter } = extractConditionStatement(field, referenceSetting, false, 1, 0, isTemplate, index)
      assign(parameters, parameter)
      const condition = {
        $sum: field.operator === 'none'
          ? 1
          : query,
      }
      customFields[field.newName || field.fieldId] = condition
    })
  }

  // SUM
  if (functionType === 'sum') {
    fields.forEach((field, index) => {
      const { query, parameter } = extractConditionStatement(field, referenceSetting, false, `$${field.fieldId}`, 0, isTemplate, index)
      assign(parameters, parameter)
      const condition = {
        $sum: field.operator === 'none'
          ? `$${field.fieldId}`
          : query,
      }
      customFields[field.newName || field.fieldId] = condition
    })
  }

  // MIN, MAX, AVG
  if (functionType === 'min' || functionType === 'max' || functionType === 'avg') {
    fields.forEach((field, index) => {
      const { query, parameter } = extractConditionStatement(field, referenceSetting, false, `$${field.fieldId}`, null, isTemplate, index)
      assign(parameters, parameter)
      const condition = {
        [`$${functionType}`]: field.operator === 'none'
          ? `$${field.fieldId}`
          : query,
      }
      customFields[field.newName || field.fieldId] = condition
    })
  }

  // FIRST, LAST
  if (functionType === 'first' || functionType === 'last') {
    const groupStage: any = { $group: { _id: groupId } }
    fields.forEach((field) => {
      groupStage.$group[field.fieldId] = { $push: `$${field.fieldId}` }
    })

    const projectStage: any = { _id: 1 }
    if (groupId) {
      projectStage[group.fieldId] = `$_id.${group.fieldId}`
    }
    fields.forEach((field, index) => {
      const { query, parameter } = extractConditionStatement(field, referenceSetting, true, 1, 0, isTemplate, index)
      assign(parameters, parameter)
      projectStage[field.newName || field.fieldId] = {
        $arrayElemAt: [
          field.operator === 'none' ? `$${field.fieldId}` : {
            $filter: {
              input: `$${field.fieldId}`,
              cond: query,
            },
          },
          functionType === 'first' ? 0 : -1,
        ],
      }
    })

    fields.forEach((field) => {
      projectionFields[field.newName || field.fieldId] = {
        $ifNull: [`$${field.newName || field.fieldId}`, null],
      }
    })
    return {
      advancedFilter: [groupStage, { $project: projectStage }],
      projections: projectionFields,
      parameters,
    }
  }

  fields.forEach((field) => {
    projectionFields[field.newName || field.fieldId] = 1
  })

  // TODO: 만약에 날짜로 그룹을 했는데 빈 날짜에도 값을 채워야 한다면, $densify stage를 추가해야 함 (기획확인 필요)
  return {
    advancedFilter: [
      {
        $group: {
          _id: groupId,
          ...customFields,
        },
      },
    ],
    projections: projectionFields,
    parameters,
  }
}

export const buildPipeline = (queryConfig: QueryConfigType) => {
  const { sortList } = queryConfig
  const sort: { [key: string]: number } = {}
  if (sortList.length) {
    sortList.forEach((s) => {
      sort[s.field] = s.sort
    })
  }
  const filter = constructMatchStage(queryConfig.filter, queryConfig.referenceSetting)
  const { advancedFilter, projections } = constructGroupStage(queryConfig.advancedFilter, queryConfig.referenceSetting)
  const pipeline = [
    ...filter,
    ...advancedFilter,
    { $project: { _id: 0, ...projections } },
  ]
  if (!isEmpty(sort)) {
    pipeline.push({ $sort: sort })
  }
  return pipeline
}

export const buildTemplatePipeline = (queryConfig: QueryConfigType) => {
  const { sortList } = queryConfig
  const sort: { [key: string]: number } = {}
  if (sortList.length) {
    sortList.forEach((s) => {
      sort[s.field] = s.sort
    })
  }
  const parameterInfo = {}
  const { templateMatchStage, parameters: matchParameters } = constructTemplateMatchStage(queryConfig.filter, queryConfig.referenceSetting)
  const { advancedFilter, projections, parameters: groupParameters } = constructGroupStage(queryConfig.advancedFilter, queryConfig.referenceSetting, true)
  const pipeline = [
    ...templateMatchStage,
    ...advancedFilter,
    { $project: { _id: 0, ...projections } },
  ]
  if (!isEmpty(sort)) {
    pipeline.push({ $sort: sort })
  }
  return {
    pipeline,
    parameters: assign(parameterInfo, matchParameters, groupParameters),
  }
}
