import BaseApi from '@/api/Base/BaseApi'
import {
  CreateInferenceNodePayload,
  UpdateInferenceNodePayload,
  InferenceNodeInfo,
  GetInferenceStatusResponse,
  GetInferenceResultResponse
} from '@/api/InferenceNode/types'

const ROOT = '/inference'

export default class InferenceNodeApi extends BaseApi {
  createInferenceNode(payload: CreateInferenceNodePayload) {
    const { projectId, ...rest } = payload
    return this.agent.post<InferenceNodeInfo>(`/project/${projectId}/inference/create`, rest)
  }

  getInferenceNodeDetail(inferenceNodeId: string) {
    return this.agent.get<InferenceNodeInfo>(`${ROOT}/${inferenceNodeId}`)
  }

  updateInferenceNode(payload: UpdateInferenceNodePayload) {
    const { inferenceNodeId, ...rest } = payload
    return this.agent.put<InferenceNodeInfo>(`${ROOT}/${inferenceNodeId}`, rest)
  }

  startInference(inferenceNodeId: string) {
    return this.agent.post<any>(`${ROOT}/${inferenceNodeId}/inference`)
  }

  getInferenceStatus(inferenceNodeId: string) {
    return this.agent.get<GetInferenceStatusResponse>(`${ROOT}/${inferenceNodeId}/inference/status`)
  }

  getInferenceResult(inferenceNodeId: string) {
    return this.agent.get<GetInferenceResultResponse>(`${ROOT}/${inferenceNodeId}/inference`)
  }
}
