import BaseApi from '@/api/Base/BaseApi'
import {
  CreateValidationNodePayload,
  GetValidationStatusResponse,
  GetValidationResultResponse,
  UpdateValidationNodePayload,
  ValidationNodeInfo
} from '@/api/ValidationNode/types'

const ROOT = '/validation'

export default class ValidationNodeApi extends BaseApi {
  createValidationNode(payload: CreateValidationNodePayload) {
    const { projectId, ...rest } = payload
    return this.agent.post<ValidationNodeInfo>(`/project/${projectId}/validation/create`, rest)
  }

  getValidationNodeDetail(validationNodeId: string) {
    return this.agent.get<ValidationNodeInfo>(`${ROOT}/${validationNodeId}`)
  }

  updateValidationNode(payload: UpdateValidationNodePayload) {
    const { validationNodeId, ...rest } = payload
    return this.agent.put<ValidationNodeInfo>(`${ROOT}/${validationNodeId}`, rest)
  }

  startValidation(validationNodeId: string) {
    return this.agent.post<any>(`${ROOT}/${validationNodeId}/validate`)
  }

  getValidationStatus(validationNodeId: string) {
    return this.agent.get<GetValidationStatusResponse>(`${ROOT}/${validationNodeId}/validate/status`)
  }

  getValidationResult(validationNodeId: string) {
    return this.agent.get<GetValidationResultResponse>(`${ROOT}/${validationNodeId}/validate`)
  }
}
