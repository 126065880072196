import { cloneDeep, filter, findIndex, set, find, map, get, concat, reject } from 'lodash'
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { WidgetConfigType, WidgetType } from '@/pages/DataCamp/Dashboard/types'
import { RootState } from '@/stores'

// FIXME: initial state
const initialState: WidgetConfigType[] = [
  {
    containerId: 'c2',
    items: [
      { i: 'c2:w1', w: 200, h: 100, type: 2 },
      { i: 'c2:w2', w: 100, h: 100, type: 2 },
      { i: 'c2:w3', w: 300, h: 150, type: 7, direction: 2, items: [{ i: 'c2:w4', w: 150, h: 100, type: 4 }, { i: 'c2:w5', w: 100, h: 100, type: 2 }] },
    ],
  },
]
export const widgetConfig = createSlice({
  name: 'widgetConfig',
  initialState,
  reducers: {
    updateWidgetConfig(state, action: PayloadAction<WidgetConfigType[]>) {
      return action.payload
    },
    // 컨테이너에 위젯 추가, 위젯 순서 업데이트, items는 항상 통째로 핸들링하는 로직으로 구현
    updateContainerItems(state, action: PayloadAction<{ containerId: string | null, items: WidgetType[] }>) {
      const { containerId, items } = action.payload
      const clonedState = cloneDeep(state)
      const targetContainerIndex = findIndex(clonedState, (s) => s.containerId === containerId)
      if (targetContainerIndex !== -1) {
        return set(clonedState, `[${targetContainerIndex}].items`, items)
      }
      return concat(state, [{ containerId: containerId ?? '', items }])
    },
    // 위젯 정보 하나 업데이트, 위젯 type이 컨테이너 일때 하위 items 추가도 여기서 핸들링
    updateWidgetInfo(state, action: PayloadAction<{ containerId: string | null, widgetId: string, info: WidgetType}>) {
      const { containerId, widgetId, info } = action.payload
      const clonedState = cloneDeep(state)
      const targetContainerIndex = findIndex(clonedState, (s) => s.containerId === containerId)
      const targetItems = get(clonedState, `[${targetContainerIndex}].items`, [])
      const newItems = map(targetItems, (item: WidgetType) => {
        if (item.i === widgetId) {
          return info
        }
        return item
      })
      return set(clonedState, `[${targetContainerIndex}].items`, newItems)
    },
    // 컨테이너 삭제할 경우, 컨테이너와 items 모두 삭제시킴
    removeContainerItems(state, action: PayloadAction<string>) {
      return reject(state, { containerId: action.payload })
    },
    removeWidget(state, action: PayloadAction<{ containerId: string, widgetId: string, subContainerId?: string | undefined | null }>) {
      const { containerId, widgetId, subContainerId } = action.payload
      const clonedState = cloneDeep(state)
      const targetContainerIndex = findIndex(clonedState, (s) => s.containerId === containerId)
      const targetItems = get(clonedState, `[${targetContainerIndex}].items`, [])
      if (subContainerId) {
        const newItems = map(targetItems, (item: WidgetType) => {
          if (item.i === subContainerId) {
            return {
              ...item,
              items: reject(item.items, { i: widgetId }),
            }
          }
          return item
        })
        return set(clonedState, `[${targetContainerIndex}].items`, newItems)
      }
      return set(clonedState, `[${targetContainerIndex}].items`, reject(targetItems, { i: widgetId }))
    },
    updateWidgetDataset(state, action: PayloadAction<{ containerId: string | null, widgetId?: string, data: any, subContainerId?: string | null, dataKey?: string }>) {
      const { containerId, widgetId, data, subContainerId, dataKey = 'dataset.options' } = action.payload
      const clonedState = cloneDeep(state)
      const targetContainerIndex = findIndex(clonedState, (s) => s.containerId === containerId)
      if (subContainerId) {
        const targetSubContainerIndex = findIndex(clonedState[targetContainerIndex].items, { i: subContainerId })
        const targetWidgetIndex = findIndex(clonedState[targetContainerIndex].items[targetSubContainerIndex].items, { i: widgetId })
        set(clonedState, `[${targetContainerIndex}].items[${targetSubContainerIndex}].items[${targetWidgetIndex}][${dataKey}]`, data)
      } else {
        const targetWidgetIndex = findIndex(clonedState[targetContainerIndex].items, { i: widgetId })
        set(clonedState, `[${targetContainerIndex}].items[${targetWidgetIndex}][${dataKey}]`, data)
      }
      return clonedState
    },
  },
})

export const selectContainerData = (containerId: string | null) => createSelector(
  (state: RootState) => state.widgetConfig,
  (widgetConfig) => get(find(widgetConfig, (container) => container.containerId === containerId), 'items', [])
)

export const selectWidgetData = (containerId: string | null, widgetId?: string, subContainerId?: string | null) => createSelector(
  selectContainerData(containerId),
  (widgets) => {
    if (subContainerId) {
      const targetContainerWidget = find(widgets, { i: subContainerId })
      return find(get(targetContainerWidget, 'items', []), { i: widgetId })
    }
    return find(widgets, { i: widgetId })
  }
)

export const {
  updateWidgetConfig,
  updateContainerItems,
  updateWidgetInfo,
  removeContainerItems,
  removeWidget,
  updateWidgetDataset,
} = widgetConfig.actions
export default widgetConfig.reducer
