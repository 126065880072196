import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { REHYDRATE } from 'redux-persist'
import { get } from 'lodash'
import { API } from '@/api'

export type UserType = {
  token: string;
  user: {
    email: string;
    name: string;
    _id: string;
    lastWorkspaceId: string;
    phoneNumber?: string;
    profileColor?: string;
    profileImageAccessibleUrl?: string;
  },
  isSignIn: boolean;
}

const initialState: UserType = {
  token: '',
  user: {
    email: '',
    name: '',
    _id: '',
    lastWorkspaceId: '',
    phoneNumber: '',
    profileColor: '#1891EA',
    profileImageAccessibleUrl: '',
  },
  isSignIn: false,
}

export const user = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser(state, action: PayloadAction<UserType>) {
      return {
        ...state,
        ...action.payload,
      }
    },
    setLastWorkspaceId(state, action: PayloadAction<string>) {
      return { ...state, user: { ...state.user, lastWorkspaceId: action.payload } }
    },
    setUserName(state, action: PayloadAction<string>) {
      return { ...state, user: { ...state.user, name: action.payload } }
    },
    setProfileColor(state, action: PayloadAction<string>) {
      return { ...state, user: { ...state.user, profileColor: action.payload } }
    },
    setAccessibleUrl(state, action: PayloadAction<string>) {
      return { ...state, user: { ...state.user, profileImageAccessibleUrl: action.payload } }
    },
    setUserInfo(state, action: PayloadAction<Partial<UserType['user']>>) {
      return { ...state, user: { ...state.user, ...action.payload } }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(REHYDRATE, (state, action: any) => {
      const token = get(action, 'payload.user.token')
      API().setToken(token)
    })
  },
})

export const {
  setUser,
  setLastWorkspaceId,
  setUserName,
  setProfileColor,
  setAccessibleUrl,
  setUserInfo,
} = user.actions
export default user.reducer
