import BaseApi from '@/api/Base/BaseApi'
import { GetTaskListResponse } from '@/api/Task/types'

const ROOT = '/task'
export default class TaskApi extends BaseApi {
  getTaskList(payload: any) {
    const { keyword, page, itemsPerPage, sortKey, sortDir } = payload
    return this.agent.get<GetTaskListResponse>(`${ROOT}/list`, {
      params: {
        page,
        num_per_page: itemsPerPage,
        sort_key: sortKey,
        sort_dir: sortDir,
      },
    })
  }
}
