import BaseApi from '@/api/Base/BaseApi'
import {
  CommonListPayload,
  CreateDatasetNodePayload,
  DatasetNodeInfo,
  DatasetNodeItemListResponse,
  DeleteDatasetNodeItemPayload,
  EditDatasetNodePayload,
  ImportCvatPayload,
  ImportCvatResponse,
  ImportStorageItemsToDatasetPayload,
  Item,
  UploadFilesToDatasetPayload
} from '@/api/DatasetNode/types'

const ROOT = '/project'
const getPrefix = (projectId: string, datasetNodeId?: string) => {
  if (datasetNodeId) {
    return `${ROOT}/${projectId}/dataset/${datasetNodeId}`
  }
  return `${ROOT}/${projectId}/dataset`
}
const defaultPaginationOptions = {
  itemsPerPage: 40,
  page: 0,
  sortDir: 'desc',
  sortKey: 'createdAt',
}

export default class DatasetNodeApi extends BaseApi {
  // create dataset node
  createDatasetNode(payload: CreateDatasetNodePayload) {
    const { projectId, ...rest } = payload
    return this.agent.post<DatasetNodeInfo>(`${getPrefix(projectId)}/create`, rest)
  }

  uploadFilesToDataset(payload: UploadFilesToDatasetPayload) {
    const { datasetNodeId, files } = payload
    const formData = new FormData()
    files.forEach((file) => {
      formData.append('file', file)
    })
    return this.agent.post<DatasetNodeInfo>(`/dataset/${datasetNodeId}/upload-items`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  }

  importStorageItemsToDataset(payload: ImportStorageItemsToDatasetPayload) {
    const { datasetNodeId, ...rest } = payload
    return this.agent.post<DatasetNodeInfo>(`/dataset/${datasetNodeId}/import-storage-items`, rest)
  }

  importCvat(payload: ImportCvatPayload) {
    const { projectId, file, modelType, datasetNodeMetadata, labelingNodeMetadata } = payload
    const formData = new FormData()
    formData.append('file', file)
    formData.append('modelType', modelType)
    formData.append('datasetNodeMetadata', JSON.stringify(datasetNodeMetadata))
    formData.append('labelingNodeMetadata', JSON.stringify(labelingNodeMetadata))
    return this.agent.post<ImportCvatResponse>(`/project/${projectId}/import-cvat`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  }

  // dataset node

  getDatasetNodeDetail(datasetNodeId: string) {
    return this.agent.get<DatasetNodeInfo>(`/dataset/${datasetNodeId}`)
  }

  getDatasetNodeData(datasetNodeId: string, options?: CommonListPayload) {
    const { itemsPerPage = 40, page = 0, sortDir = 'desc', sortKey = 'createdAt' } = options ?? {}
    return this.agent.get<DatasetNodeItemListResponse>(`/dataset/${datasetNodeId}/items`, {
      params: {
        page,
        num_per_page: itemsPerPage,
        sort_key: 'purpose',
        sort_dir: sortDir,
      },
    })
  }

  editDatasetNode(payload: EditDatasetNodePayload) {
    const { id, name, area } = payload
    const metadata = area ? { area } : undefined

    return this.agent.put<DatasetNodeInfo>(`/dataset/${id}`, { name, metadata })
  }

  // dataset item
  deleteDatasetNodeData(payload: DeleteDatasetNodeItemPayload) {
    const { datasetItemId } = payload
    return this.agent.delete(`/dataset-item/${datasetItemId}`)
  }

  getDatasetItem(datasetItemId: string) {
    return this.agent.get<Item>(`/dataset-item/${datasetItemId}`)
  }
}
