import { useI18n } from '@ahha/i18n/i18n'
import { useEffect, useMemo } from 'react'

import { isEmpty } from 'lodash'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'
import advancedFormat from 'dayjs/plugin/advancedFormat'

require('dayjs/locale/ko')
require('dayjs/locale/en')

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(LocalizedFormat)
dayjs.extend(advancedFormat)

export type DateTimeUtils = {
  utcToLocalTimeString: (d: string|number, format?: string, localTimezone?: string) => string,
  localTimeToUTCString: (d: string|number, format?: string, localTimezone?: string) => string,
}

export const useDateTimeUtil = () => {
  const { currentLanguage } = useI18n()
  useEffect(() => {
    dayjs.locale(currentLanguage)
  }, [currentLanguage])

  return useMemo<DateTimeUtils>(() => ({
    utcToLocalTimeString: (d: string|number, format?: string, localTimezone?: string): string => {
      let tz = dayjs.tz.guess()
      if (!isEmpty(localTimezone)) {
        tz = localTimezone!
      }
      const utcTime = dayjs.utc(d)
      return utcTime.tz(tz).format(format || 'LLLL')
    },
    localTimeToUTCString: (d: string|number, format?: string, localTimezone?: string): string => {
      let tz = dayjs.tz.guess()
      if (!isEmpty(localTimezone)) {
        tz = localTimezone!
      }
      const localTime = dayjs(d).tz(tz, true)
      return localTime.tz('utc').format(format || 'LLLL')
    },
  }), [currentLanguage])
}
