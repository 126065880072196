import axios from 'axios'

import { AhhaAxios } from '@/api/Base/types'
import { setToken, flattenResponseData } from './interceptors'

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_HOST,
  /* timeout: 10000, */
  headers: {
    'Content-Type': 'application/json',
  },
}) as AhhaAxios

/* Request Interceptors */
axiosInstance.interceptors.request.use(setToken)

/* Response Interceptors */
/* axiosInstance.interceptors.response.use(removeIdUnderScore) */
// TODO: refresh token
axiosInstance.interceptors.response.use(flattenResponseData)
