import { isString } from '@ahha/utils/@types/typeChecks'

export const isDev = process.env.NODE_ENV === 'development'

export function warn(message: string): void
export function warn(condition: boolean, message: string): void

export function warn(condition: boolean | string, message?: string) {
  if (process.env.NODE_ENV === 'production') {
    return
  }
  const cond = isString(condition) ? true : condition
  const msg = isString(condition) ? condition : message

  if (cond) {
    console.warn(msg)
  }
}
