import dayjs from 'dayjs'
import weekOfYear from 'dayjs/plugin/weekOfYear'

dayjs.extend(weekOfYear)

export const generateWeekFilter = (weeksAgo: number, startDay: number, startHour: number) => {
  const currentWeek = dayjs().week()
  const from = dayjs()
    .week(currentWeek - weeksAgo)
    .startOf('week')
    .add(startDay, 'day')
    .startOf('day')
    .add(startHour, 'hour')
    .set('minute', 0)
    .set('second', 0)
    .set('millisecond', 0)
    .toDate()
  const to = dayjs()
    .week(currentWeek - weeksAgo)
    .endOf('week')
    .add(startDay, 'day')
    .endOf('day')
    .add(startHour, 'hour')
    .set('minute', 59)
    .set('second', 59)
    .set('millisecond', 999)
    .toDate()
  return { from, to }
}

export const generateMonthFilter = (monthAgo: number, startHour: number) => {
  const currentMonth = dayjs().month()
  const from = dayjs().month(currentMonth - monthAgo)
    .startOf('month')
    .startOf('day')
    .add(startHour, 'hour')
    .set('minute', 0)
    .set('second', 0)
    .set('millisecond', 0)
    .toDate()
  const to = dayjs().month(currentMonth - monthAgo)
    .endOf('month')
    .endOf('day')
    .add(startHour, 'hour')
    .set('minute', 59)
    .set('second', 59)
    .set('millisecond', 999)
    .toDate()
  return { from, to }
}

export const generateSubtractFilter = (startValue: number, endValue: number, startHour: number) => {
  const from = dayjs()
    .subtract(startValue, 'day')
    .startOf('day')
    .add(startHour, 'hour')
    .toDate()
  const to = dayjs()
    .subtract(endValue, 'day')
    .endOf('day')
    .add(startHour, 'hour')
    .toDate()
  return { from, to }
}
