import * as querystring from 'querystring'
import BaseApi from '@/api/Base/BaseApi'

import {
  CreateCollectionPayload,
  CreateCollectionResponse,
  CreateDatabasePayload,
  CreateDatabaseResponse,
  EditCollectionPayload,
  EditDatabasePayload,
  EditDatabaseResponse,
  EditIndexesPayload,
  GetCollectionListPayload,
  GetCollectionListResponse,
  GetCollectionResponse,
  GetDatabaseListPayload,
  GetDatabaseListResponse,
  GetDatabaseResponse,
  GetDatabaseTreeResponse,
  GetDocumentListPayload,
  GetDocumentListResponse
} from '@/api/Database/types'

const COUNT_PER_PAGE = 40
const ROOT = '/data-source'

export default class DatabaseApi extends BaseApi {
  createDatabase(payload: CreateDatabasePayload) {
    return this.agent.post<CreateDatabaseResponse>(`${ROOT}/db/create`, payload)
  }

  getDatabaseList(payload: GetDatabaseListPayload) {
    const { keyword, page = 0, itemsPerPage = COUNT_PER_PAGE, sortKey, sortDir = 'asc' } = payload
    return this.agent.get<GetDatabaseListResponse>(`${ROOT}/db/list`, {
      params: {
        name: keyword,
        page,
        num_per_page: itemsPerPage,
        sort_key: sortKey,
        sort_dir: sortDir,
      },
    })
  }

  getDatabaseInfo(databaseId: string) {
    return this.agent.get<GetDatabaseResponse>(`${ROOT}/db/${databaseId}`)
  }

  editDatabase(databaseId: string, payload: EditDatabasePayload) {
    return this.agent.put<EditDatabaseResponse>(`${ROOT}/db/${databaseId}`, payload)
  }

  deleteDatabase(databaseId: string) {
    return this.agent.delete(`${ROOT}/db/${databaseId}`)
  }

  createCollection(databaseId: string, payload: CreateCollectionPayload) {
    return this.agent.post<CreateCollectionResponse>(`${ROOT}/${databaseId}/create-collection`, payload)
  }

  getCollectionList(payload: GetCollectionListPayload) {
    const { db_id: dbId, keyword, page = 0, itemsPerPage = COUNT_PER_PAGE, sortKey, sortDir = 'asc' } = payload
    return this.agent.get<GetCollectionListResponse>(`${ROOT}/${dbId}/collection-list`, {
      params: {
        name: keyword,
        page,
        num_per_page: itemsPerPage,
        sort_key: sortKey,
        sort_dir: sortDir,
      },
    })
  }

  getCollectionInfo(collectionId: string) {
    return this.agent.get<GetCollectionResponse>(`${ROOT}/collection/${collectionId}`)
  }

  // TODO: collection 수정 api
  editCollection(collectionId: string, payload: EditCollectionPayload) {
    return this.agent.put<any>(`${ROOT}/collection/${collectionId}`, payload)
  }

  // TODO: collection 삭제 api
  deleteCollection(collectionId: string) {
    return this.agent.delete<any>(`${ROOT}/collection/${collectionId}`)
  }

  getDatabaseTree() {
    return this.agent.get<GetDatabaseTreeResponse>(`${ROOT}/db-tree`)
  }

  getDocumentList(payload: GetDocumentListPayload) {
    const { collectionId, keyword, page = 0, itemsPerPage = COUNT_PER_PAGE, sortKey, sortDir = 'asc' } = payload
    return this.agent.get<GetDocumentListResponse>(`${ROOT}/data/${collectionId}`, {
      params: {
        name: keyword,
        page,
        num_per_page: itemsPerPage,
        sort_key: sortKey,
        sort_dir: sortDir,
      },
    })
  }

  // TODO: collection index 정보 get api

  // TODO: collection index 정보 수정 api
  editIndexes(collectionId: string, payload: EditIndexesPayload) {
    return this.agent.put(`${ROOT}/collection/${collectionId}/index`, payload)
  }

  getAggregateResult(collectionId: string, payload: any) {
    return this.agent.get(`${ROOT}/aggregate/${collectionId}?pipeline=${encodeURI(payload)}`)
  }
}
