import { useRef } from 'react'
import { UAParser } from 'ua-parser-js'

import { warn } from '@ahha/utils/log'
import { isKeyOf } from '@ahha/utils/@types/typeChecks'

import { DELTA_MODE, DELTA_MODE_PAGE_SCROLL_AMOUNT, SEPARATE_EVENT_TIME_SPAN } from '@ahha/components/Canvas/utils/hooks/useWheelEvent/const'
import { determinants } from '@ahha/components/Canvas/utils/hooks/useWheelEvent/utils'

const UA = new UAParser().getResult()

export const useWheelEvent = () => {
  const timeStamp = useRef(0)
  const initialDelta = useRef({ x: 0, y: 0 })

  const isTrackPadPinch = (e: WheelEvent) => !e.shiftKey && e.ctrlKey

  const adaptDeltaDirection = (e: WheelEvent) => {
    const { deltaX, deltaY } = e

    return { ...e, deltaX: -1 * deltaX, deltaY: -1 * deltaY }
  }

  const adaptDeltaMode = (e: WheelEvent, os: string, engine: string) => {
    const { deltaX, deltaY, deltaMode } = e

    if (!isKeyOf(os, DELTA_MODE_PAGE_SCROLL_AMOUNT)) {
      return e
    }
    const pixelAmountOnOS = DELTA_MODE_PAGE_SCROLL_AMOUNT[os]

    if (!isKeyOf(engine, pixelAmountOnOS)) {
      return e
    }
    if (deltaMode === DELTA_MODE.PAGE) {
      const modifier = pixelAmountOnOS[engine]

      return { ...e, deltaX: deltaX * modifier, deltaY: deltaY * modifier }
    }
    return e
  }

  const normalizeWheelEvent = (e: WheelEvent) => {
    const { os, engine } = UA
    const osName = os.name ?? ''
    const engineName = engine.name ?? ''

    return adaptDeltaDirection(adaptDeltaMode(e, osName, engineName))
  }

  const getUADependentDeterminant = (type: 'mouse' | 'trackPad') => (e: WheelEvent) => {
    const { os, engine } = UA
    const currentOS = os.name ?? ''

    if (!isKeyOf(currentOS, determinants)) {
      warn('Unsupported OS')
      return false
    }
    const eventDeterminant = determinants[currentOS]
    const browserEngine = engine.name ?? ''

    if (!isKeyOf(browserEngine, eventDeterminant)) {
      warn('Unsupported browser engine')
      return false
    }
    const normalizedEvt = normalizeWheelEvent(e)
    const dT = e.timeStamp - timeStamp.current

    if (dT > SEPARATE_EVENT_TIME_SPAN) {
      initialDelta.current = { x: normalizedEvt.deltaX, y: normalizedEvt.deltaY }
    }
    timeStamp.current = e.timeStamp

    /**
     * TODO:ksh: Shift 누른 상태에서도 마우스로 확대/축소 가능하도록
     * Mac:  Shift + 마우스 휠 -> deltaY가 deltaX로 반전됨
     *   - Blink, Safari: delta값도 float에서 int로 바뀜
     *   - Gecko: delta값이 바뀌지만 int 그대로 유지
     * - 2024.07.11
     * */
    if (e.shiftKey) {
      return false
    }
    if (type === 'mouse') {
      return eventDeterminant[browserEngine].mouse(normalizedEvt, initialDelta.current)
    }
    return eventDeterminant[browserEngine].trackPadDoubleSwipe(normalizedEvt, initialDelta.current)
  }

  return {
    isTrackPad: {
      pinch: isTrackPadPinch,
      doubleSwipe: getUADependentDeterminant('trackPad'),
    },
    isMouse: {
      wheel: getUADependentDeterminant('mouse'),
    },
    normalizeEvent: normalizeWheelEvent,
  }
}

/**
 * 각 wheel 이벤트 동작에서 [deltaX, deltaY]의 값.
 * 맨 처음부터 마우스 휠 / 트랙 패드 줌 / 트랙 패드 double tap & swipe
 *
 * 1. Windows
 * - Chrome [0, int or float(100/3 * (스크롤 당 줄 수))] / [0, float] / [int, int]
 *   * 100/3의 배수에 근접할 때로 판단.(소수점 4째 자리까지 잘라서)
 * - Whale, Edge [0, int(100 or 150의 배수)] / [0, float] / [int, int]
 * - Firefox [0, int(42의 배수)] / [0, float] / [float, float]
 * - Opera [0, int or float(100/3 * (스크롤 당 줄 수))] / [0, float] / [int, int]
 *
 * - Chrome, Firefox, Opera는 윈도우 마우스 설정(스크롤 당 줄 수)에 따라 마우스 휠 deltaY가 다르게 출력됨
 *
 * 2. MacOS
 * - Chrome, Whale, Edge, Safari [0, float] / [0, float] / [int, int]
 * - Firefox [0, int] / [0, float] / [int, int]
 *
 * 3. Ubuntu(Linux)
 */
