import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type ValidationChartDataType = {
  syncId: string | undefined,
  chartData: { score: number, time: number }[],
  thresholdValue: number,
  brushIndex: { startIndex: number, endIndex: number },
}

const INITIAL_DATA: ValidationChartDataType = {
  syncId: undefined,
  chartData: [],
  thresholdValue: 0,
  brushIndex: { startIndex: 0, endIndex: 0 },
}

export const validationChart = createSlice({
  name: 'validationChart',
  initialState: INITIAL_DATA,
  reducers: {
    setChartData(state, action: PayloadAction<{ chartData: { score: number, time: number }[], thresholdValue: number }>) {
      const { chartData, thresholdValue } = action.payload
      return { ...state, chartData, thresholdValue }
    },
    setSyncId(state, action: PayloadAction<string>) {
      return { ...state, syncId: action.payload }
    },
    setBrushIndex(state, action: PayloadAction<{ startIndex: number, endIndex: number }>) {
      return { ...state, brushIndex: action.payload }
    },
  },
})

export const {
  setChartData,
  setSyncId,
  setBrushIndex,
} = validationChart.actions

export default validationChart.reducer
