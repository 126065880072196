import { CommonListParams, DEFAULT_LIST_OPTIONS } from '@/api/react-query/queryKeys/common'

interface DatasetListParams extends CommonListParams {
  /* projectId?: string */
  type?: string
  filter?: string
  order?: string
}

interface DatasetFileParams {
  id: string
  page?: number
  sortKey?: string
  itemsPerPage?: number
  order?: string
}

export const datasetKeys = {
  all: ['dataset'],
  lists: () => [...datasetKeys.all, 'lists'/* , { projectId } */],
  list: (options?: DatasetListParams) => [...datasetKeys.lists(), { ...DEFAULT_LIST_OPTIONS, ...options }],
  detail: (datasetId: string) => [...datasetKeys.all, 'detail', datasetId],
  files: ({ id, ...params }: DatasetFileParams) => [...datasetKeys.detail(id), 'files', params],
} as const
