import { mapValues } from 'lodash'

import {
  createSlice,
  current,
  Draft,
  PayloadAction
} from '@reduxjs/toolkit'
import assert from 'assert'

import {
  AnnotationState,
  AnnotationSnapshot,
  ClassDefinition,
  AnnotationHistory,
  Tool,
  RawAnnotData,
  LabelMode
} from './annotation/types'
import {
  AnnotationClassType,
  GetAnnotationResponse,
  GetObjectsForFileResponse
} from '@/api/Annotation/types'
import { ToolType } from '@/pages/Lisa/Label/types'

import { getSettledFiles, transformAnnotClasses, transformAnnotFiles, transformAnnotObjects } from '@/stores/slices/Lisa/annotation/utils'

import { initializeClasses, loadAnnotation, loadClassification } from './annotation/thunks/initialization'
import { loadAnnotationFiles, isInternalAnnotFile, loadClassificationFiles } from './annotation/thunks/files'
import { loadAnnotationObjects } from './annotation/thunks/objects'
import { addClass, arrangeClasses, removeClass, updateClass, updateClassOrder } from './annotation/thunks/class'
import { saveFile, saveProject } from './annotation/thunks/saveProject'

import { createObjectReducers } from '@/stores/slices/Lisa/annotation/reducers/createObjects'
import { removeObjectReducers } from '@/stores/slices/Lisa/annotation/reducers/removeObjects'
import { updateObjectReducers } from '@/stores/slices/Lisa/annotation/reducers/updateObjects'
import { MAX_SNAPSHOT, PossibleTool, PossibleShape, initialState } from './annotation/const'

const _updateFileInfo = (state: Draft<AnnotationState>, fileId: string, snapshot: AnnotationSnapshot) => {
  const files = getSettledFiles(state)
  const fileIndex = files.findIndex((f) => f.id === fileId)

  if (fileIndex !== -1) {
    files[fileIndex].labelOk = snapshot.labelOk
    files[fileIndex].objectCount = snapshot.object.length
  }
}

export const _addSnapshot = (state: Draft<AnnotationState>, fileId: string, snapshot: AnnotationSnapshot) => {
  const { count, index } = state.annotation[fileId]
  assert(count >= index)
  if (count !== index) {
    state.annotation[fileId].snapshot.splice(0, count - index, snapshot)
  } else {
    state.annotation[fileId].snapshot.unshift(snapshot)
    state.annotation[fileId].snapshot.splice(MAX_SNAPSHOT, state.annotation[fileId].snapshot.length)
  }
  state.annotation[fileId].index += 1
  state.annotation[fileId].count = state.annotation[fileId].index
  _updateFileInfo(state, fileId, snapshot)
}

export const _getLastSnapshot = (state: Draft<AnnotationState>, fileId: string) => {
  const {
    count,
    index,
  } = state.annotation[fileId]
  assert(count >= index)
  return state.annotation[fileId]?.snapshot[count - index]
}

const _resetSnapshot = (state: Draft<AnnotationState>, fileId: string) => {
  const { count, index } = state.annotation[fileId]
  assert(count >= index)
  const last = _getLastSnapshot(state, fileId)
  state.annotation[fileId].index = 1
  state.annotation[fileId].snapshot = [last]
  state.annotation[fileId].count = state.annotation[fileId].snapshot.length
}

const _initAnnotation = (state: Draft<AnnotationState>, annotation: RawAnnotData) => {
  const { classes } = annotation
  const isClassVisible = (classId: string) => current(state.classes).find((c) => c.id === classId)?.isVisible ?? true

  state.classes = transformAnnotClasses(classes, isClassVisible)
  state.type = annotation.type
}

const _initClasses = (state: Draft<AnnotationState>, classes: (ClassDefinition | AnnotationClassType)[]) => {
  state.classes = classes.map((item) => {
    const target = current(state.classes).find((c) => c.id === (item as ClassDefinition).id)
    return {
      id: (item as ClassDefinition).id || (item as AnnotationClassType)._id,
      name: item.name,
      color: item.color,
      isVisible: target?.isVisible !== false,
      visibleConfiguredAt: new Date(),
    }
  })
}

const _initFiles = (
  state: Draft<AnnotationState>,
  files: (GetObjectsForFileResponse | null)[],
  start: number,
  end: number
) => {
  const annotation: { [annotId: string]: AnnotationHistory } = {}
  const nonNullRawFiles = files.filter((d): d is GetObjectsForFileResponse => !!d)
  const initFiles = Array.from({ length: files.length }, () => null)

  nonNullRawFiles.forEach((f) => {
    const { _id: fileId, annotationInfo } = f

    annotation[fileId] = {
      count: 0,
      index: 0,
      snapshot: [{
        labelOk: !!annotationInfo?.isSkipped,
        object: transformAnnotObjects(f),
      }],
    }
  })

  state.files = [
    ...initFiles.slice(0, start),
    ...nonNullRawFiles.map((f) => transformAnnotFiles(f)),
    ...initFiles.slice(end + 1),
  ]
  state.annotation = annotation
}

const _navigateToImage = (state: Draft<AnnotationState>, imageIndex: number) => {
  if (state.tool.isTracking) {
    const { id: nextId, labelOk: nextLabelOk } = getSettledFiles(state, imageIndex)
    const { id, labelOk } = getSettledFiles(state, state.currentIndex)
    const nextAnnotation = state.annotation[nextId]
    const currentAnnotation = state.annotation[id]
    if (!nextAnnotation.snapshot[0].object.length && !nextLabelOk) {
      _addSnapshot(state, nextId, currentAnnotation.snapshot[0])
    }
  }
  if (imageIndex === 0 || imageIndex === state.files.length - 1) {
    state.tool.isTracking = false
  }

  state.currentIndex = imageIndex
}

export const annotationSlice = createSlice({
  name: 'annotation',
  initialState,
  reducers: {
    setCurrentImageIndex: (state, action: PayloadAction<number>) => {
      state.currentIndex = action.payload
    },
    setClass: (state, action: PayloadAction<string>) => {
      state.tool.class = action.payload
    },
    setTool: (state, action: PayloadAction<Tool>) => {
      if (!PossibleTool.includes(action.payload)) {
        console.error(`${action.payload} is not available tool`)
        return
      }
      state.tool.tool = action.payload
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      if (PossibleShape.includes(action.payload)) {
        state.tool.shape = action.payload
      }
    },
    setLabelMode: (state, action: PayloadAction<LabelMode>) => {
      state.mode = action.payload
    },
    setBrush: (state, action: PayloadAction<number>) => {
      state.tool.brush = action.payload
      state.tool.tool = ToolType.BRUSH
    },
    setOpacity: (state, action: PayloadAction<number>) => {
      state.tool.opacity = Math.min(Math.max(action.payload, 0), 100)
    },
    setMagicWandThreshold: (state, action: PayloadAction<number>) => {
      state.tool.magicWandThreshold = action.payload
      state.tool.tool = ToolType.MAGICWAND
    },
    setTracking: (state, action: PayloadAction<boolean>) => {
      state.tool.isTracking = action.payload
    },
    setClassVisible: (state, action: PayloadAction<{ classId: string, isVisible: boolean }>) => {
      const { classId, isVisible } = action.payload
      const classIndex = state.classes.findIndex((c) => c.id === classId)
      if (classIndex === -1) {
        console.error(`Cannot find class ${classId}`)
        return
      }
      state.classes[classIndex].isVisible = isVisible
      state.classes[classIndex].visibleConfiguredAt = new Date()
    },
    setObjectVisible: (state, action: PayloadAction<{ objectId: string, isVisible: boolean }>) => {
      const { isVisible, objectId } = action.payload
      state.objectVisibility[objectId] = {
        isVisible,
        configuredAt: new Date(),
      }
    },
    setAllObjectVisible: (state, action: PayloadAction<{ fileIndex: number, isVisible: boolean }>) => {
      const { isVisible, fileIndex } = action.payload
      const fileId = state.files[fileIndex]?.id
      if (!fileId) {
        console.error(`Cannot access the file at index ${fileIndex}`)
        return
      }
      const now = new Date()
      const currentSnapshot = _getLastSnapshot(state, fileId)
      if (!currentSnapshot) {
        console.error(`Cannot get snapshot of file ${fileId}`)
        return
      }
      currentSnapshot.object.forEach((o) => {
        if (o.id) {
          state.objectVisibility[o.id] = {
            isVisible,
            configuredAt: now,
          }
        }
      })
    },
    ...createObjectReducers,
    ...updateObjectReducers,
    ...removeObjectReducers,

    /* resetForFile: (state, action: PayloadAction<number>) => {
      if (state.isInProgress) {
        console.error('Other requests is in progress')
        return
      }
      const fileId = state.files[action.payload].id
      state.annotation[fileId].index = 0
      if (state.annotation[fileId].snapshot[0].object) {
        _updateFileInfo(state, fileId, _getLastSnapshot(state, fileId))
        _resetSnapshot(state, fileId)
      }
    }, */
    /** `payload`로 아무 값도 전달하지 않을 경우(`undefined`) 현재 인덱스에 대해 작업 */
    undoForFile: (state, action: PayloadAction<number | undefined>) => {
      const targetIndex = action.payload ?? state.currentIndex

      if (state.isInProgress) {
        console.error('Other requests is in progress')
        return
      }
      const fileId = getSettledFiles(state, targetIndex).id
      const annotation = state.annotation[fileId]
      const snapshotCount = annotation.snapshot.length
      const snapshotIndex = annotation.count - annotation.index

      if (snapshotCount <= snapshotIndex + 1) {
        console.error('cannot undo')
        return
      }
      state.annotation[fileId].index -= 1
      _updateFileInfo(state, fileId, _getLastSnapshot(state, fileId))
    },
    /** `payload`로 아무 값도 전달하지 않을 경우(`undefined`) 현재 인덱스에 대해 작업 */
    redoForFile: (state, action: PayloadAction<number | undefined>) => {
      const targetIndex = action.payload ?? state.currentIndex

      if (state.isInProgress) {
        console.error('Other requests is in progress')
        return
      }
      const fileId = getSettledFiles(state, targetIndex).id
      const annotation = state.annotation[fileId]
      const snapshotIndex = annotation.count - annotation.index
      if (snapshotIndex <= 0) {
        console.error('cannot redo')
        return
      }
      state.annotation[fileId].index += 1
      _updateFileInfo(state, fileId, _getLastSnapshot(state, fileId))
    },
    setOkForFile: (state, action: PayloadAction<{ index: number, isOk: boolean }>) => {
      if (state.isInProgress) {
        console.error('Other requests is in progress')
        return
      }
      const { index, isOk } = action.payload
      const fileId = getSettledFiles(state, index).id
      const last = _getLastSnapshot(state, fileId)
      const snapshot = {
        labelOk: isOk,
        object: isOk ? [] : last.object,
      }
      _addSnapshot(state, fileId, snapshot)
    },
    resetCurrentAnnotation: (state) => initialState,
  },
  extraReducers: (builder) => {
    /* Annotation 초기화 */
    builder.addCase(loadAnnotation.pending, (state) => {
      state.isInProgress = true
    })
    builder.addCase(loadAnnotation.fulfilled, (state, action) => {
      state.isInProgress = false
      const { annotationId, annotation, files, start, end, datasetMeta } = action.payload
      const classes = current(state.classes).length > 0 ? state.classes : annotation.classes as RawAnnotData['classes']

      _initAnnotation(state, { ...annotation, classes })
      _initFiles(state, files, start, end)

      state.datasetMeta = { ...datasetMeta, id: datasetMeta._id }
      state.id = annotationId
      state.tool = { ...initialState.tool }
      state.tool.class = arrangeClasses(state.classes)[0]?.id || null
    })
    builder.addCase(loadAnnotation.rejected, (state) => {
      state.isInProgress = false
    })
    builder.addCase(initializeClasses.pending, (state) => {
      state.isInProgress = true
    })
    builder.addCase(initializeClasses.fulfilled, (state, action) => {
      state.isInProgress = false
    })
    builder.addCase(initializeClasses.rejected, (state) => {
      state.isInProgress = false
    })
    builder.addCase(loadAnnotationFiles.fulfilled, (state, action) => {
      const rawFiles = action.payload

      state.files = rawFiles.map((f) => (!f || isInternalAnnotFile(f) ? f : transformAnnotFiles(f)))
    })
    builder.addCase(loadAnnotationObjects.fulfilled, (state, action) => {
      const objectData = action.payload
      const { index } = action.meta.arg
      const { id, labelOk } = getSettledFiles(state, index)

      if (objectData !== undefined) {
        state.annotation[id] = {
          count: 0,
          index: 0,
          snapshot: [{
            labelOk,
            object: objectData,
          }],
        }
      }
      _navigateToImage(state, index)
    })

    /* Classification 초기화 */
    builder.addCase(loadClassification.pending, (state) => {
      state.isInProgress = true
    })
    builder.addCase(loadClassification.fulfilled, (state, action) => {
      const { annotation, annotationId, files } = action.payload
      const initialHistory = {} as { [annotId: string]: AnnotationHistory }

      state.id = annotationId
      state.classes = transformAnnotClasses(annotation.classes)
      state.files = files.map((f) => transformAnnotFiles(f))
      state.annotation = files.reduce((a, f) => {
        if (isInternalAnnotFile(f) || !f) {
          return a
        }
        const { _id, annotationInfo } = f

        return {
          ...a,
          [_id]: {
            count: 0,
            index: 0,
            snapshot: [{
              labelOk: !!annotationInfo?.isSkipped,
              object: transformAnnotObjects(f),
            }],
          },
        }
      }, initialHistory)
      state.isInProgress = false
    })
    builder.addCase(loadClassification.rejected, (state) => {
      state.isInProgress = false
    })
    builder.addCase(loadClassificationFiles.pending, (state) => {
      state.isInProgress = true
    })
    builder.addCase(loadClassificationFiles.fulfilled, (state, action) => {
      const rawFiles = action.payload
      const transformedFiles = rawFiles.map((f) => (!f || isInternalAnnotFile(f) ? f : transformAnnotFiles(f)))
      const prevHistory = state.annotation

      state.files = transformedFiles
      state.annotation = rawFiles.reduce((a, f) => {
        if (isInternalAnnotFile(f) || !f) {
          return a
        }
        const { _id, annotationInfo } = f

        return {
          ...a,
          [_id]: {
            count: 0,
            index: 0,
            snapshot: [{
              labelOk: !!annotationInfo?.isSkipped,
              object: transformAnnotObjects(f),
            }],
          },
        }
      }, prevHistory)
      state.isInProgress = false
    })
    builder.addCase(loadClassificationFiles.rejected, (state) => {
      state.isInProgress = false
    })

    builder.addCase(addClass.pending, (state) => {
      state.isInProgress = true
    })
    builder.addCase(addClass.fulfilled, (state, action) => {
      state.isInProgress = false
      const { addedClass } = action.payload

      /* Annotation에서 최초로 추가된 클래스일 때 Tool에 선택된 클래스로 지정 */
      if (!state.tool.class) {
        state.tool.class = addedClass._id
      }
      // NOTE: response from addClass API includes only the class created
      // _initAnnotation(state, annotation)
      _initClasses(state, [...state.classes, addedClass])
    })
    builder.addCase(addClass.rejected, (state) => {
      state.isInProgress = false
    })
    builder.addCase(removeClass.pending, (state) => {
      state.isInProgress = true
    })
    builder.addCase(removeClass.fulfilled, (state, action) => {
      state.isInProgress = false
      const { annotation, removedClassId } = action.payload

      const { classes, currentIndex } = state
      const defaultClass = classes[0]?.id
      const fileId = getSettledFiles(state, currentIndex).id
      const updatedCurrentFileSnapshot = state.annotation[fileId]

      /* Tool에 선택된 클래스가 삭제됐을 때 선택된 클래스 초기화 */
      if ((state.tool.class === removedClassId) && defaultClass) {
        state.tool.class = defaultClass
      } else if (classes.length < 1) {
        state.tool.class = null
      }

      /**
       * 현재 페이지의 snapshot만 업데이트된 상태로 두고 나머지는 모두 초기화.
       * 삭제된 클래스의 오브젝트가 있던 이미지는 이미지 이동 시 다시 로드되므로 제거된 상태로 둬도 무방.
       *  */
      state.annotation = { [fileId]: updatedCurrentFileSnapshot }
      _initAnnotation(state, annotation)
    })
    builder.addCase(removeClass.rejected, (state) => {
      state.isInProgress = false
    })
    builder.addCase(updateClass.pending, (state) => {
      state.isInProgress = true
    })
    builder.addCase(updateClass.fulfilled, (state, action) => {
      state.isInProgress = false
      const {
        classId,
        name,
        color,
      } = action.payload
      const index = state.classes.findIndex((i) => i.id === classId)
      if (index !== -1) {
        state.classes[index].name = name
        state.classes[index].color = color
      }
    })
    builder.addCase(updateClass.rejected, (state) => {
      state.isInProgress = false
    })
    builder.addCase(updateClassOrder.pending, (state) => {
      state.isInProgress = true
    })
    builder.addCase(updateClassOrder.rejected, (state) => {
      state.isInProgress = false
    })
    builder.addCase(updateClassOrder.fulfilled, (state, action) => {
      state.isInProgress = false
      // _initAnnotation(state, action.payload)
      _initClasses(state, action.payload)
    })
    builder.addCase(saveProject.rejected, (state) => {
      state.isInProgress = false
    })
    builder.addCase(saveProject.pending, (state) => {
      state.isInProgress = true
    })
    builder.addCase(saveProject.fulfilled, (state) => {
      state.isInProgress = false
      state.annotation = mapValues(state.annotation, (a) => ({
        index: 0,
        count: 0,
        snapshot: [a.snapshot[a.count - a.index]],
      }))
    })
    // ----- 테스트용 코드 ---
    builder.addCase(saveFile.rejected, (state) => {
      state.isInProgress = false
    })
    builder.addCase(saveFile.pending, (state) => {
      state.isInProgress = true
    })
    builder.addCase(saveFile.fulfilled, (state) => {
      state.isInProgress = false
      // FIXME: 이 로직은 어떻게 핸들링 해야 좋을 지 고민을 할 필요가 있음, tracking 때문에 일단 꺼둠
      state.annotation = mapValues(state.annotation, (a) => ({
        index: 0,
        count: 0,
        snapshot: [a.snapshot[a.count - a.index]],
      }))
    })
  },
})

export const {
  setCurrentImageIndex,
  setClass,
  setTool,
  setLabelMode,
  setOpacity,
  setMagicWandThreshold,
  setTracking,
  setBrush,
  setOkForFile,
  setClassVisible,
  setObjectVisible,
  setAllObjectVisible,
  addBox,
  addPolygon,
  addEllipse,
  updateBox,
  updatePolygon,
  updateEllipse,
  updateObjectClass,
  addTag,
  removeTag,
  updateTags,
  removeObject,
  redoForFile,
  undoForFile,
  /* resetForFile, */
  resetCurrentAnnotation,
} = annotationSlice.actions

export default annotationSlice.reducer
