import { Draft, PayloadAction } from '@reduxjs/toolkit'
import { v4 as uuidv4, validate as uuidValidate } from 'uuid'
import { get } from 'lodash'

import { _addSnapshot, _getLastSnapshot } from '@/stores/slices/Lisa/annotationSlice'
import { AnnotationObject, AnnotationState, BoxObject, EllipseObject, PolygonObject, TagObject } from '@/stores/slices/Lisa/annotation/types'

const _updateObject = (state: Draft<AnnotationState>, objectId: string, fileId: string, object: Omit<AnnotationObject, 't' | 'createdAt'>) => {
  if (state.isInProgress) {
    console.error('Other requests is in progress')
    return
  }
  let found = false
  const last = _getLastSnapshot(state, fileId)
  const snapshot = {
    labelOk: false,
    object: last.object.map((cur) => {
      if (cur.id === objectId) {
        found = true
        return { ...cur, ...object, isEdited: true }
      }
      return cur
    }),
  }
  if (!found) {
    console.warn(`fail to find object ${objectId} in file ${fileId}`)
    return
  }
  _addSnapshot(state, fileId, snapshot)
}

export const updateObjectReducers = {
  updateBox: {
    reducer: (
      state: Draft<AnnotationState>,
      action: PayloadAction<{ fileId: string, objectId: string, box: Omit<BoxObject, 'isFixedRatio' | 't' | 'createdAt'> }>
    ) => {
      const {
        fileId,
        box,
        objectId,
      } = action.payload
      _updateObject(state, objectId, fileId, box)
    },
    prepare: (
      fileId: string,
      objectId: string,
      top: number,
      left: number,
      height: number,
      width: number,
      rotation: number
    ) => ({
      payload: {
        fileId,
        objectId,
        box: {
          x: left,
          y: top,
          width,
          height,
          rotation,
          modifiedAt: new Date(),
        },
      },
    }),
  },
  updateEllipse: {
    reducer: (
      state: Draft<AnnotationState>,
      action: PayloadAction<{ fileId: string, objectId: string, ellipse: Omit<EllipseObject, 'isFixedRatio' | 't' | 'createdAt'> }>
    ) => {
      const {
        fileId,
        ellipse,
        objectId,
      } = action.payload
      _updateObject(state, objectId, fileId, ellipse)
    },
    prepare: (
      fileId: string,
      objectId: string,
      cx: number,
      cy: number,
      width: number,
      height: number,
      rotation: number
    ) => ({
      payload: {
        fileId,
        objectId,
        ellipse: {
          cx,
          cy,
          rx: width,
          ry: height,
          rotation,
          modifiedAt: new Date(),
        },
      },
    }),
  },
  updatePolygon: {
    reducer: (
      state: Draft<AnnotationState>,
      action: PayloadAction<{ fileId: string, objectId: string, polygon: Omit<PolygonObject, 'isPathEditable' | 't' | 'createdAt'> }>
    ) => {
      const {
        fileId,
        polygon,
        objectId,
      } = action.payload
      _updateObject(state, objectId, fileId, polygon)
    },
    prepare: (
      fileId: string,
      objectId: string,
      points: { x: number, y: number }[]
    ) => ({
      payload: {
        fileId,
        objectId,
        polygon: {
          points,
          modifiedAt: new Date(),
        },
      },
    }),
  },
  updateObjectClass: {
    reducer: (state: Draft<AnnotationState>, action: PayloadAction<{ fileId: string, objectId: string, classId: string }>) => {
      const {
        fileId,
        objectId,
        classId,
      } = action.payload
      const last = _getLastSnapshot(state, fileId)
      let found = false
      const snapshot = {
        labelOk: false,
        object: last.object.map((cur) => {
          if (cur.id === objectId) {
            found = true
            return {
              ...cur,
              label: classId,
              modifiedAt: new Date(),
            }
          }
          return cur
        }),
      }
      if (!found) {
        console.warn(`fail to find object ${objectId} in file ${fileId}`)
        return
      }
      _addSnapshot(state, fileId, snapshot)
    },
    prepare: (fileId: string, objectId: string, classId: string) => ({
      payload: {
        fileId,
        objectId,
        classId,
      },
    }),
  },
  updateTags: {
    reducer: (state: Draft<AnnotationState>, action: PayloadAction<{ fileIdList: string[], classId: string }>) => {
      const { fileIdList, classId } = action.payload
      fileIdList.forEach((fileId) => {
        const last = _getLastSnapshot(state, fileId)
        const now = new Date()
        const objectId = get(last?.object[0], 'id', uuidv4())
        const object: TagObject = {
          id: objectId,
          label: classId,
          t: 'tag',
          createdAt: get(last?.object, '[0].createdAt', now),
          modifiedAt: now,
          isEdited: !uuidValidate(objectId),
        }
        const newObjects = [object]
        const snapshot = {
          labelOk: false,
          object: newObjects,
        }
        _addSnapshot(state, fileId, snapshot)
      })
    },
    prepare: (fileIdList: string[], classId: string) => ({
      payload: {
        fileIdList,
        classId,
      },
    }),
  },
}
