export const annotationKeys = {
  all: ['annotation'],
  overview: (annotId: string) => [...annotationKeys.all, 'overview', annotId],
  files: (annotId: string) => [...annotationKeys.all, 'files', annotId],
  fileSegment: (annotId: string, fileId: string, start: number, end: number) => [
    ...annotationKeys.files(annotId),
    fileId,
    { start, end },
  ],
  file: (annotId: string, fileId: string) => [...annotationKeys.files(annotId), fileId],
  objects: (annotId: string, fileId: string) => [...annotationKeys.file(annotId, fileId), 'objects'],
  tiffImage: (src: string) => [...annotationKeys.all, 'tiffImage', src],
  history: (annotationId: string, datasetFileId: string) => [...annotationKeys.all, 'history', annotationId, datasetFileId],
}
