import { RefObject, useEffect, useRef } from 'react'

interface WheelNavigationOptions {
  ref: RefObject<HTMLElement>
  onWheelUp?: (e: HTMLElement, dY: number) => void
  onWheelDown?: (e: HTMLElement, dy: number) => void
  enabled: boolean
}

/** 사용자가 직접 스크롤을 드래그하는 대신 마우스 휠을 이용해 이동할 수 있도록 해주는 훅 */
export const useWheelNavigation = ({ ref, onWheelUp, onWheelDown, enabled }: WheelNavigationOptions) => {
  const currentDy = useRef(0)
  const accumulatedDy = useRef(0)

  const handleWheel = ({ deltaY }: WheelEvent) => {
    const STEP = 10
    const THRESHOLD = 20
    const currentDeltaY = Math.sign(deltaY) * STEP

    if (Math.sign(deltaY * currentDy.current) < 0) {
      currentDy.current = currentDeltaY
    } else {
      currentDy.current += currentDeltaY
    }
    const dY = currentDy.current / THRESHOLD

    if (!ref.current) {
      return
    }

    accumulatedDy.current += dY
    if (dY < -1) {
      onWheelUp?.(ref.current, accumulatedDy.current)
      currentDy.current = 0
    } else if (dY > 1) {
      onWheelDown?.(ref.current, accumulatedDy.current)
      currentDy.current = 0
    }
  }

  useEffect(() => {
    if (enabled && ref.current) {
      ref.current.addEventListener('wheel', handleWheel)

      return () => {
        /* if (!ref.current) {
          throw new Error('Event handler of the ref element cannot cleaned-up. ')
        } */
        ref.current?.removeEventListener('wheel', handleWheel)
      }
    }
    return () => null
  }, [enabled])
}
