function makeIdPath(base: string) {
  return (id: string): string => {
    if (id) {
      return `${base}/${id}`
    }
    return `${base}/:id`
  }
}

/**
 * 특정 path에 둘 이상의 URL 파라미터를 추가할 때 사용하는 함수
 * 타입 파라미터 `P`에 `[type: string, id: string]`처럼 튜플 타입을 지정하면
 * `siteUrl`을 사용하는 컴포넌트에서 전달해야할 파라미터의 개수와 타입을 알 수 있으므로 설정하는 것을 추천.
 * @param base
 */
function makeMultipleParams<P extends string[]>(base: string) {
  return (...params: P) => {
    if (params.length > 0) {
      return `${base}/${params.join('/')}`
    }
    return `${base}/:id`
  }
}

function makeProjectIdPath(base: string, path: string) {
  return (id: string): string => {
    if (id) {
      return `${base}/${id}/${path}`
    }
    return `${base}/:id`
  }
}

const dashboardRoot = '/main/dashboard'
const datasetRoot = '/main/lisa/dataset'
const modelRoot = '/main/lisa/model'
const labelRoot = '/main/lisa/label'
const storageRoot = '/main/storage'
const databaseRoot = '/main/database'
const taskRoot = '/main/task'

const loginRoot = '/login'
const workspaceRoot = '/main/workspace'
const projectRoot = '/main/project'
const labelingRoot = '/main/labeling'
const resultRoot = '/main/result'

export const SiteUrls = {
  Landing: '/',
  Main: {
    _: '/main',
    Dashboard: {
      _: dashboardRoot,
      Edit: `${dashboardRoot}/edit`,
      WidgetConfig: `${dashboardRoot}/widgetConfig`,
    },
    Lisa: {
      Dataset: {
        _: `${datasetRoot}`,
        List: (page: number) => `${datasetRoot}?page=${page}`,
        Create: `${datasetRoot}/create`,
        Detail: makeMultipleParams<[type: string, id: string]>(datasetRoot),
      },
      Validation: '/main/lisa/validation',
      Live: {
        dashboard: '/main/lisa/live',
        result: '/main/lisa/live/result',
      },
      Model: {
        _: modelRoot,
        List: (page: number) => `${modelRoot}?page=${page}`,
        Create: `${modelRoot}/create`,
        Detail: makeIdPath(modelRoot),
      },
      Label: {
        _: labelRoot,
        Create: `${labelRoot}/create`,
        Detail: makeIdPath(labelRoot),
        DetailClassification: makeIdPath(`${labelRoot}/classification`),
        DetailObjDet: makeIdPath(`${labelRoot}/objdet`),
        Result: makeMultipleParams<[modelId: string, evaluationId: string]>(`${labelRoot}/result`),
      },
      Task: {
        Management: `${taskRoot}/management`,
      },
    },
    // FIXME: 사용되는곳 정리 후 삭제
    Storage: {
      _: storageRoot,
      folder: makeIdPath(`${storageRoot}/folder`),
    },
    Database: {
      _: databaseRoot,
      List: (page: number) => `${databaseRoot}?page=${page}`,
      Collections: (id: string, page?: number) => (page ? `${databaseRoot}/${id}?page=${page}` : `${databaseRoot}/${id}`),
      CollectionsCreate: makeIdPath(`${databaseRoot}/create`),
      CollectionsDetail: makeMultipleParams<[databaseId: string, collectionId: string]>(databaseRoot),
    },
    Pipeline: '/main/pipeline',
    Workspace: {
      Main: workspaceRoot,
      Join: `${workspaceRoot}/join`,
      Create: `${workspaceRoot}/create`,
      Invited: makeMultipleParams<[invitationCode: string]>(workspaceRoot),
      Settings: `${workspaceRoot}/settings`,
      NotificationSettings: `${workspaceRoot}/notification/settings`,
      Projects: `${workspaceRoot}/projects`,
      Members: `${workspaceRoot}/members`,
      Pricing: `${workspaceRoot}/pricing`,
      Storage: {
        _: `${workspaceRoot}/storage`,
        folder: makeIdPath(`${workspaceRoot}/storage`),
      },
      NoWorkspace: `${workspaceRoot}/none`,
    },
    // FIXME: 구조 잡은 후에 기존에 있던 Project 경로 제거 후 이름 변경
    Project: {
      Main: makeIdPath(projectRoot),
      Settings: makeProjectIdPath(projectRoot, 'settings'),
      NotificationSettings: makeProjectIdPath(projectRoot, 'notification/settings'),
      Pipeline: makeProjectIdPath(projectRoot, 'pipeline'),
      ModelManagement: makeProjectIdPath(projectRoot, 'model/management'),
      Dashboard: makeProjectIdPath(projectRoot, 'dashboard'),
    },
    // // FIXME: 걷어내야함
    // Project: {
    //   _: projectRoot,
    //   Detail: makeIdPath(projectRoot),
    // },
    Labeling: {
      Anomaly: makeIdPath(`${labelingRoot}/anomaly`),
      Classification: makeIdPath(`${labelingRoot}/classification`),
      ObjDet: makeIdPath(`${labelingRoot}/objdet`),
    },
    // ValidationResult: makeMultipleParams<[projectId: string, nodeId: string]>(resultRoot),
    Result: {
      Validation: makeMultipleParams<[projectId: string, nodeId: string]>(`${resultRoot}/validation`),
      Inference: makeMultipleParams<[projectId: string, nodeId: string]>(`${resultRoot}/inference`),
    },
    User: {
      Settings: '/main/user/settings',
    },
  },
  Login: {
    _: loginRoot,
    Main: `${loginRoot}/main`,
    SignUp: `${loginRoot}/signup`,
    RecoverPassword: `${loginRoot}/recover`,
    ExpiredInvitation: `${loginRoot}/expired`,
  },
}
