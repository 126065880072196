export const NOTISTATES = {
  SUCCESS: 'success',
  ERROR: 'error',
} as const

export const TOAST_STATES = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  ERROR: 'error',
}

export const ICON_TYPES = {
  INFO: 'info',
}

export type NotiValueType = (typeof NOTISTATES)[keyof typeof NOTISTATES]
export type ToastValueType = (typeof TOAST_STATES)[keyof typeof TOAST_STATES]
export type IconValueType = (typeof ICON_TYPES)[keyof typeof ICON_TYPES]

export type NotificationType = {
  id: number | string,
  notiType: NotiValueType,
  title: string,
  message: string,
  autoHide: boolean,
  // 이미지, href 있을 때 어떻게 핸들링 하는 게 좋을지 조금 더 고민이 필요
  imageSrc?: string,
  url?: string,
}
export type ToastType = {
  id: number | string,
  toastType: ToastValueType,
  title: string,
  message: string,
  autoHide: boolean,
  variant: 'singleLine' | 'multiLine',
  iconType?: IconValueType,
}

export type NotificationData = Omit<NotificationType, 'id'>
export type ToastData = Omit<ToastType, 'id'>
export type NotiCombinedData = NotificationData | ToastData

export type NotificationContext = {
  notifications: (NotificationType | ToastType)[],
  addNotification: (data: NotiCombinedData) => void,
  removeNotification: (id: number | string) => void,
}
