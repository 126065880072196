import { find } from 'lodash'

import { retry } from '@ahha/utils/network/retry'
import { API } from '@/api'
import { AppListenerMiddleware } from '@/stores'
import { addComment, removeComment, repositionComment, toggleResolveComment, updateCommentData } from '@/stores/slices/Labeling/labelingSlice'
import { transformComment } from '@/stores/slices/Labeling/utils'
import { loadLabelingItems } from '@/stores/slices/Labeling/thunks/files'
import { loadObjects } from '@/stores/slices/Labeling/thunks/objects'
import { socket } from '@/socket'

export const commentLoadListener = (listener: AppListenerMiddleware) => listener.startListening({
  predicate: (a) => a.type === loadObjects.fulfilled.type || a.type === loadLabelingItems.fulfilled.type,
  effect: async (action, api) => {
    const { currentIndex, files } = api.getState().labeling
    if (action.type === loadLabelingItems.fulfilled.type) {
      api.dispatch(loadObjects({ index: currentIndex }))
    }
    // api.unsubscribe()
    //
    // const objects = files[currentIndex]?.labelingObjects
    //
    // api.dispatch(loadComments(transformComment.toClient(objects)))
    //
    // await api.delay(100)
    // api.subscribe()
  },
})

export const commentAddListener = (listener: AppListenerMiddleware) => listener.startListening({
  actionCreator: addComment,
  effect: async (action, api) => {
    const { user } = api.getOriginalState().user
    const { labelingNodeId, currentIndex, files, comments } = api.getOriginalState().labeling
    const current = find(comments[files[currentIndex || 0]?._id || ''], (c) => c.id === action.payload.id)
    const fileId = files[currentIndex]?._id

    if (!fileId || !current) {
      return
    }
    if (!current.serverId) {
      const res = await API().LabelingReview.createComment({
        fileId,
        metadata: {},
        region: {
          x: current.x,
          y: current.y,
          width: current.width,
          height: current.height,
          anchor: current.anchor,
        },
        comment: action.payload.comment,
      })
      const { _id, comments } = res

      const updatedComment = {
        fileId,
        commentId: action.payload.id,
        data: { comments: transformComment.subComment(comments), serverId: _id },
      }
      socket.emitCreateReviewObject({
        nodeId: labelingNodeId,
        userId: user._id,
        userName: user.name,
        itemId: fileId,
        addedComment: res,
      } as any)
      api.dispatch(updateCommentData(updatedComment))
    } else {
      const res = await API().LabelingReview.appendComment({
        id: current.serverId,
        comment: action.payload.comment,
      })
      const { comments } = res

      const updatedComment = {
        fileId,
        commentId: action.payload.id,
        data: { comments: transformComment.subComment(comments) },
      }
      socket.emitAddComment({
        nodeId: labelingNodeId,
        userId: user._id,
        userName: user.name,
        updatedComment: { ...updatedComment, commentId: res._id },
      } as any)
      api.dispatch(updateCommentData(updatedComment))
    }
  },
})

export const commentDeleteListener = (listener: AppListenerMiddleware) => listener.startListening({
  actionCreator: removeComment,
  effect: async (action, api) => {
    const { id, commentId } = action.payload
    const { user } = api.getOriginalState().user
    const { labelingNodeId, comments: commentList, currentIndex, files } = api.getOriginalState().labeling
    const current = find(commentList[files[currentIndex || 0]?._id || ''], (c) => c.id === id)
    const { serverId, comments } = current ?? {}
    const willBeEmpty = (comments?.length ?? 0) <= 1

    if (!serverId) {
      return
    }
    const res = await API().LabelingReview.removeComment(serverId, commentId)
    socket.emitDeleteComment({
      nodeId: labelingNodeId,
      userId: user._id,
      userName: user.name,
      itemId: res.labelingItemId,
      serverId,
      commentId,
      isDestroyed: res.comments?.length === 0,
    } as any)
    // if (willBeEmpty) {
    //   API().Labeling.deleteObject(serverId)
    // } else {
    //   API().LabelingReview.removeComment(serverId, commentId)
    // }
  },
})

// export const commentAnchorListener = (listener: AppListenerMiddleware) => listener.startListening({
//   actionCreator: removeObject,
//   effect: async (action, api) => {
//     const { objectId } = action.payload
//     const { comments: commentList, currentIndex, files } = api.getOriginalState().labeling
//     const { serverId } = find(commentList[files[currentIndex || 0]?._id || ''], (c) => c.anchor === objectId) ?? {}
//
//     if (!serverId) {
//       return
//     }
//     retry(() => API().Labeling.deleteObject(serverId))
//   },
// })

export const commentResolveListener = (listener: AppListenerMiddleware) => listener.startListening({
  actionCreator: toggleResolveComment,
  effect: async (action, api) => {
    const { id } = action.payload
    const { user } = api.getOriginalState().user
    const { labelingNodeId, comments: commentList, currentIndex, files } = api.getOriginalState().labeling
    const { serverId } = find(commentList[files[currentIndex || 0]?._id || ''], (c) => c.id === id) ?? {}

    if (!serverId) {
      return
    }
    const res = await retry(() => API().LabelingReview.toggleResolveComment(serverId))
    socket.emitResolveComment({
      nodeId: labelingNodeId,
      userId: user._id,
      userName: user.name,
      itemId: res.labelingItemId,
      commentId: res._id,
      isResolved: Boolean(res.isConfirm),
    })
  },
})

export const commentPositionListener = (listener: AppListenerMiddleware) => listener.startListening({
  actionCreator: repositionComment,
  effect: async (action, api) => {
    const { id, x, y, width, height } = action.payload
    const { comments: commentList, currentIndex, files } = api.getOriginalState().labeling
    const { serverId } = find(commentList[files[currentIndex || 0]?._id || ''], (c) => c.id === id) ?? {}

    if (!serverId) {
      return
    }
    const a = 0
    /* retry(() => API().LabelingNode) */
  },
})
