import { ListenerMiddlewareInstance, createListenerMiddleware } from '@reduxjs/toolkit'
import { commentAddListener, commentDeleteListener, commentLoadListener, commentPositionListener, commentResolveListener } from '@/stores/slices/Labeling/listeners/comment'
import { AppDispatch, RootState } from '@/stores/types'
import { fileStatusListener } from '@/stores/slices/Labeling/listeners/file'
import { nodeInvalidateListener } from '@/stores/slices/Pipeline/listeners/invalidate'

export const listenerMiddleware = createListenerMiddleware()

const instance = listenerMiddleware as ListenerMiddlewareInstance<RootState, AppDispatch>

/** Labeling */
fileStatusListener(instance)

/** Labeling - Review */
commentLoadListener(instance)
commentAddListener(instance)
commentDeleteListener(instance)
// commentAnchorListener(instance)
commentResolveListener(instance)
commentPositionListener(instance)

/** Pipeline */
nodeInvalidateListener(instance)
