/* Annotation 응답 */
import { ValueOf } from '@ahha/utils/@types/typeUtils'
import { Paginated } from '@/api/Base/types'
import { FileMeta } from '@/api/DataStorage/types'
import { AnnotationLabelingType } from '@/pages/Lisa/Label/const'

export interface AnnotationResponse {
  _id: string
  datasetId: string
  type: ValueOf<typeof AnnotationLabelingType>
  isCompleted: boolean
  classes: string[]
  numOfDatasetFiles: number
  numOfLabelObjects: number
  numOfLabeledFiles: number
  numOfSkippedFiles: number
  owner: string
  createdAt: string
  updatedAt: string
}

export interface AnnotationInfo {
  isSkipped: boolean,
  labeledAt: string,
  annotationObjects: {
    createdAt: string,
    datasetFileId: string,
    isFixedRatio: string,
    isPathEditable: string,
    label: any,
    metadata: any,
    region: any,
    zOrder: number,
    _id: string
    type: string,
  }[],
}

export type AnnotationFile = {
  accessibleUrl: string,
  annotationInfo: AnnotationInfo | null,
  createdAt: string,
  datasetId: string,
  fileMeta: FileMeta,
  name: string,
  numOfLabelObjects: number,
  previewAccessibleUrl: string,
  _id: string,
}

// TODO: move to common as FilesPayload?
export interface GetAnnotationFilesPayload {
  annotationId: string;
  page?: number;
  itemsPerPage?: number;
  sortKey?: string;
  sortDir?: string;
}

export type GetAnnotationFilesResponse = Paginated<AnnotationFile[]>

export type GetObjectsForFileResponse = AnnotationFile

export type AnnotationClassType = {
  annotationId: string,
  color: string,
  createdAt: string,
  name: string,
  order: number,
  updatedAt: string,
  _id: string,
}

export interface CreateAnnotationPayload {
  datasetId: string;
  type: string;
}

export type CreateAnnotationResponse = {
  createdAt: string,
  datasetId: string,
  isCompleted: boolean,
  owner: string,
  status: number,
  type: string,
  _id: string,
  numOfDatasetFiles: number,
}

export type GetAnnotationResponse = Omit<CreateAnnotationResponse, 'status'> & {
  classes: AnnotationClassType[],
  numOfLabeledFiles: number,
  numOfLabelObjects: number,
}

export interface AddClassPayload {
  annotationId: string;
  color: string;
  name: string;
}

export interface UpdateClassPayload {
  annotationClassId: string;
  color?: string;
  name?: string;
  order?: number;
}

export interface UpdateClassOrderPayload {
  annotationId: string,
  classIds: string[],
}

export const ObjectToolType = {
  RECTANGLE: 'RECTANGLE',
  ELLIPSE: 'ELLIPSE',
  POLYGON: 'POLYGON',
  TAG: 'TAG',
} as const

export interface CreateObjectPayload {
  annotationClassId: string;
  annotationId: string;
  datasetFileId: string;
  isFixedRatio?: boolean;
  isPathEditable?: boolean;
  // TODO: fix type (metadata & region = any json object)
  metadata?: any;
  region?: any;
  type: keyof typeof ObjectToolType;
  zOrder: number;
}

export interface UpdateObjectPayload extends CreateObjectPayload {
  annotationObjectId: string;
}

export interface MarkObjectAsOkPayload {
  annotationId: string;
  datasetFileId: string;
}

export interface SaveObjectsPayload {
  annotationId: string;
  annotationObjects: {
    annotationClassId: string,
    annotationObjectId?: string,
    isFixedRatio: boolean,
    isPathEditable: boolean,
    metadata: any,
    region: any,
    type: keyof typeof ObjectToolType,
    zOrder: number,
  }[];
  datasetFileId: string;
}

// {
//   "_id": "667290099080b8305a412a0f",
//   "annotationFileId": {
//   "annotationId": "667270309080b8305a41225f",
//     "datasetFileId": "6672702d9080b8305a41225b"
// },
//   "action": "DELETE",
//   "modifiedAt": "2024-06-19T08:00:09+0000",
//   "modifiedBy": "65ff3be9f2c692316812993f"
// }
export interface GetHistoryResponse {
  _id: string;
  annotationFileId: {
    annotationId: string;
    datasetFileId: string;
  };
  action: string // TODO: 'DELETE' | 'CREATE' | 'UPDATE'
  modifiedAt: string,
  modifiedBy: string,
}
