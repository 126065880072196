import Konva from 'konva'
import { RefObject, useEffect } from 'react'

import { KonvaEventObject } from 'konva/lib/Node'
import { getUnscaledPointerPosition } from '@ahha/components/Canvas/utils/konva'
import { useStage } from '@ahha/components/Canvas/utils/Context/Stage/useStage'

interface MouseUpOutOfCanvasParams {
  ref: RefObject<Konva.Shape | Konva.Group>
  enabled?: boolean
  deps?: unknown[]
  onEscaped: (e: MouseEvent) => void
}

const noop = () => {}

export const useMouseUpOutOfCanvas = ({
  enabled = true,
  ref,
  deps = [],
  onEscaped,
}: MouseUpOutOfCanvasParams) => {
  const [stage, utils] = useStage((s) => s.node)

  const isOnCanvas = (e: KonvaEventObject<MouseEvent>, stage: Konva.Stage) => {
    const { gutter } = utils.getAttributes()
    const scale = stage.scaleX()
    const [x, y] = getUnscaledPointerPosition(e)

    const relativeX = x - (gutter.x + stage.x() / scale)
    const relativeY = y - (gutter.y + stage.y() / scale)

    const maxX = stage.width() - 2 * gutter.x
    const maxY = stage.height() - 2 * gutter.y

    const isXBounded = relativeX >= 0 && relativeX <= maxX
    const isYBounded = relativeY >= 0 && relativeY <= maxY

    return isXBounded && isYBounded
  }

  useEffect(() => {
    const canvas = ref.current?.getLayer()?.getCanvas()._canvas

    if (!enabled || !stage) {
      return noop
    }
    /* Stage 내부이지만 이미지 외부 영역(gutter 영역)에서 mouse up */
    const handleMouseUpOnStage = (e: KonvaEventObject<MouseEvent>) => {
      if (!isOnCanvas(e, stage)) {
        onEscaped(e.evt)
      }
    }

    /** Stage 외부에서 mouse up */
    const handleMouseUp = (e: MouseEvent) => {
      if (!!canvas && e.target !== canvas) {
        onEscaped(e)
      }
    }

    stage.on('mouseup', handleMouseUpOnStage)
    window.addEventListener('mouseup', handleMouseUp)

    return () => {
      stage.off('mouseup', handleMouseUpOnStage)
      window.removeEventListener('mouseup', handleMouseUp)
    }
  }, [enabled, onEscaped, ...deps])
}

export default useMouseUpOutOfCanvas
