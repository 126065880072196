export type RegionVariant = 'ROI' | 'masking'

export type RoiType = 'rectangle' | 'ellipse'

export type Point2D = [number, number]

interface CustomProperties {
  stroke?: string;
  dash?: number[];
}

interface Region extends CustomProperties {
  id: string;
  variant: RegionVariant
  fill?: string
}

interface RegionScale {
  scale: number
}

export interface EllipsePayloadInfo {
  x: number;
  y: number;
  width: number;
  height: number;
  rotation: number;
}

/* export interface RoiRegion extends Region {
  type: RoiType;
  x: number;
  y: number;
  width: number;
  height: number;
  stroke: string;
  rotation: number;
} */

export interface EllipseRoiRegion extends Region {
  type: 'ellipse';
  cx: number;
  cy: number;
  rx: number;
  ry: number;
  stroke: string;
  rotation: number;
}

export interface RectangleRoiRegion extends Region {
  type: 'rectangle';
  x: number;
  y: number;
  width: number;
  height: number;
  stroke: string;
  rotation: number;
}

export type RoiRegion = EllipseRoiRegion | RectangleRoiRegion

export interface MaskingRegion extends Region {
  fill: string;
  points: Point2D[];
  isClosed: boolean;
}

export interface RoiState {
  imageSize: {
    naturalWidth: number;
    editorWidth: number;
  };
  annotId: string | null;
  currentRegionId: {
    ROI: string | null;
    masking: string;
  };
  regions: (RoiRegion | MaskingRegion)[];
  isInitialized: boolean;
  isMutated: boolean;
}

export interface RoiInitialInfo {
  annotId?: string | null;
  imageSize: {
    naturalWidth?: number;
    editorWidth: number;
  };
}

/** `Layer`(id)의 렌더링 순서. 가장 나중에 오는 레이어가 캔버스의 최상단에 그려짐. */
export type RearrangeBasis = readonly ['ROI', 'masking'] | readonly ['masking', 'ROI']

export type RoiInitializer = RegionScale & {
  type: RoiType;
  id: string;
  /** Rectangle일 때는 `width`, Ellipse일 때는 `rx * 2` */
  width: number;
  /** Rectangle일 때는 `height`, Ellipse일 때는 `ry * 2` */
  height: number;
  rotation: number;
}

export type NewRoiRegionPayload = Omit<RoiInitializer, 'id'>

export interface RegionPayload {
  id: string;
  data: Partial<RoiRegion | MaskingRegion>;
  rearrange?: RearrangeBasis;
}

export interface RegionIdPayload {
  type: RegionVariant;
  id?: string;
}

export type RegionCustomize = (r: RoiRegion | MaskingRegion) => CustomProperties

export interface RegionSelectorPayload {
  viewWidth?: number;
  regionCustomize?: RegionCustomize;
}

/* API */
export interface ServerRectangleRoi {
  type: 'rectangle';
  data: {
    x: number;
    y: number;
    width: number;
    height: number;
    rotation: number;
  }
}

export interface EllipseInfo {
  cx: number;
  cy: number;
  rx: number;
  ry: number;
  rotation: number;
}

export interface ServerEllipseRoi {
  type: 'ellipse';
  data: EllipseInfo;
}

export type ServerMasking = Array<Point2D>[]

export interface RoiThunkConfig {
  state: { annotationRoi: RoiState }
}

export interface TransformedRoiResponse {
  roi: RoiRegion[];
  mask: MaskingRegion[];
}

export const isRectRoiRegion = (r: Record<string, unknown>): r is Partial<RectangleRoiRegion> => r.type === 'rectangle'
  || ('x' in r && 'y' in r)
  || ('width' in r && 'height' in r)

export const isEllipseRoiRegion = (r: Record<string, unknown>): r is Partial<EllipseRoiRegion> => r.type === 'ellipse'
  || ('cx' in r && 'cy' in r)
  || ('rx' in r && 'ry' in r)

export const isMaskingRegion = (r: Record<string, unknown>): r is Partial<MaskingRegion> => r.variant === 'masking'
  || 'points' in r
  || 'isClosed' in r
