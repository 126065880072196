import BaseApi from '@/api/Base/BaseApi'
import { InviteMembersPayload, SimpleInvitationInfo, InvitationInfo, RemoveMemberPayload } from '@/api/Invitation/types'
import { Workspace } from '@/api/Workspace/types'

export default class InvitationApi extends BaseApi {
  getInvitationInfo(invitationCode: string) {
    return this.agent.get<SimpleInvitationInfo>(`/invitation/${invitationCode}/info`)
  }

  acceptInvitation(invitationCode: string) {
    return this.agent.post<Workspace>('/invitation/workspace/accept', { invitationCode })
  }

  rescindInvitation(invitationId: string) {
    return this.agent.delete<InvitationInfo>(`/invitation/${invitationId}/workspace/rescind`)
  }

  inviteUsersToWorkspace(payload: InviteMembersPayload) {
    return this.agent.post('/invitation/workspace/members', payload)
  }

  resendInvitation(invitationId: string) {
    return this.agent.post(`/invitation/${invitationId}/workspace/resend`)
  }

  getInvitedUsersToWorkspace(workspaceId: string) {
    return this.agent.get<InvitationInfo[]>(`/invitation/workspace/${workspaceId}/list`)
  }

  removeMember(payload: RemoveMemberPayload) {
    const { workspaceId, userId } = payload
    return this.agent.delete(`/invitation/workspace/${workspaceId}/member/${userId}`)
  }
}
