import { createAsyncThunk } from '@reduxjs/toolkit'
import { validate as uuidValidate } from 'uuid'
import { has, set } from 'lodash'

import { API } from '@/api'

import { getLabelingDefaultCondition } from '@/stores/slices/Labeling/utils'
import { LabelingObjectData, ThunkConfig } from '@/stores/slices/Labeling/types'
import { calcOriginalPointsForDB } from '@/pages/Labeling/utils/canvasUtils'
import {
  GetUserShortcutsResponse,
  LabelingNodeItem,
  ObjectToolType,
  UserShortcut
} from '@/api/LabelingNode/types'
import { invalidateQueryData } from '@/stores/slices/Labeling/queryData'
import { LABEL_FILE_STATUS } from '@/api/LabelingNode/const'

/** 파일 단위로 저장 */
export const saveFile = createAsyncThunk<boolean, number | undefined, ThunkConfig>(
  'labeling/save-file',
  async (itemIndex, thunkAPI) => {
    const { labelingNodeId, files, annotation, currentIndex, userIsReviewer, initialNodeStatus } = thunkAPI.getState().labeling
    const index = itemIndex ?? currentIndex
    const item = files[index]
    const itemId = item?._id || ''
    const snapshot = annotation[itemId]
    // const snapshotIndex = history.count - history.index
    //
    // if (snapshotIndex < 0 || snapshotIndex > history.snapshot.length) {
    //   console.warn('Unexpected snapshot index. Cannot save file.')
    //   return false
    // }

    // TODO: 소켓 붙는 시점에 파일 단위 저장할 필요가 없어지므로 임시로 에러만 나지 않도록 검수 오브젝트는 제외하고 저장하도록 수정해둠
    const filteredObjects = snapshot.object.filter((o) => has(o, 't') && !o.isDeleted)
    const payload = {
      labelingItemId: itemId,
      labelingObjects: filteredObjects.map(_serializeObject),
    }

    let isSkipped = false
    try {
      if (item?.status !== LABEL_FILE_STATUS.FINISHED && item?.status !== LABEL_FILE_STATUS.REJECTED && item?.status !== LABEL_FILE_STATUS.COMPLETED) {
        if (!userIsReviewer && (initialNodeStatus === LABEL_FILE_STATUS.REQUEST_REVIEW || initialNodeStatus === LABEL_FILE_STATUS.REVIEW_IN_PROGRESS)) {
          return false
        }
        await API().Labeling.skipItem(item?._id || '')
        isSkipped = true
      }
      await API().Labeling.saveObjects(payload)
      // FIXME: duration 쌓는 방식 확인 필요
      await API().Labeling.updateUserMeta({ labelingNodeId, lastIndex: index, duration: 0 })
      invalidateQueryData.objects(labelingNodeId, itemId)
    } catch (e) {
      console.error(e)
    }
    return isSkipped
  },
  { condition: getLabelingDefaultCondition }
)

const _serializeObject = (o: LabelingObjectData) => {
  const common = {
    labelingObjectId: o.id || '',
    labelingClassId: o.label || '',
    zOrder: 0,
  }
  if (o.id && uuidValidate(o.id)) {
    set(common, 'labelingObjectId', undefined)
  }
  const meta = {
    createdAt: o.createdAt,
    modifiedAt: o.modifiedAt,
  }

  switch (o.t) {
    case 'box': {
      /**
       * konva에서 사각형의 좌표 `x`, `y`는 `rotation`이 적용된 top-left 점이지만
       * backend에서의 `x`, `y`는 `rotation`이 0일 때의 top-left 점으로 다르기 때문에
       * `rotation`이 0일 때의 top-left 점으로 변환해 API 호출.
       */
      const {
        xtl, ytl, xbr, ybr,
      } = calcOriginalPointsForDB(o.x, o.y, o.width, o.height, o.rotation)
      return {
        ...common,
        // type: 2,
        type: ObjectToolType.RECTANGLE as keyof typeof ObjectToolType,
        isFixedRatio: o.isFixedRatio,
        // isPathEditable: o.isPathEditable || false,
        region: {
          xtl,
          ytl,
          xbr,
          ybr,
          rotation: o.rotation,
        },
        metadata: { ...meta },
      }
    }
    case 'ellipse': {
      return {
        ...common,
        type: ObjectToolType.ELLIPSE as keyof typeof ObjectToolType,
        isFixedRatio: o.isFixedRatio,
        // isPathEditable: o.isPathEditable,
        region: {
          cx: o.cx,
          cy: o.cy,
          rx: o.rx,
          ry: o.ry,
          rotation: o.rotation,
        },
        metadata: { ...meta },
      }
    }
    case 'polygon':
      return {
        ...common,
        type: ObjectToolType.POLYGON as keyof typeof ObjectToolType,
        region: { points: o.points.map((p: { x: any; y: any; }) => `${p.x},${p.y}`).join(';') },
        // isFixedRatio: o.isFixedRatio,
        isPathEditable: o.isPathEditable,
        metadata: { ...meta },
      }
    case 'tag':
      return {
        ...common,
        type: ObjectToolType.TAG,
      }
    default:
      console.error('cannot reach here - invalid object annotation type')
      return {
        labelingObjectId: '',
        labelingClassId: '',
        labelingNodeId: '',
        labelingItemId: '',
        isFixedRatio: false,
        isPathEditable: false,
        metadata: undefined,
        region: undefined,
        type: ObjectToolType.RECTANGLE as keyof typeof ObjectToolType,
        zOrder: 0,
      }
  }
}

export const saveUserShortcuts = createAsyncThunk<GetUserShortcutsResponse, UserShortcut[], ThunkConfig>(
  'labeling/save-user-shortcuts',
  async (shortcuts, thunkAPI) => {
    const savedShortcuts = await API().Labeling.updateUserShortcuts({ shortcutKeySettings: shortcuts })
    return savedShortcuts
  }
)
