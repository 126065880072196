import React, { useContext, useMemo, useState } from 'react'
import { reject } from 'lodash'

import {
  NotificationContext as NotiContextType,
  NotificationType,
  NotiCombinedData,
  ToastType
} from '@ahha/utils/@types/types'

const NotificationContext = React.createContext<NotiContextType>({
  notifications: [],
  addNotification: () => {},
  removeNotification: () => {},
})

const useGlobalNotification = () => useContext(NotificationContext)

const NotificationProvider = (props: any) => {
  const { children } = props
  const [notifications, setNotifications] = useState<(NotificationType | ToastType)[]>([])

  const addNotification = (notification: NotiCombinedData) => {
    // TODO: change id
    const id = Math.floor(Math.random() * 10000000)
    setNotifications((prev) => prev.concat({ id, ...notification }))
  }

  const removeNotification = (id: number | string) => {
    setNotifications((prev) => reject(prev, (p) => p.id === id))
  }

  const contextValue = useMemo(() => ({
    addNotification,
    removeNotification,
    notifications,
  }), [notifications])

  return (
    <NotificationContext.Provider value={contextValue}>
      {children}
    </NotificationContext.Provider>
  )
}

export { useGlobalNotification, NotificationProvider }
export default NotificationContext
