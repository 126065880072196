import { useCallback, useEffect, useRef, useSyncExternalStore } from 'react'
import { isString } from '@ahha/utils/@types/typeChecks'

type LocalStorageReturns<T> = [T, (value: T) => void, () => T]

interface LocalStorageOptions<T> {
  key: string
  initialValue: T
  listening?: boolean
  expire?: number
}

type Params<T> = [LocalStorageOptions<T>] | [string, T]

const DEFAULT_OPTIONS = { listening: true, expire: Infinity }

export function useLocalStorage<T>(key: string, initialValue: T): LocalStorageReturns<T>
export function useLocalStorage<T>(options: LocalStorageOptions<T>): LocalStorageReturns<T>
export function useLocalStorage<T>(...params: Params<T>) {
  const { key, initialValue, listening, expire } = distributeParams(params)

  const snapshot = useRef<{ value: T | null, expireAt: number }>({ value: null, expireAt: -1 })
  const isExpired = useRef(false)
  const expireTimer = useRef<NodeJS.Timeout>()

  const subscribe = useCallback((callback: () => void) => {
    const handleStorageChanged = (e: StorageEvent) => {
      if (e.key === key) {
        callback()
      }
    }

    window.addEventListener('storage', handleStorageChanged)
    return () => window.removeEventListener('storage', handleStorageChanged)
  }, [key])

  const getSnapshot = useCallback((): T => {
    if (!listening) {
      return initialValue
    }
    if (snapshot.current.value) {
      return snapshot.current.value
    }
    const v = JSON.parse(localStorage.getItem(key) ?? 'null')

    if (v) {
      snapshot.current = v
    }
    return v?.value ?? initialValue
  }, [key, listening])

  const state = useSyncExternalStore(subscribe, getSnapshot)

  const setValue = (value: T) => {
    const expireAt = expire === Infinity ? -1 : Date.now() + expire
    const next = { value, expireAt }

    snapshot.current = next
    dispatchEvent(new StorageEvent('storage', { key, newValue: JSON.stringify(next) }))
  }

  const getValue = () => JSON.parse(localStorage.getItem(key) ?? 'null') ?? initialValue

  useEffect(() => {
    if (expire === Infinity) {
      return
    }
    const { expireAt } = snapshot.current
    const timeRemaining = Math.max(expireAt - Date.now(), 0)
    const initialSnapshot = { value: initialValue, expireAt: -1 }

    expireTimer.current = setTimeout(() => {
      snapshot.current = initialSnapshot
      isExpired.current = true
    }, timeRemaining)

    return () => expireTimer.current && clearTimeout(expireTimer.current)
  }, [state, expire])

  useEffect(() => () => {
    if (isExpired.current) {
      localStorage.removeItem(key)
    }
  }, [])

  return [state, setValue, getValue] as const
}

const distributeParams = <T>(params: Params<T>) => {
  const [p0, p1] = params

  if (isString(p0)) {
    return { ...DEFAULT_OPTIONS, key: p0, initialValue: p1 as T }
  }
  return { ...DEFAULT_OPTIONS, ...p0 }
}
