import BaseApi from '@/api/Base/BaseApi'
import {
  CreateModelNodePayload,
  CreateParametersPayload,
  GetTrainResultResponse,
  GetTrainStatusResponse,
  ModelNodeInfo,
  UpdateModelNodePayload
} from '@/api/ModelNode/types'

const ROOT = '/model'

export default class ModelNodeApi extends BaseApi {
  createModelNode(payload: CreateModelNodePayload) {
    const { projectId, ...rest } = payload
    return this.agent.post<ModelNodeInfo>(`/project/${projectId}/model/create`, rest)
  }

  getModelNodeDetail(modelNodeId: string) {
    return this.agent.get<ModelNodeInfo>(`${ROOT}/${modelNodeId}`)
  }

  updateModelNode(payload: UpdateModelNodePayload) {
    const { modelNodeId, ...rest } = payload
    return this.agent.put<ModelNodeInfo>(`${ROOT}/${modelNodeId}`, rest)
  }

  deleteModelNode(modelNodeId: string) {
    return this.agent.delete(`${ROOT}/${modelNodeId}/delete`)
  }

  startTrain(modelNodeId: string) {
    return this.agent.post<GetTrainResultResponse>(`${ROOT}/${modelNodeId}/train`)
  }

  getTrainStatus(modelNodeId: string) {
    return this.agent.get<GetTrainStatusResponse>(`${ROOT}/${modelNodeId}/train/status`)
  }

  getTrainResult(modelNodeId: string) {
    return this.agent.get(`${ROOT}/${modelNodeId}/train`)
  }
}
