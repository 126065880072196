import { axiosInstance } from '@/api/Base/axiosInstance'

export default class BaseApi {
  #token: string

  #signal: AbortSignal | undefined

  agent: typeof axiosInstance

  constructor(signal?: AbortSignal) {
    this.#token = ''
    this.agent = axiosInstance
    this.#signal = signal
  }

  setToken(token: string) {
    this.#token = token
  }

  getToken() {
    return this.#token
  }

  setSignal(signal: AbortSignal) {
    this.#signal = signal
  }

  get signal() {
    return this.#signal
  }
}
