export const SEPARATE_EVENT_TIME_SPAN = 45

export const BLINK_MOUSE_WHEEL_DELTA = [50, 33.33333]

export const GECKO_MOUSE_WHEEL_DELTA = 20

export const DELTA_MODE = {
  PIXEL: 0,
  LINE: 1,
  PAGE: 2,
}

export const DELTA_MODE_PAGE_SCROLL_AMOUNT = {
  Windows: {
    /** 구버전 Chrome(<= 100)에서는 delta 값이 정수로, 그 외 브라우저에서는 0.6666...으로 나타는 경우 대응 */
    Blink: 100,
    Gecko: 40,
  },
  /** Mac은 해당 옵션이 없음. */
  'Mac OS': {
    Blink: 1,
    Gecko: 1,
    WebKit: 1,
  },
}
