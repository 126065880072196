import BaseApi from '@/api/Base/BaseApi'
import {
  AddClassPayload,
  AnnotationClassType,
  CreateAnnotationPayload,
  CreateAnnotationResponse,
  CreateObjectPayload,
  GetAnnotationFilesPayload,
  GetAnnotationFilesResponse,
  GetAnnotationResponse, GetHistoryResponse,
  GetObjectsForFileResponse,
  MarkObjectAsOkPayload,
  SaveObjectsPayload,
  UpdateClassOrderPayload,
  UpdateClassPayload,
  UpdateObjectPayload
} from '@/api/Annotation/types'

const COUNT_PER_PAGE = 40

export default class AnnotationApi extends BaseApi {
  createAnnotation(payload: CreateAnnotationPayload) {
    return this.agent.post<CreateAnnotationResponse>('/annotation', payload)
  }

  getAnnotation(annotationId: string) {
    return this.agent.get<GetAnnotationResponse>(`/annotation/${annotationId}`)
  }

  getAnnotationFiles(payload: GetAnnotationFilesPayload) {
    const { annotationId, page, itemsPerPage, sortKey, sortDir } = payload
    return this.agent.get<GetAnnotationFilesResponse>(`/annotation/${annotationId}/files`, {
      params: {
        page,
        num_per_page: itemsPerPage || COUNT_PER_PAGE,
        sort_key: sortKey,
        sort_dir: sortDir,
      },
    })
  }

  getClasses(annotationId: string) {
    return this.agent.get<AnnotationClassType[]>(`/annotation/${annotationId}/class`)
  }

  addClass(payload: AddClassPayload) {
    return this.agent.post<AnnotationClassType>('/annotation/class', payload, { signal: this.signal })
  }

  updateClass(payload: UpdateClassPayload) {
    return this.agent.put<AnnotationClassType>('/annotation/class', payload)
  }

  updateClassOrder(payload: UpdateClassOrderPayload) {
    return this.agent.put<AnnotationClassType[]>('/annotation/class/order', payload)
  }

  deleteClass(classId: string) {
    return this.agent.delete<AnnotationClassType>(`/annotation/class/${classId}`)
  }

  getObjectsForFile(annotationId: string, datasetFileId: string) {
    return this.agent.get<GetObjectsForFileResponse>(`/annotation/${annotationId}/${datasetFileId}/objects`)
  }

  createObject(payload: CreateObjectPayload) {
    return this.agent.post('/annotation/object', payload)
  }

  updateObject(payload: UpdateObjectPayload) {
    return this.agent.put('/annotation/object', payload)
  }

  markObjectAsOk(payload: MarkObjectAsOkPayload) {
    return this.agent.post('/annotation/object-skip', payload)
  }

  deleteObject(annotationId: string, datasetFileId: string, annotationObjectId: string) {
    return this.agent.delete(`/annotation/${annotationId}/${datasetFileId}/object/${annotationObjectId}`)
  }

  saveObjects(payload: SaveObjectsPayload) {
    return this.agent.put('/annotation/objects-save', payload)
  }

  getHistory(annotationId: string, datasetFileId: string) {
    return this.agent.get<GetHistoryResponse[]>(`/annotation/${annotationId}/${datasetFileId}/history`)
  }
}
