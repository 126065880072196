import { MainCategory } from '@/pages/Pipeline/canvas/FlowNode/types'

export const LINK_ERROR = {
  stale: 'link is stale.',
  incorrect: 'link is incorrect.',
} as const

export const INITIAL_NODE_TITLE: Record<MainCategory, string> = {
  dataset: 'Dataset',
  labeling: 'Labeling',
  train: 'Model(Train)',
  validation: 'Model(Validation)',
  inference: 'Model(Inference)',
  review: 'Review',
}
