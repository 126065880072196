import { CommonListParams, withOptions } from '@/api/react-query/queryKeys/common'

export const datasetNodeKeys = {
  all: (projectId: string) => [projectId, 'datasetNode'],
  detail: (projectId: string, datasetNodeId: string) => [...datasetNodeKeys.all(projectId), 'detail', datasetNodeId],
  files: (projectId: string, datasetNodeId: string, options?: CommonListParams) => withOptions(
    [...datasetNodeKeys.detail(projectId, datasetNodeId), 'files'],
    options
  ),
  file: (projectId: string, datasetNodeId: string, fileId: string) => [...datasetNodeKeys.files(projectId, datasetNodeId), 'file', fileId],
}
