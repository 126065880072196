import { createSlice } from '@reduxjs/toolkit'

import { getDateWithEndTime, getDateWithStartTime } from '@ahha/utils/formatters/date'
import { CameraState } from '@/stores/slices/cameraSlice/types'

const groupHistoryType = { id: null, name: null }

const cameraHistoryType = { id: null }

const cameraDateType = {
  startDay: getDateWithStartTime(new Date()),
  endDay: getDateWithEndTime(new Date()),
}

const cameraSortType = { reviewed: undefined }

const initialState: CameraState = {
  groupHistoryState: groupHistoryType,
  cameraHistoryState: cameraHistoryType,
  cameraDateState: cameraDateType,
  cameraSortState: cameraSortType,
  recordingCamera: [],
  recordedCamera: {},
}

export const cameraSlice = createSlice({
  name: 'camera',
  initialState,
  reducers: {
    setGroupHistoryState: (state, action) => {
      state.groupHistoryState = action.payload
    },
    resetGroupHistoryState: (state) => {
      state.groupHistoryState = groupHistoryType
    },
    setCameraHistoryState: (state, action) => {
      state.cameraHistoryState = action.payload
    },
    resetCameraHistoryState: (state) => {
      state.cameraHistoryState = cameraHistoryType
    },
    setCameraDateState: (state, action) => {
      const { startDay, endDay } = action.payload

      state.cameraDateState = {
        startDay: getDateWithStartTime(startDay ?? new Date()),
        endDay: getDateWithEndTime(endDay ?? new Date()),
      }
    },
    resetCameraDateState: (state) => {
      state.cameraDateState = cameraDateType
    },
    setCameraSortState: (state, action) => {
      state.cameraSortState = action.payload
    },
    resetCameraSortState: (state) => {
      state.cameraSortState = cameraSortType
    },
    addRecordingCamera: (state, action) => {
      state.recordingCamera = [...state.recordingCamera, action.payload]
    },
    removeRecordingCamera: (state, action) => {
      state.recordingCamera = state.recordingCamera.filter((cam) => cam?.id !== action.payload)
    },
    setRecordedCamera: (state, action) => {
      state.recordedCamera = action.payload
    },
  },
})

const cameraReducer = cameraSlice.reducer

export const {
  setGroupHistoryState,
  resetGroupHistoryState,
  setCameraHistoryState,
  resetCameraHistoryState,
  setCameraDateState,
  resetCameraDateState,
  setCameraSortState,
  resetCameraSortState,
  addRecordingCamera,
  removeRecordingCamera,
  setRecordedCamera,
} = cameraSlice.actions

export default cameraReducer
