import { useCallback, useEffect, useRef, useState } from 'react'
import probe from 'probe-image-size'
import UTIF from 'utif'
import { useQueryClient } from '@tanstack/react-query'
import { isSymbol } from '@ahha/utils/@types/typeChecks'
import { annotationKeys } from '@/api/react-query/queryKeys/annotation'

interface UseTiffParams {
  src: string | symbol
  isTiff: boolean
}

export const useStableSource = (src: string | symbol) => {
  const prevSrc = useRef('')

  const path = removeQueryParams(resolveSource(src))
  const prevPath = removeQueryParams(prevSrc.current)

  /** 쿼리 파리미터만 다른 source는 동일한 source로 간주 */
  if (path === prevPath) {
    return prevSrc.current
  }
  prevSrc.current = resolveSource(src)
  return resolveSource(src)
}

export const useTiff = (src: string | symbol, isTiff = false) => {
  const [url, setUrl] = useState('')
  const queryClient = useQueryClient()

  const parseTiff = useCallback(async (src: string | symbol) => {
    try {
      if (isSymbol(src)) {
        return src.description as string
      }
      const res = await fetch(src)
      const [buffer, imageMeta] = await Promise.all([res.arrayBuffer(), probe(src)])
      const { width = 0, height = 0 } = imageMeta

      const { ctx, canvas } = createCanvas(width, height)
      const rgba = decodeTiffBuffer(buffer)

      ctx.putImageData(new ImageData(rgba, width, height), 0, 0)
      return canvas.toDataURL('image/png')
    } catch (error) {
      console.error('Error loading image:', error)
    }
    return 'parse failed'
  }, [])

  useEffect(() => {
    if (!isTiff) {
      return
    }
    const sourceKey = removeQueryParams(resolveSource(src))
    const tiffCache = queryClient.getQueryData<string>(annotationKeys.tiffImage(sourceKey));

    (async () => {
      if (tiffCache) {
        setUrl(tiffCache)
      } else {
        const imageUrl = await parseTiff(src)

        setUrl(imageUrl)
        queryClient.setQueryData(annotationKeys.tiffImage(sourceKey), imageUrl)
      }
    })()
  }, [src, isTiff])

  if (!url) {
    return resolveSource(src)
  }
  return url
}

/** @deprecated roi 적용 후 제거 */
export const useTiffImage = ({ src, isTiff }: UseTiffParams) => {
  const prevSrc = useRef('')
  const [url, setUrl] = useState('')
  const queryClient = useQueryClient()

  const path = removeQueryParams(resolveSource(src))
  const prevPath = removeQueryParams(prevSrc.current)
  /** 쿼리 파리미터만 다른 source는 동일한 source로 간주 */
  const isSrcIdentical = path === prevPath

  const parseTiff = useCallback(async (src: string | symbol) => {
    try {
      if (isSymbol(src)) {
        return src.description as string
      }
      const res = await fetch(src)
      const [buffer, imageMeta] = await Promise.all([res.arrayBuffer(), probe(src)])
      const { width = 0, height = 0 } = imageMeta

      const { ctx, canvas } = createCanvas(width, height)
      const rgba = decodeTiffBuffer(buffer)

      ctx.putImageData(new ImageData(rgba, width, height), 0, 0)
      return canvas.toDataURL('image/png')
    } catch (error) {
      console.error('Error loading image:', error)
    }
    return 'parse failed'
  }, [])

  useEffect(() => {
    if (!isTiff || isSrcIdentical) {
      return
    }
    const tiffCache = queryClient.getQueryData<string>(annotationKeys.tiffImage(path));

    (async () => {
      if (tiffCache) {
        setUrl(tiffCache)
      } else {
        const imageUrl = await parseTiff(src)

        setUrl(imageUrl)
        queryClient.setQueryData(annotationKeys.tiffImage(path), imageUrl)
      }
    })()
    prevSrc.current = resolveSource(src)
  }, [src, isTiff, isSrcIdentical])

  if (isTiff) {
    return { url }
  }
  if (isSrcIdentical) {
    return { url: prevSrc.current }
  }

  prevSrc.current = resolveSource(src)
  return { url: resolveSource(src) }
}

const createCanvas = (width?: number, height?: number) => {
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')

  canvas.width = width || 100
  canvas.height = height || 100

  if (!ctx) {
    throw new Error('2d context not supported or canvas already initialized.')
  }
  return { ctx, canvas }
}

const decodeTiffBuffer = (buffer: ArrayBuffer) => {
  const imageDirectories = UTIF.decode(buffer)

  UTIF.decodeImage(buffer, imageDirectories[0])
  return new Uint8ClampedArray(UTIF.toRGBA8(imageDirectories[0]))
}

const removeQueryParams = (url: string) => url.split('?')[0]

const resolveSource = (src: string | symbol) => (isSymbol(src) ? src.description as string : src)
