import { createAsyncThunk } from '@reduxjs/toolkit'
import { find } from 'lodash'

import { API } from '@/api'
import { ThunkConfig } from '@/stores/slices/Labeling/types'
import { socket } from '@/socket'
import { LabelingObject } from '@/api/LabelingNode/types'

export const removeObject = createAsyncThunk<{ itemId: string, objectId: string }, { index: number, objectId: string }, ThunkConfig>(
  'labeling/remove-object',
  async (payload, thunkAPI) => {
    const { index, objectId } = payload
    // 있어야하는 로직인지???
    // const { comments, files } = thunkAPI.getState().labeling
    // const { serverId } = find(comments[files[index]?._id || ''], (c) => c.anchor === objectId) ?? {}
    // const deletedComment = await API().Labeling.deleteObject(serverId)
    const { user } = thunkAPI.getState().user
    const { labelingNodeId } = thunkAPI.getState().labeling
    const res = await API().Labeling.deleteObject(objectId)
    socket.emitDeleteObject({
      nodeId: labelingNodeId,
      userId: user._id,
      userName: user.name,
      itemId: res.labelingItemId,
      deletedObject: res,
    } as any)
    return { itemId: res.labelingItemId, objectId }
  }
)
