/* eslint-disable no-loop-func */
import { isEmpty } from 'lodash'
import { DatasetNodeInfo, Node } from '@/api/DatasetNode/types'
import { LabelingVariant, ReviewVariant } from '@/pages/Pipeline/canvas/FlowNode/types'
import { LabelingFileStatusType, LabelingNodeInfo, ReviewNodeInfo } from '@/api/LabelingNode/types'
import {
  DatasetNode,
  InferenceNode,
  LabelNode,
  ModelNode,
  ReviewNode,
  ValidationNode
} from '@/stores/slices/Pipeline/types/node'
import { ModelNodeInfo } from '@/api/ModelNode/types'
import { ValidationNodeInfo } from '@/api/ValidationNode/types'
import { InferenceNodeInfo } from '@/api/InferenceNode/types'
import { LABEL_FILE_STATUS } from '@/api/LabelingNode/const'

const getNodeArea = (nodeData?: Node<any>) => nodeData?.metadata?.area ?? '-1:-1'

const getLabelVariant = (status: LabelingFileStatusType): LabelingVariant => {
  if (status === LABEL_FILE_STATUS.NOT_STARTED) {
    return 'waiting'
  }
  if (status === LABEL_FILE_STATUS.IN_PROGRESS) {
    return 'workOngoing'
  }
  if (status === LABEL_FILE_STATUS.FINISHED) {
    return 'completed'
  }
  if (status === LABEL_FILE_STATUS.REQUEST_REVIEW) {
    return 'reviewOngoing'
  }
  if (status === LABEL_FILE_STATUS.REVIEW_IN_PROGRESS) {
    return 'reviewOngoing'
  }
  return 'waiting'
}

const getReviewVariant = (status: LabelingFileStatusType): ReviewVariant => {
  if (status === LABEL_FILE_STATUS.NOT_STARTED) {
    return 'waiting'
  }
  /* if (status === LABEL_FILE_STATUS.IN_PROGRESS) {
    return 'workOngoing'
  } */
  if (status === LABEL_FILE_STATUS.FINISHED) {
    return 'completed'
  }
  if (status === LABEL_FILE_STATUS.REVIEW_IN_PROGRESS) {
    return 'ongoing'
  }
  return 'waiting'
}

const getLabelVariantFromSubNodes = (subNodes: LabelingNodeInfo[]) => {
  const allCurrentStatus = new Set(subNodes.map((n) => getLabelVariant(n.status)))
  const isDetermined = allCurrentStatus.size === 1

  if (allCurrentStatus.has('workOngoing')) {
    return 'workOngoing'
  }
  if (isDetermined && allCurrentStatus.has('reviewOngoing')) {
    return 'reviewOngoing'
  }
  if (isDetermined && allCurrentStatus.has('completed')) {
    return 'completed'
  }
  return 'waiting'
}

const toDatasetNode = (datasets: DatasetNodeInfo[], includeSubNodes = true): DatasetNode[] => {
  const L = datasets.length
  const nodes = []

  for (let i = 0; i < L; i += 1) {
    const current = datasets[i]

    nodes.push({
      id: current._id,
      title: current.name,
      details: `${current.numOfDatasetItems ?? 0}`,
      type: 'dataset' as const,
      isActivated: Boolean(current.isActive),
      area: getNodeArea(current),
      createdAt: current.createdAt,
      next: current.labelingNodes?.map((n) => n._id),
      children: current.subNodes?.map((n) => n._id),
    })
  }

  return nodes
}

const toLabelNode = (labels: LabelingNodeInfo[]): LabelNode[] => {
  const L = labels.length
  const nodes = []

  for (let i = 0; i < L; i += 1) {
    const current = labels[i]
    const SUBNODE_DATA = { total: 0, finished: 0, rejected: 0 }
    const mergedData = current.subNodes?.reduce((a, c) => ({
      ...a,
      total: a.total + c.numOfLabelingItems,
      finished: a.finished + c.numOfFinishedItems,
      rejected: a.rejected + c.numOfRejectedItems,
    }), SUBNODE_DATA)

    const variant = current.subNodes ? getLabelVariantFromSubNodes(current.subNodes) : getLabelVariant(current.status)
    const fileStatus = mergedData
      ? [mergedData.total, mergedData.finished, mergedData.rejected]
      : [current.numOfLabelingItems, current.numOfFinishedItems, current.numOfRejectedItems]

    nodes.push({
      id: current._id,
      title: current.name,
      details: current.modelType ?? '',
      type: 'labeling' as const,
      variant,
      isActivated: Boolean(current.isActive),
      area: getNodeArea(current),
      createdAt: current.createdAt,
      previous: current.datasetNodeIds,
      next: [],
      fileStatus,
      classGroupId: current.classGroupId,
      classGroupName: current.classGroupName,
      isTempClassGroup: current?.isTempClassGroup || false,
    })
  }
  return nodes
}

const toReviewNode = (reviews: ReviewNodeInfo[]): ReviewNode[] => {
  const L = reviews.length
  const nodes = []

  for (let i = 0; i < L; i += 1) {
    const current = reviews[i]
    nodes.push({
      id: current._id,
      title: current.name,
      // FIXME: detail content?
      details: current.name,
      type: 'review' as const,
      isActivated: Boolean(current.isActive),
      area: getNodeArea(current),
      variant: getReviewVariant(current.status),
      createdAt: current.createdAt,
      previous: current.labelingNodeId ? [current.labelingNodeId] : undefined,
      next: [],
      fileStatus: [current.numOfLabelingItems, current.numOfNotReviewedItems, current.numOfRejectedItems, current.numOfCompletedItems],
    })
  }
  return nodes
}

const toModelNode = (models: ModelNodeInfo[]): ModelNode[] => {
  const L = models.length
  const nodes = []

  for (let i = 0; i < L; i += 1) {
    const current = models[i]
    nodes.push({
      id: current._id,
      title: current.name,
      details: current.modelType ?? '',
      type: 'train' as const,
      variant: current.trainingJobId ? 'ongoing' as const : 'waiting' as const,
      isActivated: Boolean(current.isActive),
      area: getNodeArea(current),
      createdAt: current.createdAt,
      previous: !isEmpty(current.reviewNodeIds) ? current.reviewNodeIds : (current.labelingNodeIds ?? undefined),
      next: [],
    })
  }
  return nodes
}

const toValidationNode = (validations: ValidationNodeInfo[]): ValidationNode[] => {
  const L = validations.length
  const nodes = []

  for (let i = 0; i < L; i += 1) {
    const current = validations[i]
    const { labelingNodeId, reviewNodeId, modelNodeId } = current
    const prevLinks: string[] = []
    if (reviewNodeId) {
      prevLinks.push(reviewNodeId)
    } else if (labelingNodeId) {
      prevLinks.push(labelingNodeId)
    }
    if (modelNodeId) {
      prevLinks.push(modelNodeId)
    }
    nodes.push({
      id: current._id,
      title: current.name,
      details: current.numOfLabelingItems?.toLocaleString() ?? '',
      type: 'validation' as const,
      variant: current.validationJobId ? 'ongoing' as const : 'waiting' as const,
      isActivated: Boolean(current.isActive),
      area: getNodeArea(current),
      createdAt: current.createdAt,
      previous: !isEmpty(prevLinks) ? prevLinks : undefined,
      next: [],
    })
  }
  return nodes
}

const toInferenceNode = (inferences: InferenceNodeInfo[]): InferenceNode[] => {
  const L = inferences.length
  const nodes = []

  for (let i = 0; i < L; i += 1) {
    const current = inferences[i]
    const { datasetNodeId, modelNodeId } = current
    const prevLinks: string[] = []
    if (datasetNodeId) {
      prevLinks.push(datasetNodeId)
    }
    if (modelNodeId) {
      prevLinks.push(modelNodeId)
    }
    nodes.push({
      id: current._id,
      title: current.name,
      details: current.numOfDatasetItems?.toLocaleString() ?? '',
      type: 'inference' as const,
      variant: current.inferenceJobId ? 'ongoing' as const : 'waiting' as const,
      isActivated: Boolean(current.isActive),
      area: getNodeArea(current),
      createdAt: current.createdAt,
      previous: !isEmpty(prevLinks) ? prevLinks : undefined,
      next: [],
    })
  }
  return nodes
}

export const transform = {
  toDatasetNode,
  toLabelNode,
  toReviewNode,
  toModelNode,
  toValidationNode,
  toInferenceNode,
}
