import BaseApi from '@/api/Base/BaseApi'
import { CreatePayload, DatasetDetailResponse, DatasetFilesResponse, DatasetListResponse, GetFilesPayload, GetListPayload } from '@/api/Dataset/types'

const COUNT_PER_PAGE = 20

const DEFAULT_GET_DATASET_PAYLOAD: Required<Omit<GetListPayload, 'keyword' | 'type' | 'filter'>> = {
  page: 0,
  itemsPerPage: COUNT_PER_PAGE,
  sortKey: 'name',
  order: 'asc',
}

export default class DatasetApi extends BaseApi {
  getDatasetList(params?: GetListPayload) {
    const payload = { ...DEFAULT_GET_DATASET_PAYLOAD, ...params }
    const { keyword, type, filter, page, itemsPerPage, sortKey, order } = payload

    return this.agent.get<DatasetListResponse>('/dataset/list', {
      params: {
        name: keyword,
        type,
        filter,
        page,
        sort_key: sortKey,
        sort_dir: order,
        num_per_page: itemsPerPage || COUNT_PER_PAGE,
      },
    })
  }

  getDataset(id: string) {
    return this.agent.get<DatasetDetailResponse>(`/dataset/${id}`)
  }

  getDatasetFiles(payload: GetFilesPayload) {
    const { id, page, itemsPerPage, sortKey, order } = payload

    return this.agent.get<DatasetFilesResponse>(`/dataset/${id}/files`, {
      params: {
        page,
        sort_key: sortKey,
        sort_dir: order,
        num_per_page: itemsPerPage || COUNT_PER_PAGE,
      },
    })
  }

  createDataset({ name, fileIds, type }: CreatePayload) {
    return this.agent.post('/dataset', {
      name,
      file_ids: fileIds,
      type,
    })
  }

  deleteDataset(id: string) {
    return this.agent.delete(`/dataset/${id}`)
  }
}
