export const ERRORS = {
  duplicatedName: 1,
  duplicatedColor: 2,
  unavailableName: 3,
}

export const UNAVAILABLE_NAME = 'background'

export const DEFAULT_PALETTE = [
  '#F71F1F', '#05AFF7', '#FDD631', '#1FE224', '#9E40FD',
  '#38E6FE', '#FD6F41', '#D6D6D6', '#FD4082', '#242DFF',
  '#F16FFF', '#CCDB36', '#068513', '#BF7C7C', '#8D8D8D',
  '#BDA6FF', '#F32C00', '#1FBFA5', '#FDAA40', '#C41162',
  '#1E3561', '#D200CA', '#830000', '#8F736B', '#52BCCF',
  '#E6CBB9', '#481E9D', '#1891EA', '#F085AC', '#239587',
]

export const DEFAULT_SWATCHES = [
  '#3FC4FC', '#63FFDA', '#5AF158', '#FEE934', '#FD9E80', '#FD80AA',
  '#1891EA', '#1FBFA5', '#1EC722', '#FDD631', '#FD6F41', '#FD4082',
  '#4E6CF0', '#169689', '#259B23', '#FDAA40', '#FB3D1D', '#F61058',
  '#3A4FCC', '#12828E', '#0B6F0D', '#FC6D21', '#DD2C18', '#C41162',
  '#E0E0E0', '#757575', '#424242',
]
