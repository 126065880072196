import BaseApi from '@/api/Base/BaseApi'
import {
  AddClassToGroupPayload,
  ClassGroup,
  CreateClassGroupPayload,
  DeleteClassInGroupPayload,
  LabelingClass,
  UpdateClassGroupPayload,
  UpdateClassInGroupPayload
} from '@/api/ClassGroup/types'

const ROOT = '/class/group'

export default class ClassGroupApi extends BaseApi {
  getClassGroupList(projectId: string) {
    return this.agent.get<ClassGroup[]>(`/project/${projectId}${ROOT}/list`)
  }

  createClassGroup(payload: CreateClassGroupPayload) {
    const { projectId, ...rest } = payload
    return this.agent.put<ClassGroup>(`/project/${projectId}${ROOT}/create`, rest)
  }

  getClassGroup(classGroupId: string) {
    return this.agent.get<ClassGroup>(`${ROOT}/${classGroupId}`)
  }

  updateClassGroup(payload: UpdateClassGroupPayload) {
    const { classGroupId, ...rest } = payload
    return this.agent.put<ClassGroup>(`${ROOT}/${classGroupId}`, rest)
  }

  getClassesInGroup(classGroupId: string) {
    return this.agent.get<LabelingClass[]>(`${ROOT}/${classGroupId}/list`)
  }

  addClassToGroup(payload: AddClassToGroupPayload) {
    const { classGroupId, ...rest } = payload
    return this.agent.post<ClassGroup>(`${ROOT}/${classGroupId}/class/create`, rest)
  }

  updateClassInGroup(payload: UpdateClassInGroupPayload) {
    const { classGroupId, labelingClassId, ...rest } = payload
    return this.agent.put<LabelingClass>(`${ROOT}/${classGroupId}/class/${labelingClassId}`, rest)
  }

  deleteClassInGroup(payload: DeleteClassInGroupPayload) {
    const { classGroupId, labelingClassId } = payload
    return this.agent.delete<ClassGroup>(`${ROOT}/${classGroupId}/class/${labelingClassId}`)
  }
}
