const ELEMENT_TO_SUPPRESS_EVENT = new Set(['INPUT', 'TEXTAREA', 'SELECT', 'OPTION'])

export const shouldSuppressKeyEvent = () => {
  const activeElement = document.activeElement?.tagName ?? ''
  return ELEMENT_TO_SUPPRESS_EVENT.has(activeElement)
}

export const isCtrlKeyPressed = (e: KeyboardEvent | MouseEvent) => {
  const platform = navigator.userAgentData?.platform ?? navigator.platform
  const isMacOS = platform.toUpperCase().indexOf('MAC') >= 0

  return isMacOS ? e.metaKey : e.ctrlKey
}
