/* eslint-disable */
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react'
import i18next from 'i18next'
import { initReactI18next, useTranslation } from 'react-i18next'
import { isEmpty } from 'lodash'
import dayjs from 'dayjs'
import { TKey, TKeyOptions, useI18Next } from '@ahha/utils/hooks/useI18Next'

i18next
  .use(initReactI18next)
  .init({
    resources: {},
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
  })

export interface I18nAvailableLanguage {
  language: string,
  dictionary: any,
  language_display_name?: string,
}

export type I18nContextValue = {
  t: (key: TKey, options?: TKeyOptions) => string,
  availableLanguages: {
    language: string,
    language_display_name?: string
  }[],
  currentLanguage: string,
  changeLanguage: (lang: string) => void,
};


const I18nContext = createContext<I18nContextValue>({
  t: (key: TKey) => `${key}`,
  availableLanguages: [],
  currentLanguage: '',
  changeLanguage: (lang: string) => {
  },
})

export const I18nProvider = ({
  children,
  availableLanguages,
  currentLanguage,
  setCurrentLanguage,
}: {
  children: React.ReactNode,
  availableLanguages: I18nAvailableLanguage | I18nAvailableLanguage[],
  currentLanguage?: string,
  setCurrentLanguage?: (lang: string) => void
}) => {
  const { t } = useI18Next()
  const { i18n } = useTranslation()
  const [_defaultLang, _setDefaultLang] = useState<string | undefined>(undefined)
  const [_currentLang, _setCurrentLang] = useState<string>('')

  useEffect(() => {
    i18n.changeLanguage(_currentLang)
  }, [_currentLang])

  useEffect(() => {
    if (isEmpty(currentLanguage) && !isEmpty(_defaultLang)) {
      _setCurrentLang(_defaultLang!!)
    } else if (!isEmpty(currentLanguage)) {
      _setCurrentLang(currentLanguage!!)
    }
    dayjs.locale(currentLanguage)
  }, [currentLanguage])

  useEffect(() => {
    let availables: I18nAvailableLanguage[] = []
    if (Array.isArray(availableLanguages)) {
      availables = availableLanguages
    } else {
      availables = [availableLanguages]
    }

    let defaultLng: undefined | string = undefined
    if (availables.length > 0) {
      defaultLng = availables[0].language
      _setDefaultLang(defaultLng)
      availables.forEach((lang) => {
        i18n.addResources(lang.language, 'translation', lang.dictionary)
      })
      if (isEmpty(currentLanguage) && !isEmpty(defaultLng)) {
        changeLanguage(defaultLng)
      }
    }
  }, [availableLanguages, currentLanguage])

  const changeLanguage = (lang: string) => {
    if (setCurrentLanguage) {
      setCurrentLanguage(lang)
    } else {
      _setCurrentLang(lang)
    }
  }

  const value = useMemo(() => {
    let availables: I18nAvailableLanguage[] = []
    if (Array.isArray(availableLanguages)) {
      availables = availableLanguages
    } else {
      availables = [availableLanguages]
    }

    return {
      t,
      availableLanguages: availables.map((lan) => {
        return {
          language: lan.language,
          language_display_name: lan.language_display_name,
        }
      }),
      currentLanguage: _currentLang,
      changeLanguage,
    }
  }, [t, availableLanguages, _currentLang])

  return (
    <I18nContext.Provider value={value}>
      {children}
    </I18nContext.Provider>
  )
}

export const useI18n = () => {
  const contextValue = useContext(I18nContext)
  if (contextValue === undefined) {
    console.error('Error fail to get context value')
  }
  return contextValue
}

