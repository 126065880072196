import { isEqual, isNotEqual } from '@ahha/components/Canvas/utils/common'
import { BLINK_MOUSE_WHEEL_DELTA, GECKO_MOUSE_WHEEL_DELTA } from '@ahha/components/Canvas/utils/hooks/useWheelEvent/const'
import { maxAbs } from '@ahha/utils/number'

interface Delta {
  x: number
  y: number
}

const isMultipleOf = (n: number, ...constant: number[]) => n !== 0 && constant.some((c) => isEqual(n % c, 0))

const isNotMultipleOf = (n: number, ...constant: number[]) => constant.every((c) => isNotEqual(n % c, 0))

const isPairOf = {
  FloatZero: (a: number, b: number) => Math.abs(a + b) === Math.abs(a - b) && !Number.isInteger(a + b),
  FloatFloat: (a: number, b: number) => !Number.isInteger(a + b),
  IntZero: (a: number, b: number) => Math.abs(a + b) === Math.abs(a - b) && Number.isInteger(a + b),
  IntInt: (a: number, b: number) => Number.isInteger(a) && Number.isInteger(b),
}

const isMouse = {
  intDefault: (e: WheelEvent) => !e.ctrlKey && isPairOf.IntZero(e.deltaX, e.deltaY),
  floatDefault: (e: WheelEvent) => !e.ctrlKey && isPairOf.FloatZero(e.deltaX, e.deltaY),
  deltaValidation: {
    onMac: (deltaThreshold: number) => (e: WheelEvent, initDelta: Delta) => !e.ctrlKey && isPairOf.IntZero(e.deltaX, e.deltaY) && initDelta.x === 0 && isMultipleOf(e.deltaY, deltaThreshold),
    onWindows: (deltaThreshold: number[]) => (e: WheelEvent) => !e.ctrlKey && e.deltaX === 0 && isMultipleOf(e.deltaY, ...deltaThreshold),
  },
}

const isTrackPad = {
  intDefault: (e: WheelEvent) => isPairOf.IntInt(e.deltaX, e.deltaY),
  floatDefault: (e: WheelEvent) => !e.ctrlKey && isPairOf.FloatFloat(e.deltaX, e.deltaY),
  deltaValidation: {
    onMac: (deltaThreshold: number) => (e: WheelEvent, initDelta: Delta) => isPairOf.IntInt(e.deltaX, e.deltaY) && Math.abs(maxAbs(initDelta.x, initDelta.y)) < deltaThreshold && isNotMultipleOf(e.deltaX + e.deltaY, deltaThreshold),
    onWindows: (deltaThreshold: number[]) => (e: WheelEvent) => isNotMultipleOf(e.deltaX, ...deltaThreshold) || isNotMultipleOf(e.deltaY, ...deltaThreshold),
  },
}

const Windows = {
  Blink: {
    mouse: isMouse.deltaValidation.onWindows(BLINK_MOUSE_WHEEL_DELTA),
    trackPadDoubleSwipe: isTrackPad.deltaValidation.onWindows(BLINK_MOUSE_WHEEL_DELTA),
  },
  Gecko: {
    mouse: isMouse.intDefault,
    trackPadDoubleSwipe: isTrackPad.floatDefault,
  },
}

/**
 * ### MacOS 브라우저 엔진별 WheelEvent delta값 차이
 * - Shift를 누르고 Mouse 스크롤 동작 시 deltaY가 deltaX로 반전됨.
 * - Shift 키를 누른 상태에서는 확대, 이동 동작이 되지 않도록 차단.(추후 수정 가능성 있음)
 *
 * #### 1. Blink: Chrome, Microsoft Edge, Naver Whale, Opera 등
 *   - Mouse 스크롤: float
 *   - TrackPad 스와이프: int
 *
 * #### 2. WebKit: Safari
 *   - Mouse 스크롤: float
 *   - TrackPad 스와이프: int
 *     - 트랙패드 스와이프를 빠르게 하는 경우, e.ctrlKey = false이고 delta 값이 float인 경우가 있음.
 *
 * #### 3. Gecko: Firefox
 *   - Mouse 스크롤: int
 *   - TrackPad 스와이프: int
 */
const Mac = {
  Blink: {
    mouse: isMouse.floatDefault,
    trackPadDoubleSwipe: isTrackPad.intDefault,
  },
  WebKit: {
    mouse: isMouse.floatDefault,
    /** FIXME:ksh: 트랙패드 스와이프를 빠르게 하는 경우, e.ctrlKey = false이고 delta 값이 float인 경우가 있음. - 2024.04.29 */
    trackPadDoubleSwipe: isTrackPad.intDefault,
  },
  Gecko: {
    mouse: isMouse.deltaValidation.onMac(GECKO_MOUSE_WHEEL_DELTA),
    /**
     * 트랙패드 스와이프 중에 마우스 휠 동작 시 캔버스 이동이 되는 문제가 있어
     * delta 값이 `GECKO_MOUSE_WHEEL_DELTA`의 배수인지 여부를 추가로 체크함.
     * -> 트랙패드 스와이프로 인한 Wheel 이벤트가 "종료되기 전"에 발생하는 최초의 마우스 휠 동작은 실행되지 않음.
     * */
    trackPadDoubleSwipe: isTrackPad.deltaValidation.onMac(GECKO_MOUSE_WHEEL_DELTA),
  },
}

const Linux = {}

export const determinants = {
  'Mac OS': Mac,
  Windows,
  /* Linux, */
}
