import BaseApi from '@/api/Base/BaseApi'

import {
  CommonDashboardResponse,
  EditDashboardPayload,
  EditDashboardResponse,
  GetDashboardInfoResponse,
  GetDashboardListResponse,
  GetQueryListPayload,
  GetQueryListResponse,
  QueryItem,
  SaveQueryPayload
} from '@/api/Dashboard/types'

const COUNT_PER_PAGE = 20

const DEFAULT_GET_QUERY_LIST_PAYLOAD = {
  page: 0,
  itemsPerPage: COUNT_PER_PAGE,
  sortKey: 'name',
  order: 'asc',
}

const ROOT = '/dashboard'
const QUERY_ROOT = `${ROOT}/query`

export default class DashboardApi extends BaseApi {
  getQueryList(params?: GetQueryListPayload) {
    const payload = { DEFAULT_GET_QUERY_LIST_PAYLOAD, ...params }
    const { page, itemsPerPage, sortKey, order } = payload

    return this.agent.get<GetQueryListResponse>(`${QUERY_ROOT}/list`, {
      params: {
        page,
        num_per_page: itemsPerPage,
        sort_key: sortKey,
        sort_dir: order,
      },
    })
  }

  // TODO fix return type
  saveQuery(payload: SaveQueryPayload) {
    return this.agent.post<any>(`${QUERY_ROOT}`, { ...payload, pipeline: JSON.stringify(payload.pipeline) })
  }

  editQueryInfo(queryId: string, payload: SaveQueryPayload) {
    return this.agent.put<any>(`${QUERY_ROOT}/${queryId}`, { ...payload, pipeline: JSON.stringify(payload.pipeline) })
  }

  getQueryDetail(queryId: string) {
    return this.agent.get<QueryItem>(`${QUERY_ROOT}/${queryId}`)
  }

  executeQuery(queryId: string, collectionId: string, parameters: { [key: string]: any }) {
    return this.agent.post<any>(`${QUERY_ROOT}/${queryId}/execute`, parameters, { params: { datasource_id: collectionId } })
  }

  createDashboard(name: string) {
    return this.agent.post<CommonDashboardResponse>(`${ROOT}`, { name })
  }

  getDashboardList() {
    return this.agent.get<GetDashboardListResponse>(`${ROOT}/list`)
  }

  getDashboardDetail(dashboardId: string) {
    return this.agent.get<GetDashboardInfoResponse>(`${ROOT}/${dashboardId}`)
  }

  deleteDashboard(dashboardId: string) {
    return this.agent.delete(`${ROOT}/${dashboardId}`)
  }

  // TODO: change response type
  editDashboard(dashboardId: string, payload: EditDashboardPayload) {
    return this.agent.put<EditDashboardResponse>(`${ROOT}/${dashboardId}`, payload)
  }
}
