import { useEffect, useReducer, useRef } from 'react'

const INITIAL_MODIFIER_KEYS = { shiftKey: false, ctrlKey: false, metaKey: false }
const ELEMENT_TO_SUPPRESS_RECORD = new Set(['INPUT', 'TEXTAREA', 'SELECT', 'OPTION'])

interface ModifierKeysParams {
  enabled?: boolean
  /** keydown, keyup 이벤트가 발생할 때마다 컴포넌트를 재렌더링할지 여부.(기본값은 `false`) */
  forceRerender?: boolean
  /** Shift 키를 눌렀을 때 실행할 콜백함수. 컴포넌트 마운트 시에 생성된 콜백 함수만 사용되므로 주의. */
  onShiftDown?: () => void
  onShiftUp?: () => void
}

export const useModifierKeys = (param?: ModifierKeysParams) => {
  const { enabled = true, forceRerender = false, onShiftDown, onShiftUp } = param ?? {}

  const [, forceUpdate] = useReducer((x) => x + 1, 0)
  const modifierKeys = useRef(INITIAL_MODIFIER_KEYS)

  const setKeys = (e: KeyboardEvent) => {
    modifierKeys.current = {
      shiftKey: e.shiftKey,
      ctrlKey: e.ctrlKey,
      metaKey: e.metaKey,
    }
  }

  const resetKeys = () => {
    modifierKeys.current = INITIAL_MODIFIER_KEYS
  }

  useEffect(() => {
    const recordModifierKeys = (e: KeyboardEvent) => {
      const activeElement = document.activeElement?.tagName ?? ''
      const isPrevShiftKey = modifierKeys.current.shiftKey

      if (!enabled) {
        return
      }
      if (ELEMENT_TO_SUPPRESS_RECORD.has(activeElement)) {
        resetKeys()
        return
      }
      setKeys(e)

      if (e.shiftKey) {
        onShiftDown?.()
      }
      if (isPrevShiftKey && !e.shiftKey) {
        onShiftUp?.()
      }
      if (forceRerender) {
        forceUpdate()
      }
    }

    window.addEventListener('keydown', recordModifierKeys)
    window.addEventListener('keyup', recordModifierKeys)
    return () => {
      window.removeEventListener('keydown', recordModifierKeys)
      window.removeEventListener('keyup', recordModifierKeys)
    }
  }, [enabled, forceRerender])

  const isCtrlKeyPressed = () => {
    const platform = navigator.userAgentData?.platform ?? navigator.platform
    const isMacOs = platform.toUpperCase().indexOf('MAC') >= 0

    return isMacOs ? modifierKeys.current.metaKey : modifierKeys.current.ctrlKey
  }

  return {
    isShiftKey: () => modifierKeys.current.shiftKey,
    isCtrlKey: isCtrlKeyPressed,
  }
}
