import BaseApi from '@/api/Base/BaseApi'
import {
  CommonStorageItem,
  GetDownloadTokenResponse,
  GetStorageListPayload,
  GetStorageListResponse,
  StorageItem,
  UploadFilesResponse
} from '@/api/Storage/types'

const COUNT_PER_PAGE = 20

const ROOT = '/storage'

export default class StorageApi extends BaseApi {
  #rootDirId = ''

  // FIXME: 언제 root ID를 셋할지 고민이 필요함, root dir을 굳이 이렇게 가지고 있어야 하나 싶기는 한데, 최상위 루트 id 지정이 꼭 필요하다고 하면 그때 다시 고민
  setRootDirId(rootId: string) {
    this.#rootDirId = rootId
  }

  getRootDirId() {
    return this.#rootDirId
  }

  // async getRootDirectories(payload: GetRootListPayload) {
  //
  // }

  getStorageRootItemList(payload: GetStorageListPayload) {
    const {
      type,
      keyword,
      searchRecursive = true,
      page = 0,
      itemsPerPage = COUNT_PER_PAGE,
      sortKey = 'type',
      order,
    } = payload
    return this.agent.get<GetStorageListResponse>(`${ROOT}/list`, {
      params: {
        type,
        search: keyword || undefined,
        page,
        num_per_page: itemsPerPage,
        // sort_key: sortKey,
        // sort_dir: order,
        // search_recursive: searchRecursive,
        sort_key: 'type,createdAt',
        sort_dir: `asc,${order}`,
        search_recursive: !!keyword,
      },
    })
  }

  /** 편의를 위해서 루트 List, 폴더 하위 리스트 api 분리 */
  getStorageItemList(dirId: string, payload: GetStorageListPayload) {
    const {
      type,
      keyword,
      searchRecursive = true,
      page = 0,
      itemsPerPage = COUNT_PER_PAGE,
      sortKey = 'type',
      order,
    } = payload
    return this.agent.get<GetStorageListResponse>(`${ROOT}/list/${dirId}`, {
      params: {
        type,
        search: keyword || undefined,
        page,
        num_per_page: itemsPerPage,
        // sort_key: sortKey,
        // sort_dir: order,
        // search_recursive: searchRecursive,
        sort_key: 'type,createdAt',
        sort_dir: `asc,${order}`,
        search_recursive: !!keyword,
      },
    })
  }

  createDirectory({ path, parentId }: { path: string, parentId?: string }) {
    return this.agent.post<CommonStorageItem>(`${ROOT}/directory/create`, { path, parent_id: parentId || undefined })
  }

  getStorageItem(itemId: string) {
    return this.agent.get<StorageItem>(`${ROOT}/${itemId}`)
  }

  deleteStorageItem(itemId: string) {
    return this.agent.delete(`${ROOT}/${itemId}`)
  }

  /** 지금 당장은 기획이 없기 때문에 추후 기획이 확인 되면 필요한 api */
  copyStorageItem(itemId: string, destDirId: string) {
    return this.agent.post(`${ROOT}/${itemId}`, { dest_storage_item_id: destDirId })
  }

  // FIXME: response type
  getDownloadToken(itemId: string) {
    return this.agent.get<GetDownloadTokenResponse>(`${ROOT}/download-token/${itemId}`)
  }

  downloadItem(itemId: string, token: string) {
    return this.agent.get(`${ROOT}/download/${itemId}`, {
      params: {
        token,
      },
    })
  }

  /** api에서 directory id가 없으면 자동으로 ROOT에 업로드 시킴 */
  uploadFiles(files: File[], directoryId?: string) {
    const formData = new FormData()
    files.forEach((file) => {
      formData.append('file', file)
    })
    if (directoryId) {
      formData.append('storage_item_id', directoryId)
    }
    return this.agent.post<UploadFilesResponse>(`${ROOT}/file/direct-upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }

  moveStorageItem(itemId: string, destDirId?: string) {
    const destItemId = destDirId || this.#rootDirId
    return this.agent.put(`${ROOT}/move/${itemId}`, { dest_storage_item_id: destItemId })
  }

  renameStorageItem(itemId: string, newName: string) {
    return this.agent.put<CommonStorageItem & { fileMetaId?: string }>(`${ROOT}/rename/${itemId}`, { new_name: newName })
  }
}
