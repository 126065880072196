import { useEffect } from 'react'

import { isNonEmptyObject, isString } from '@ahha/utils/@types/typeChecks'
import { useReactive } from '@ahha/utils/hooks/useEffectEvent'

interface EventOptions {
  capture?: boolean
  once?: boolean
  passive?: boolean
}

type EventHandler<K extends keyof WindowEventMap> = (this: Window, ev: WindowEventMap[K]) => void

interface UseGlobalEventParams<K extends keyof WindowEventMap> extends EventOptions {
  type: K
  handler: EventHandler<K>
  enabled?: boolean
}

export function useGlobalEvent<K extends keyof WindowEventMap>(
  type: K,
  handler: EventHandler<K>
): void
export function useGlobalEvent<K extends keyof WindowEventMap>(
  options: UseGlobalEventParams<K>
): void

export function useGlobalEvent<K extends keyof WindowEventMap>(
  p0: K | UseGlobalEventParams<K>,
  p1?: EventHandler<K>
) {
  const { type, handler, enabled = true, ...options } = distributeParams(p0, p1)
  const getEventData = useReactive({
    type,
    options: isNonEmptyObject(options) ? options : undefined,
    handler,
  })

  useEffect(() => {
    const { type, options } = getEventData()
    const listener: EventHandler<K> = (ev) => {
      getEventData().handler.call(window, ev)
    }

    if (enabled) {
      window.addEventListener(type, listener, options)
    }
    return () => {
      window.removeEventListener(type, listener, options)
    }
  }, [enabled])
}

const distributeParams = <K extends keyof WindowEventMap>(
  p0: K | UseGlobalEventParams<K>,
  p1?: EventHandler<K>
) => {
  if (isString(p0)) {
    if (!p1) {
      throw new Error('useGlobalEvent: invalid arguments. Event handler is undefined.')
    }
    return { type: p0, handler: p1, enabled: true }
  }
  return p0
}
