import { io, Socket } from 'socket.io-client'
import { CommonLabelingHandlerPayload } from './types'

class SocketClient {
  private socket: Socket

  constructor(serverUrl: string) {
    this.socket = io(serverUrl)
  }

  connect(): void {
    this.socket.connect()
  }

  disconnect(): void {
    this.socket.disconnect()
  }

  emitIntoLabeling(payload: CommonLabelingHandlerPayload) {
    this.socket.emit('into_labeling', payload)
  }

  emitMouseMove(payload: CommonLabelingHandlerPayload) {
    this.socket.emit('mouse_move', payload)
  }

  emitCreateObject(payload: CommonLabelingHandlerPayload) {
    this.socket.emit('create_object', payload)
  }

  emitSelectObject(payload: CommonLabelingHandlerPayload) {
    this.socket.emit('select_object', payload)
  }

  emitDrawObject(payload: CommonLabelingHandlerPayload) {
    this.socket.emit('draw_object', payload)
  }

  emitEditObject(payload: CommonLabelingHandlerPayload) {
    this.socket.emit('edit_object', payload)
  }

  emitDeleteObject(payload: CommonLabelingHandlerPayload) {
    this.socket.emit('delete_object', payload)
  }

  emitChangeObjectClass(payload: CommonLabelingHandlerPayload) {
    this.socket.emit('change_object_class', payload)
  }

  emitFinishLabelingItem(payload: CommonLabelingHandlerPayload) {
    this.socket.emit('finish_labeling_item', payload)
  }

  emitRequestReview(payload: CommonLabelingHandlerPayload) {
    this.socket.emit('request_review', payload)
  }

  emitCancelRequestReview(payload: CommonLabelingHandlerPayload) {
    this.socket.emit('cancel_request_review', payload)
  }

  emitLeaveLabeling(payload: CommonLabelingHandlerPayload) {
    this.socket.emit('leave_labeling', payload)
  }

  emitHandleUndo(payload: CommonLabelingHandlerPayload) {
    this.socket.emit('handle_undo', payload)
  }

  emitHandleRedo(payload: CommonLabelingHandlerPayload) {
    this.socket.emit('handle_redo', payload)
  }

  emitCreateReviewObject(payload: CommonLabelingHandlerPayload) {
    this.socket.emit('create_review_object', payload)
  }

  emitAddComment(payload: CommonLabelingHandlerPayload) {
    this.socket.emit('add_comment', payload)
  }

  emitEditComment(payload: CommonLabelingHandlerPayload) {
    this.socket.emit('edit_comment', payload)
  }

  emitDeleteComment(payload: CommonLabelingHandlerPayload) {
    this.socket.emit('delete_comment', payload)
  }

  emitResolveComment(payload: CommonLabelingHandlerPayload) {
    this.socket.emit('resolve_comment', payload)
  }

  // receive events
  onIntoNewOne(callback: (data: CommonLabelingHandlerPayload) => void): void {
    this.socket.on('into_labeling', callback)
  }

  onUpdateMousePosition(callback: (data: { userName: string, userId: string, position: any }) => void): void {
    this.socket.on('update_mouse', callback)
  }

  onObjectCreated(callback: (data: CommonLabelingHandlerPayload) => void): void {
    this.socket.on('object_created', callback)
  }

  onObjectSelected(callback: (data: CommonLabelingHandlerPayload) => void): void {
    this.socket.on('object_selected', callback)
  }

  onObjectDrawing(callback: (data: CommonLabelingHandlerPayload) => void): void {
    this.socket.on('object_drawing', callback)
  }

  onObjectEdited(callback: (data: CommonLabelingHandlerPayload) => void): void {
    this.socket.on('object_edited', callback)
  }

  onObjectDeleted(callback: (data: CommonLabelingHandlerPayload) => void): void {
    this.socket.on('object_deleted', callback)
  }

  onObjectClassChanged(callback: (data: CommonLabelingHandlerPayload) => void): void {
    this.socket.on('object_class_changed', callback)
  }

  onLabelingItemFinished(callback: (data: CommonLabelingHandlerPayload) => void): void {
    this.socket.on('labeling_item_finished', callback)
  }

  onReviewRequested(callback: (data: CommonLabelingHandlerPayload) => void): void {
    this.socket.on('review_requested', callback)
  }

  onReviewRequestCanceled(callback: (data: CommonLabelingHandlerPayload) => void): void {
    this.socket.on('review_request_canceled', callback)
  }

  onLeaveSomeOne(callback: (data: CommonLabelingHandlerPayload) => void): void {
    this.socket.on('leave_labeling', callback)
  }

  onUndo(callback: (data: CommonLabelingHandlerPayload) => void): void {
    this.socket.on('undo', callback)
  }

  onRedo(callback: (data: CommonLabelingHandlerPayload) => void): void {
    this.socket.on('redo', callback)
  }

  onReviewObjectCreated(callback: (data: CommonLabelingHandlerPayload) => void): void {
    this.socket.on('review_object_created', callback)
  }

  onCommentAdded(callback: (data: CommonLabelingHandlerPayload) => void): void {
    this.socket.on('comment_added', callback)
  }

  onCommentEdited(callback: (data: CommonLabelingHandlerPayload) => void): void {
    this.socket.on('comment_edited', callback)
  }

  onCommentDeleted(callback: (data: CommonLabelingHandlerPayload) => void): void {
    this.socket.on('comment_deleted', callback)
  }

  onCommentResolved(callback: (data: CommonLabelingHandlerPayload) => void): void {
    this.socket.on('comment_resolved', callback)
  }

  on(event: string, callback: (data: any) => void): void {
    this.socket.on(event, callback)
  }

  emit(event: string, data?: any) {
    this.socket.emit(event, data)
  }

  off(event: string, callback: (data: any) => void): void {
    this.socket.off(event, callback)
  }
}

export default SocketClient
