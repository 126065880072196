/* eslint-disable consistent-return */
import { isElementOf } from '@ahha/utils/@types/typeChecks'
import { useCallback, useEffect, useRef, useState } from 'react'

interface UseInfiniteScrollProps {
  onIntersect(): void;
  coolTime?: number;
  enabled?: boolean;
  options?: Omit<IntersectionObserverInit, 'root'>;
}

const DEFAULT_OPTIONS = { rootMargin: '100px' }

/**
 * @param onIntersect 관찰하는 요소와 겹칠 때 실행할 함수
 * @param coolTime onIntersecting이 실행되는 최소 시간 간격(ms)을 설정
 * @param initiateOnMount 컴포넌트 마운트 후 바로 무한 스크롤을 실행할지 여부 설정
 */
export function useInfiniteScroll<
  T extends Element = HTMLDivElement,
  R extends Element = HTMLDivElement
>({
  onIntersect,
  coolTime = 50,
  enabled = true,
  options = DEFAULT_OPTIONS,
}: UseInfiniteScrollProps) {
  const [isVisible, setIsVisible] = useState(0)

  const rootRef = useRef<R | null>()
  const targetRef = useRef<T>(null)
  const ioRef = useRef<IntersectionObserver | null>(null)

  const prevTime = useRef(0)

  const initialize = useCallback(() => {
    if (!enabled) {
      return
    }
    onIntersect()
  }, [enabled, onIntersect])

  const terminate = useCallback(() => {
    ioRef.current?.disconnect()
  }, [])

  const isTargetVisible = useCallback((rootBounds: DOMRectReadOnly | null) => {
    if (rootBounds && isElementOf<HTMLDivElement>(targetRef.current)) {
      const { bottom } = rootBounds
      const { offsetTop } = targetRef.current

      return offsetTop < bottom
    }
    return false
  }, [])

  useEffect(() => {
    if (!targetRef.current || !enabled) {
      return
    }
    const observerOptions = {
      ...DEFAULT_OPTIONS,
      ...options,
      root: rootRef.current,
    }
    const io = new IntersectionObserver(([{ isIntersecting, time, rootBounds }]) => {
      /** root 하단에서 intersection 이벤트가 무한히 발생하지 않도록 쿨타임 적용 */
      if (!isIntersecting || (time - prevTime.current < coolTime)) {
        return
      }
      onIntersect()
      prevTime.current = time
    }, observerOptions)

    io.observe(targetRef.current)
    ioRef.current = io

    return () => io.disconnect()
  }, [enabled, coolTime])

  useEffect(() => {}, [isVisible])

  /**
   * IntersectionObserver로 관찰하려는 요소와 root 요소를 연결.
   * @example
   * const App = () => {
   *  const { connect } = useInfiniteScroll<HTMLSpanElement>()
   *
   *   return (
   *     <div {...connect.parent()}>
   *       <span {...connect.target()} />
   *     </div>
   *   )
   * }
   * */
  const connect = {
    /**
     * 관찰 대상 요소의 부모 요소(root). 부모 요소는 `target` 요소를 포함하는 **스크롤 가능한 요소**여야함.
     * 그렇지 않으면 `option.rootMargin`이 적용되지 않음에 주의.
     * @see https://stackoverflow.com/a/58622945
     * */
    parent: () => ({ ref: rootRef }),
    /** IntersectionObserver의 관찰 대상 요소 */
    target: () => ({ ref: targetRef }),
  }

  return {
    connect,
    enabled,
    parentRef: rootRef,
    initialize,
    terminate,
  }
}
