import { call, put, takeLatest } from 'redux-saga/effects'
import { PayloadAction } from '@reduxjs/toolkit'
import { loginComplete, loginError, loginStart } from '@/stores/slices/authSlice'

function* loginStartSaga({ payload }: PayloadAction<{ userId: string, password?: string }>) {
  const { userId, password } = payload
  if (!userId || !password) {
    yield call(loginError)
    return
  }
  yield put(loginComplete({ authToken: 'faksdjflkajsl' }))
}

export default function* rootSaga() {
  yield takeLatest(loginStart, loginStartSaga)
}
