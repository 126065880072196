import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Layouts } from 'react-grid-layout'
import { get, map, mapValues, reject, maxBy, find } from 'lodash'
import { v4 as uuidv4 } from 'uuid'

import { DashboardConfigDataType, ContainerType, WidgetType } from '@/pages/DataCamp/Dashboard/types'
import { ContainerDirection } from '@/pages/DataCamp/Dashboard/const'

// The 'i' key in gridLayout must match its respective container's containerId
const DASHBOARD_CONFIG_DATA: DashboardConfigDataType = {
  // dashboardId: 'dashboard1',
  dashboardId: '',
  gridLayout: {
    lg: [
      { i: 'c1', x: 0, y: 0, w: 4, h: 10 },
      { i: 'c2', x: 4, y: 0, w: 4, h: 20 },
    ],
  },
  containers: [
    {
      containerId: 'c1',
      title: 'Charts 1',
      direction: ContainerDirection.HORIZONTAL,
    },
    {
      containerId: 'c2',
      title: 'Charts 2',
      direction: ContainerDirection.VERTICAL,
    },
  ],
}
const INITIAL_STATE: DashboardConfigDataType = {
  dashboardId: '',
  gridLayout: {},
  containers: [],
}

export const dashboardConfig = createSlice({
  name: 'dashboardConfig',
  initialState: INITIAL_STATE,
  reducers: {
    setDashboardData(state, action: PayloadAction<DashboardConfigDataType>) {
      return action.payload
    },
    setDashboardId(state, action: PayloadAction<string>) {
      return { ...state, dashboardId: action.payload }
    },
    setGridLayout(state, action: PayloadAction<Layouts>) {
      return { ...state, gridLayout: action.payload }
    },
    addNewContainer(state, action: PayloadAction<{ title: string, breakpoint: string }>) {
      const { title, breakpoint } = action.payload
      const randomId = uuidv4()
      // TODO: change breakpoint
      const maxY = get(maxBy(state.gridLayout[breakpoint], 'y'), 'y', 0)
      const newPosition = {
        i: randomId,
        x: 0,
        y: state.containers.length ? maxY + 1 : 0,
        w: 4,
        h: 10,
      }
      const newContainer: ContainerType = {
        containerId: randomId,
        title,
        direction: ContainerDirection.VERTICAL,
      }
      return {
        ...state,
        // TODO: change breakpoint
        gridLayout: {
          ...state.gridLayout,
          lg: state.gridLayout.lg?.concat(newPosition),
          md: state.gridLayout.md?.concat(newPosition),
          sm: state.gridLayout.sm?.concat(newPosition),
          xs: state.gridLayout.xs?.concat(newPosition),
          xxs: state.gridLayout.xxs?.concat(newPosition),
          [breakpoint]: state.gridLayout[breakpoint].concat(newPosition),
        },
        containers: state.containers.concat(newContainer),
      }
    },
    removeContainer(state, action: PayloadAction<string>) {
      return {
        ...state,
        gridLayout: mapValues(state.gridLayout, (value) => reject(value, { i: action.payload })),
        containers: reject(state.containers, { containerId: action.payload }),
      }
    },
    setContainerItemsOrder(state, action: PayloadAction<{ containerId: string, items: WidgetType[] }>) {
      const { containerId, items } = action.payload
      return {
        ...state,
        containers: map(state.containers, (container) => (
          container.containerId === containerId ? { ...container, items } : container
        )),
      }
    },
    updateContainerInfo(state, action: PayloadAction<{ containerId: string, data: ContainerType}>) {
      const { containerId, data } = action.payload
      return {
        ...state,
        containers: map(state.containers, (container) => (
          container.containerId === containerId ? data : container
        )),
      }
    },
  },
})

const containerSelector = (state: any) => state.dashboardConfig.containers
export const targetContainerSelector = (containerId: string | null) => createSelector(
  containerSelector,
  (containerList) => find(containerList, (container) => container.containerId === containerId)
)

export const {
  setDashboardData,
  setDashboardId,
  setGridLayout,
  addNewContainer,
  removeContainer,
  setContainerItemsOrder,
  updateContainerInfo,
} = dashboardConfig.actions

export default dashboardConfig.reducer
