export const WidgetPreset = {
  TEXT: 1,
  IMAGE: 2,
  LINE_CHART: 3,
  BAR_CHART: 4,
  PIE_CHART: 5,
  TABLE: 6,
  CONTAINER: 7,
} as const

export const ContainerDirection = {
  VERTICAL: 1,
  HORIZONTAL: 2,
} as const
