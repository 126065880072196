export const ROI_LAYER_TYPES = {
  roi: 'ROI',
  masking: 'masking',
} as const

/** ROI 캔버스의 최상단에 그릴 Layer의 순서를 정의. */
export const ARRANGE_BASIS = {
  frontIs: {
    roi: [ROI_LAYER_TYPES.masking, ROI_LAYER_TYPES.roi],
    masking: [ROI_LAYER_TYPES.roi, ROI_LAYER_TYPES.masking],
  },
} as const

export const FOCUSED_MASKING_ID = {
  inViewMode: {
    none: '',
  },
  /**
   * view 모드일 때는 빈 문자열, drawing 모드일 때는 빈 문자열이 아닌 문자열로 두 상태를 간단히 구분 짓기 위해 `null` 대신 더미 문자 사용함.
   * drawing 모드일 때, (1) 어떤 오브젝트도 포커스되지 않은 상태(`"empty"`), (2) 포커스된 오브젝트가 존재하는 상태(`"bbddfba3-42a2-4a5a-b192-7b015a9951d6"`)가 존재함.
   * 빈 문자열인지 아닌지로 drawing 모드를 체크할 수 있기 때문에 이 방식 사용함. `null`로 대체 가능한지는 테스트 필요.
  */
  inDrawingMode: {
    none: 'empty',
  },
}

export const FOCUSED_ROI_ID = {
  inViewMode: {
    none: '',
  },
  inDrawingMode: {
    none: null,
  },
}
