import styled from 'styled-components'

import { NotificationType, ToastType } from '@ahha/utils/@types/types'

export const NotificationListWrapper = styled.ul`
  display: flex;
  flex-direction: column-reverse;
  gap: 0.5rem;
  position: fixed;
  z-index: 9999;
  right: ${({ theme }) => theme.variables.pxToRem(20)};
  top: ${({ theme }) => theme.variables.pxToRem(100)};

  .notificationClear {
    animation: noti-slide-out 0.3s ease-in-out forwards;
  }

  @keyframes noti-slide-in {
    0% {
      opacity: 0;
      transform: translateX(100%);
    }
    100% {
      opacity: 1;
      transform: translateX(0%);
    }
  }

  @keyframes noti-slide-out {
    0% {
      opacity: 1;
      transform: translateX(0%);
    }
    100% {
      opacity: 0;
      transform: translateX(100%);
    }
  }
`

export const StyledNotiItem = styled.li<NotificationType>``

export const StyledToastItem = styled.li<ToastType>`
  width: ${({ theme }) => theme.variables.pxToRem(365)};
  display: flex;
  gap: 0.25rem;
  padding: 0.25rem 0.625rem;
  border-radius: 0.25rem;
  box-shadow: ${({ theme }) => `0px 0px 1px 0px ${theme.colors.dim}, 0px 1px 4px 0px ${theme.colors.dim}, 0px 2px 8px 0px ${theme.colors.dim}`};
  background-color: ${({ theme }) => theme.colors.bg.primary};
  color: ${({ theme }) => theme.colors.text.inverse};
  align-items: ${({ variant }) => (variant === 'multiLine' ? 'top' : 'center')};
  border: ${({ theme }) => `1px solid ${theme.colors.border.border2}`};
  animation: noti-slide-in 0.3s ease-in-out forwards;

  > div {
    width: calc(100% - 3.5rem);
    display: flex;
    gap: 0.25rem;
    flex-direction: ${({ variant }) => (variant === 'multiLine' ? 'column' : 'row')};
    // align-items: ${({ variant }) => (variant === 'multiLine' ? 'column' : 'center')};

    h5 {
      font-weight: 700;
      font-size: 0.75rem;
    }

    span {
      font-size: 0.75rem;
      ${({ variant }) => variant === 'singleLine' && `
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      `};
    }
  }

  > svg {
    width: 1rem;
    height: 1rem;
  }

  svg > path {
    stroke: ${({ theme }) => theme.colors.icon.inverse};
  }

  > button {
    &:hover {
      background-color: transparent !important;
    }
  }

  ${({ toastType, theme }) => toastType === 'secondary' && `
    background-color: ${theme.colors.page.primary};
    color: ${theme.colors.text.primary};
    svg > path {
      stroke: ${theme.colors.icon.primary};
    }
  `}

  ${({ toastType, theme }) => toastType === 'error' && `
    background-color: ${theme.colors.bg.error};
  `}
`
