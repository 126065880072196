import { groupBy } from 'lodash'
import { MaskingRegion, Point2D, RearrangeBasis, RoiInitializer, RoiRegion, RoiState } from './types'

export const RECT_ROI_SCALABLE_PROPS = [
  ['x'],
  ['y'],
  ['width'],
  ['height'],
] as const

export const ELLIPSE_ROI_SCALABLE_PROPS = [
  ['cx'],
  ['cy'],
  ['rx'],
  ['ry'],
] as const

export const MASKING_SCALABLE_PROPS = [
  ['points', (p: Point2D[], m: number) => p.map(([x, y]) => [x * m, y * m] as Point2D)],
] as const

/** @deprecated 타입 가드를 위해 `RECT_ROI_SCALABLE_PROPS`, `ELLIPSE_ROI_SCALABLE_PROPS`, `MASKING_SCALABLE_PROPS` 사용할 것 */
export const SCALABLE_PROPS = [
  ['x'],
  ['y'],
  ['width'],
  ['height'],
  ['points', (p: Point2D[], m: number) => p.map(([x, y]) => [x * m, y * m])],
] as const

const INITIAL_ROI_SIZE = 0.8

export const roiInitializer = ({ id, type, width, height, scale }: RoiInitializer): RoiRegion => {
  const initialPosition = {
    x: ((1 - Math.sqrt(INITIAL_ROI_SIZE)) / 2) * width,
    y: ((1 - Math.sqrt(INITIAL_ROI_SIZE)) / 2) * height,
  }
  const initialSize = {
    width: width * Math.sqrt(INITIAL_ROI_SIZE),
    height: height * Math.sqrt(INITIAL_ROI_SIZE),
  }

  if (type === 'ellipse') {
    return {
      id,
      variant: 'ROI',
      type,
      cx: initialPosition.x + initialSize.width / 2,
      cy: initialPosition.y + initialSize.height / 2,
      rx: initialSize.width / 2,
      ry: initialSize.height / 2,
      stroke: '#fff',
      rotation: 0,
    }
  }

  // x, y, width, height: scale = 1일 때의 값
  return {
    id,
    variant: 'ROI',
    type,
    x: initialPosition.x / scale,
    y: initialPosition.y / scale,
    width: initialSize.width / scale,
    height: initialSize.height / scale,
    stroke: '#fff',
    rotation: 0,
  }
}

export const maskingInitializer = (id: string): MaskingRegion => ({
  id,
  variant: 'masking',
  fill: '#13161D99',
  points: [],
  isClosed: false,
})

export const rearrange = (
  regions: (RoiRegion | MaskingRegion)[],
  orders: RearrangeBasis
) => {
  const groups = groupBy(regions, (e) => e.variant)

  return orders.map((variant) => groups[variant] ?? []).flat()
}

export const getImageScale = (state: RoiState) => {
  const { editorWidth, naturalWidth } = state.imageSize

  return naturalWidth / (editorWidth || 1)
}
