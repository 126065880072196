import React from 'react'
import { map } from 'lodash'

import NotificationItem from '@ahha/stableComponents/Notification/NotificationItem'
import { NotificationListWrapper } from '@ahha/stableComponents/Notification/styled'
import { useGlobalNotification } from '@/context/notificationContext'

const Notification = () => {
  const { notifications } = useGlobalNotification()

  if (!notifications.length) {
    return null
  }

  return (
    <NotificationListWrapper>
      {map(notifications, (n) => (
        <NotificationItem key={n.id} {...n} />
      ))}
    </NotificationListWrapper>
  )
}
export default Notification
