import { ExcludeEmpty, WithEmpty } from '@ahha/utils/@types/typeUtils'
import dayjs from 'dayjs'
import { ForwardedRef, MutableRefObject, ReactElement } from 'react'

export const isPlainObject = <T extends Record<string, unknown>>(value: unknown): value is T => value?.constructor === Object

/**
 * 객체 구조 분해 할당으로 `value`의 타입이 `SomeType | {}`이 될 때 `{}` 타입 제거
 * @example
 * const { type, ...rest } = props ?? {} // rest의 타입은 SomeType | {}
 *
 * */
export const isNonEmptyObject = <T extends WithEmpty<Record<any, any>>>(value: T): value is ExcludeEmpty<T> => isPlainObject(value) && Object.keys(value).length > 0

export const isFunction = (value: unknown): value is (...args: any[]) => any => typeof value === 'function'

export const isArray = <T>(value: unknown): value is T[] => Array.isArray(value)

export const isReadonlyArray = <T>(value: T | readonly T[]): value is readonly T[] => Array.isArray(value)

export const isObjectArray = <T extends Record<string, unknown>>(value: unknown): value is T[] => isArray(value) && isPlainObject(value[0])

export const isNonEmptyArray = <T>(value: T[] | undefined): value is T[] => isArray(value) && value.length > 0

export const isString = (value: unknown): value is string => typeof value === 'string'

export const isNumber = (value: unknown): value is number => typeof value === 'number' && !Number.isNaN(value)

export const isDate = (value: unknown): value is Date => value instanceof Date

export const isDateString = (value: unknown): value is Date | string => isDate(value) || (isString(value) && dayjs(value).isValid())

export const isNullish = <T>(value: T | null | undefined): value is null | undefined => value === null || value === undefined

export const isNotNullish = <T>(value: T): value is Exclude<T, null | undefined> => value !== null && value !== undefined

export const isPromise = <T>(value: unknown): value is Promise<T> => value instanceof Promise

export const isBoolean = (value: unknown): value is boolean => typeof value === 'boolean'

export const isSymbol = (value: unknown): value is symbol => typeof value === 'symbol'

export const isKeyOf = <T extends Record<string, unknown>>(key: PropertyKey | undefined, obj: T): key is keyof T => {
  if (!key) {
    return false
  }
  return key in obj
}

export const isFiberNode = (node: unknown): node is ReactElement => isPlainObject(node) && '$$typeof' in node && node.$$typeof === Symbol.for('react.element')

export const isJsxElement = (element: unknown): element is JSX.Element => isPlainObject(element) && element.type !== undefined

export const isElement = (value: unknown): value is HTMLElement => value instanceof HTMLElement || value instanceof SVGElement

export const isButtonElement = (value: unknown): value is HTMLButtonElement => value instanceof HTMLButtonElement

export const isInputElement = (value: unknown): value is HTMLInputElement => value instanceof HTMLInputElement

export const isElementOf = <T extends HTMLElement>(value: unknown): value is T => value instanceof HTMLElement

export const isRefObject = <T>(value: unknown): value is MutableRefObject<T> => isPlainObject<{ current: T | null }>(value)

export const isNonEmptyRefObject = <T extends HTMLElement>(value: ForwardedRef<T>)
  : value is MutableRefObject<T> => isRefObject(value) && !!value.current

export const isSet = <T>(value: unknown): value is Set<T> => value instanceof Set

export const isFile = (value: unknown): value is File => value instanceof File

export const isFileSystemHandle = (value: unknown): value is FileSystemHandle => value instanceof FileSystemHandle

/**
 * readonly Array에 대해 임의의 값 `x`가 배열에 포함되어 있는지 확인하는 타입 가드.
 */
export const isIn = <T, >(values: readonly T[], x: any): x is T => values.includes(x)

export const hasProperty = <T, K extends PropertyKey>(obj: T, key: K): obj is Exclude<T, null | undefined> & { [P in K]: P extends keyof T ? T[P] : never } => isPlainObject(obj) && key in obj
