import { Draft } from '@reduxjs/toolkit'
import { v4 as uuidv4, validate as uuidValidate } from 'uuid'
import { set } from 'lodash'

import { Comment, LabelingNodeItem, LabelingObject, ObjectToolType } from '@/api/LabelingNode/types'
import { LabelingObjectData, LabelingState, ThunkConditionAPI, Comment as StoreComment } from '@/stores/slices/Labeling/types'
import { AnnotationType } from '@/pages/Labeling/types'
import { calcOriginalPointsForDB, calcRotatedPointsForCanvas } from '@/pages/Labeling/utils/canvasUtils'

export const serializeObject = (o: LabelingObjectData, itemId: string, tagOrder?: number) => {
  const common = {
    labelingItemId: itemId,
    labelingObjectId: o.id || '',
    labelingClassId: o.label || '',
    zOrder: 0,
  }
  if (o.id && uuidValidate(o.id)) {
    set(common, 'labelingObjectId', undefined)
  }
  const meta = {
    createdAt: o.createdAt,
    modifiedAt: o.modifiedAt,
    tagOrder,
  }

  switch (o.t) {
    case 'box': {
      /**
       * konva에서 사각형의 좌표 `x`, `y`는 `rotation`이 적용된 top-left 점이지만
       * backend에서의 `x`, `y`는 `rotation`이 0일 때의 top-left 점으로 다르기 때문에
       * `rotation`이 0일 때의 top-left 점으로 변환해 API 호출.
       */
      const {
        xtl, ytl, xbr, ybr,
      } = calcOriginalPointsForDB(o.x, o.y, o.width, o.height, o.rotation)
      return {
        ...common,
        // type: 2,
        type: ObjectToolType.RECTANGLE as keyof typeof ObjectToolType,
        isFixedRatio: o.isFixedRatio,
        // isPathEditable: o.isPathEditable || false,
        region: {
          xtl,
          ytl,
          xbr,
          ybr,
          rotation: o.rotation,
        },
        metadata: { ...meta },
      }
    }
    case 'ellipse': {
      return {
        ...common,
        type: ObjectToolType.ELLIPSE as keyof typeof ObjectToolType,
        isFixedRatio: o.isFixedRatio,
        // isPathEditable: o.isPathEditable,
        region: {
          cx: o.cx,
          cy: o.cy,
          rx: o.rx,
          ry: o.ry,
          rotation: o.rotation,
        },
        metadata: { ...meta },
      }
    }
    case 'polygon':
      return {
        ...common,
        type: ObjectToolType.POLYGON as keyof typeof ObjectToolType,
        region: { points: o.points.map((p: { x: any; y: any; }) => `${p.x},${p.y}`).join(';') },
        // isFixedRatio: o.isFixedRatio,
        isPathEditable: o.isPathEditable,
        metadata: { ...meta },
      }
    case 'tag':
      return {
        ...common,
        type: ObjectToolType.TAG,
      }
    default:
      console.error('cannot reach here - invalid object annotation type')
      return {
        labelingObjectId: '',
        labelingClassId: '',
        labelingNodeId: '',
        labelingItemId: '',
        isFixedRatio: false,
        isPathEditable: false,
        metadata: undefined,
        region: undefined,
        type: ObjectToolType.RECTANGLE as keyof typeof ObjectToolType,
        zOrder: 0,
      }
  }
}

export const transformAnnotObjects = (data: LabelingObject[]): LabelingObjectData[] => (data ?? []).map((o) => {
  const common = {
    id: o._id || uuidv4(),
    label: o?.labelingClass?._id || '', // TODO: class id만 필요한게 맞나?
    createdAt: o.metadata?.createdAt || new Date(),
    modifiedAt: o.metadata?.modifiedAt || new Date(),
    name: o.metadata?.tagOrder,
  }

  switch (o.type) {
    case ObjectToolType.RECTANGLE: {
      const t: AnnotationType = 'box'
      // konva 좌표계에 맞는 형식으로 변환해줌.
      // 회전하지 않은 tl(xtl, ytl), br(xbr, ybr)점을 사용하여
      // 회전한 점(x, y)과 사각형의 너비(width)와 높이(height)을 반환함.
      const {
        x: xtl,
        y: ytl,
        width: w,
        height: h,
      } = calcRotatedPointsForCanvas(o.region?.xtl, o.region?.ytl, o.region?.xbr, o.region?.ybr, o.region?.rotation)
      return {
        ...common,
        t,
        x: xtl,
        y: ytl,
        width: w,
        height: h,
        rotation: o.region?.rotation,
        isFixedRatio: o.isFixedRatio || false,
      }
    }
    case ObjectToolType.POLYGON: {
      const t: AnnotationType = 'polygon'
      return {
        ...common,
        t,
        points: o.region?.points?.split(';').map((p: string) => {
          const c = p.split(',')
          return {
            x: parseFloat(c[0]),
            y: parseFloat(c[1]),
          }
        }),
        isPathEditable: o.isPathEditable || false,
      }
    }
    case ObjectToolType.ELLIPSE: {
      const t: AnnotationType = 'ellipse'
      return {
        ...common,
        t,
        cx: o.region?.cx,
        cy: o.region?.cy,
        rx: o.region?.rx,
        ry: o.region?.ry,
        isFixedRatio: o.region?.isFixedRatio || false,
        rotation: o.region?.rotation,
      }
    }
    case ObjectToolType.TAG: {
      const t: AnnotationType = 'tag'
      return {
        ...common,
        ...o,
        t,
      }
    }
    default: {
      const { region = {}, ...others } = o
      return {
        ...others,
        id: o._id || uuidv4(),
        ...region,
      }
    }
  }
}) ?? []

const transformToClientComments = (objects: LabelingObject[] | undefined) => [...(objects ?? [])]
  .reduce((a, c) => {
    if (c.type !== ObjectToolType.COMMENT) {
      return a
    }
    const { _id, region = {}, isConfirm } = c

    a.push({
      id: _id,
      serverId: _id,
      resolved: isConfirm ?? false,
      ...region,
      comments: transformSubComments(c.comments),
    })
    return a
  }, [] as StoreComment[])

const transformSubComments = (comments: Comment[] | null) => [...(comments ?? [])]
  ?.sort((a, b) => +new Date(b.commentedAt) - +new Date(a.commentedAt))
  .map((c) => ({
    id: c.commentId,
    user: { id: c.commentedBy, name: c.name },
    text: c.comment,
    createdAt: c.commentedAt,
  }))

const transformToServerComments = (comments: StoreComment[]) => comments.map((c) => {
  const { id, serverId, resolved, comments, ...region } = c
  return {
    labelingObjectId: serverId || id,
    labelingClassId: '669df22cbfcb2f078218bed9',
    type: ObjectToolType.COMMENT,
    isReviewed: resolved,
    region,
    zOrder: 0,
    isConfirm: resolved,
    comments: comments?.map((s) => ({
      commentId: s.id,
      commentedBy: s.user.id,
      comment: s.text,
      commentedAt: s.createdAt,
      name: s.user.name,
    })),
  }
})

export const transformComment = {
  toClient: transformToClientComments,
  toServer: transformToServerComments,
  subComment: transformSubComments,
}

export function getSettledFiles(state: Draft<LabelingState>): LabelingNodeItem[]
export function getSettledFiles(state: Draft<LabelingState>, index: number): LabelingNodeItem
export function getSettledFiles({ files }: Draft<LabelingState>, index?: number) {
  const isInvalidIndex = index !== undefined && files[index] === null
  if ((files.length < 1 && process.env.NODE_ENV === 'development') || isInvalidIndex) {
    throw new Error(
      'Access to uninitialized annotation file is not allowed. '
      + `Annotation file of index ${index} is null or undefined. `
      + 'Check if the annotation file is properly loaded before access.'
    )
  }
  if (index !== undefined) {
    return files[index]
  }
  return files
}

export const getLabelingDefaultCondition = <A, E>(args: A, thunkAPI: ThunkConditionAPI<E>) => {
  if (thunkAPI.getState().annotation.isInProgress) {
    console.error('Another request is in progress')
    return false
  }
  return true
}
