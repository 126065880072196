import { Nullable } from '../../types/common'
import { isNotNullish, isNullish } from '../typeChecks'

/* 산술 연산 */
export const add = (...numbers: Nullable<number>[]) => numbers
  .reduce<number>((a, c) => a + (c ?? 0), 0)

export const subtract = (...numbers: Nullable<number>[]) => numbers
  .reduce((a, c) => (a ?? 0) - (c ?? 0)) ?? 0

export const multiply = (...numbers: Nullable<number>[]) => numbers
  .reduce<number>((a, c) => a * (c ?? 0), 1)

export const divide = (...numbers: Nullable<number>[]) => numbers
  .reduce((a, c) => (a ?? 0) / (c ?? 1)) ?? 0

export const halve = (value: Nullable<number>) => (value ?? 0) / 2

export const double = (value: Nullable<number>) => (value ?? 0) * 2

export const getLength = (x1: Nullable<number>, x2: Nullable<number>) => Math.abs(subtract(x1, x2))

export const max = (...numbers: Nullable<number>[]) => Math.max(...numbers.filter(isNotNullish))

export const min = (...numbers: Nullable<number>[]) => Math.min(...numbers.filter(isNotNullish))

/* 비교 연산 */
const COMPUTATIONAL_ERROR = 0.0001

/** `a`, `b` 두 값이 수치적으로 동일한 지 판단(둘의 차이가 `COMPUTATIONAL_ERROR`보다 작을 때' */
export const isIdentical = (a: number, b: number) => Math.abs(a - b) < COMPUTATIONAL_ERROR

export const isAbsBounded = (value: Nullable<number>, bound: number) => Math.abs(value ?? 0) <= bound

export const clampMinMax = (value: Nullable<number>, min: number, max: number) => {
  if (isNullish(value) || value <= min) {
    return min
  }
  if (value >= max) {
    return max
  }
  return value
}

/* 삼각 함수 관련 */
export const isHorizontal = (radian: number) => isIdentical(radian % Math.PI, 0)

export const isVertical = (radian: number) => isIdentical(radian % (Math.PI * 0.5), 0)
