import { useReactive } from '@ahha/utils/hooks/useEffectEvent'
import { useEffect, useRef } from 'react'

type ArrowState = -1 | 0 | 1

interface KeyboardNavigationOptions {
  enabled: boolean
  /**
   * ref 요소 내부에 input, textarea 등 포커스가 가능한 요소가 있는지 여부를 설정.
   * `ref`를 설정하지 않았을 때, `hasFocusableChildren`를 false로 설정하면
   * 페이지 내의 input 요소가 포커스되어도 이벤트가 실행될 수 있으므로 true로 설정하는 것을 권장.
   *  */
  hasFocusableChildren?: boolean
  /**
   * 특정 요소 또는 그 자식 요소가 포커스되었을 때에만 이벤트를 실행하고자 할 경우 타겟 요소를 설정.
   * 기본값은 `document.body`
   *  */
  ref?: React.RefObject<HTMLElement>
  onArrowLeft?: () => void
  onArrowRight?: () => void
  onArrowUp?: () => void
  onArrowDown?: () => void
  onArrowMixed?: (x: ArrowState, y: ArrowState) => void
}

const ARROWS = new Set(['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'])
const FOCUSABLE_ELEMENT_TAGS = ['input', 'textarea']
const PSEUDO_EVENT_INTERVAL = 20

/** 사용자가 직접 스크롤을 드래그하는 대신 키보드를 이용해 이동할 수 있도록 해주는 훅 */
export const useKeyboardNavigation = ({
  ref,
  enabled,
  hasFocusableChildren = false,
  onArrowLeft,
  onArrowRight,
  onArrowUp,
  onArrowDown,
  onArrowMixed,
}: KeyboardNavigationOptions) => {
  const directionRecord = useRef(new Set<string>())
  const timerId = useRef<NodeJS.Timeout>()

  const getHandlers = useReactive({
    onArrowLeft,
    onArrowRight,
    onArrowUp,
    onArrowDown,
    onArrowMixed,
  })

  const isElementFocused = (e: HTMLElement | null) => {
    const { activeElement } = document
    const activeTagName = activeElement?.tagName.toLowerCase() ?? ''

    if (!e || !activeElement) {
      return false
    }
    return e.contains(activeElement) && FOCUSABLE_ELEMENT_TAGS.includes(activeTagName)
  }

  const getArrowState = () => {
    const x = +directionRecord.current.has('ArrowLeft') - +directionRecord.current.has('ArrowRight')
    const y = +directionRecord.current.has('ArrowUp') - +directionRecord.current.has('ArrowDown')

    return [x, y] as [ArrowState, ArrowState]
  }

  const clearKeyEventTimer = () => {
    clearInterval(timerId.current)
    timerId.current = undefined
  }

  const handleKeyDown = ({ key, repeat }: KeyboardEvent) => {
    const targetEl = ref?.current ?? document.body

    if (hasFocusableChildren && isElementFocused(targetEl)) {
      return
    }
    if (repeat) {
      return
    }
    const { onArrowLeft, onArrowRight, onArrowUp, onArrowDown, onArrowMixed } = getHandlers()

    if (key === 'ArrowLeft') {
      onArrowLeft?.()
    } else if (key === 'ArrowRight') {
      onArrowRight?.()
    } else if (key === 'ArrowUp') {
      onArrowUp?.()
    } else if (key === 'ArrowDown') {
      onArrowDown?.()
    }

    if (onArrowMixed && ARROWS.has(key)) {
      clearKeyEventTimer()
      timerId.current = setInterval(() => onArrowMixed(...getArrowState()), PSEUDO_EVENT_INTERVAL)
    }
    directionRecord.current.add(key)
  }

  const removeKeyRecord = ({ key }: KeyboardEvent) => {
    directionRecord.current.delete(key)

    if (directionRecord.current.size < 1) {
      clearKeyEventTimer()
    }
  }

  useEffect(() => {
    if (enabled) {
      document.addEventListener('keydown', handleKeyDown)
      document.addEventListener('keyup', removeKeyRecord)

      return () => {
        document.removeEventListener('keydown', handleKeyDown)
        document.removeEventListener('keyup', removeKeyRecord)
      }
    }
    return () => null
  }, [enabled])
}
