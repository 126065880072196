import { AnnotationState } from './types'
import { ToolType } from '@/pages/Lisa/Label/types'

export const ANNOTATION_GLOBAL_OPACITY = 0.8

export const initialState: AnnotationState = {
  id: null,
  type: '',
  name: null,
  mode: 'label',
  tool: {
    tool: ToolType.POINTER,
    class: null,
    shape: ToolType.RECTANGLE,
    brush: 1,
    opacity: ANNOTATION_GLOBAL_OPACITY * 100,
    magicWandThreshold: 16,
    isTracking: false,
  },
  isInProgress: false,
  files: [],
  annotation: {},
  currentIndex: 0,
  isRoiAllowed: false,
  classes: [],
  objectVisibility: {},
  datasetMeta: {
    id: '',
    name: '',
  },
}

export const PossibleShape = [
  ToolType.RECTANGLE,
  ToolType.ELLIPSE,
]

export const PossibleTool = [
  ...PossibleShape,
  ToolType.POINTER,
  ToolType.POLYGON,
  ToolType.BRUSH,
  ToolType.MAGICWAND,
  'select',
]

export const MAX_SNAPSHOT = 50
