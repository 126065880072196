import { useCallback, useRef } from 'react'
import ImmutableSet from '@ahha/utils/ImmutableSet'

type TimerId = NodeJS.Timeout

const DEFAULT_TIMEOUT = 200

export const useTimeout = (timeout = DEFAULT_TIMEOUT) => {
  const timerIds = useRef(new ImmutableSet<TimerId>())

  const clearTimer = useCallback((id: TimerId) => {
    clearTimeout(id)
    timerIds.current = timerIds.current.delete(id)
  }, [])

  const clearAllTimers = useCallback(() => {
    timerIds.current.forEach((id) => clearTimeout(id))
    timerIds.current.clear()
  }, [])

  const startTimer = useCallback((fn: () => void, delay = timeout) => {
    const timer = setTimeout(() => {
      fn()
      clearTimer(timer)
    }, delay)

    timerIds.current = timerIds.current.add(timer)
  }, [])

  return {
    start: startTimer,
    clear: clearTimer,
    clearAll: clearAllTimers,
  }
}
