import { useCallback, useRef } from 'react'

export const useFlagMode = () => {
  const mode = useRef(false)

  const isActive = () => mode.current

  const setMode = useCallback(() => { mode.current = true }, [])

  const unsetMode = useCallback(() => { mode.current = false }, [])

  return [isActive, setMode, unsetMode] as const
}
