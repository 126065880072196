/* eslint-disable no-continue */
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { v4 } from '@lukeed/uuid'
import { filter, find, forEach, map } from 'lodash'

import { isArray } from '@ahha/utils/@types/typeChecks'
import { Node, NodePlaceholder } from '@/stores/slices/Pipeline/types/node'
import { DatasetNodeInfo } from '@/api/DatasetNode/types'
import { Link } from '@/stores/slices/Pipeline/types/link'
import { ChangeLinkPayload, CreateLabelNodePayload, CreateNodePayload, CreatePlaceholderNode, CreateServerNodePayload, InitializeNodePayload, UpdateNode } from '@/stores/slices/Pipeline/types/payload'
import { INITIAL_NODE_TITLE, LINK_ERROR } from '@/stores/slices/Pipeline/const'
import { isLabelingNode } from '@/stores/slices/Pipeline/types/typeGuards'
import { MainCategory, NodeVariant } from '@/pages/Pipeline/canvas/FlowNode/types'

type InvalidationTarget =
  | 'all'
  | 'self'
  | 'parent'
  | 'children'
  | 'self/children'
  | 'self/siblings'
  | 'parent/self'
  | 'parent/self/siblings'

interface InvalidationData {
  type: InvalidationTarget
  id: string
}

export interface NodeData extends Node {
  type: MainCategory
}

export interface PipelineState {
  projectId: string
  nodes: Record<string, Node>
  links: Link[]
  placeholder: NodePlaceholder | null
  stale: InvalidationData | null
  // NOTE: task api에 따라서 필요없으면 아래 필드는 삭제
  tasksInProgress: { type: 'train' | 'validation' | 'inference', nodeId: string, modelType?: string }[]
}

const INITIAL_STATE: PipelineState = {
  projectId: '',
  nodes: {},
  links: [],
  placeholder: null,
  stale: null,
  tasksInProgress: [],
}

export const pipelineSlice = createSlice({
  name: 'pipeline',
  initialState: INITIAL_STATE,
  reducers: {
    resetPipelineData: () => INITIAL_STATE,
    loadAllServerNodes: (state, action: PayloadAction<InitializeNodePayload>) => {
      const { projectId, nodes } = action.payload

      forEach(nodes, (n) => {
        if (!find(state.tasksInProgress, (t) => t.nodeId === n.id)) {
          if (n.type === 'train' && n.variant === 'ongoing') {
            state.tasksInProgress = [...state.tasksInProgress, { type: 'train', nodeId: n.id, modelType: n.details }]
          }
          if (n.type === 'validation' && n.variant === 'ongoing') {
            state.tasksInProgress = [...state.tasksInProgress, { type: 'validation', nodeId: n.id }]
          }
          if (n.type === 'inference' && n.variant === 'ongoing') {
            state.tasksInProgress = [...state.tasksInProgress, { type: 'inference', nodeId: n.id }]
          }
        }
      })

      state.projectId = projectId
      state.nodes = nodes.reduce((a, n) => ({ ...a, [n.id]: n }), {} as Record<string, Node>)
      state.links = nodes.reduce((a, n) => {
        const { id, previous = [], next = [] } = n

        a.push(
          ...map(previous, (d) => ({ from: d, to: id })),
          ...map(next, (d) => ({ from: id, to: d }))
        )
        return a
      }, [] as Link[])
    },
    invalidateNode: (state, action: PayloadAction<InvalidationData>) => {
      state.stale = action.payload
    },
    /** @deprecated  */
    createDatasetNode: (state, action: PayloadAction<DatasetNodeInfo>) => {
      const { _id, numOfDatasetItems, metadata } = action.payload
      const createdAt = new Date()
      const mainArea = metadata?.area
      const mainId = _id

      state.nodes[mainId] = {
        id: mainId,
        type: 'dataset',
        title: 'Dataset',
        details: `${numOfDatasetItems ?? '-'}`,
        isActivated: true,
        area: mainArea,
        createdAt,
      }
    },
    /** @deprecated  */
    createLabelNode: (state, action: PayloadAction<CreateLabelNodePayload>) => {
      const { _id, name, modelType, area, parent, isActivated, datasetNodeIds } = action.payload
      const createdAt = new Date()

      state.nodes[_id] = {
        id: _id,
        type: 'labeling',
        title: name,
        details: modelType ?? '',
        isActivated,
        area,
        createdAt,
        parent,
      }

      /** 레이블링 상위 노드만 데이터셋과 연결 */
      if (!parent) {
        state.links.push(...datasetNodeIds.map((d) => ({ from: d, to: _id })))
      }
    },
    createPlaceholder: (state, action: PayloadAction<CreatePlaceholderNode>) => {
      const { sourceNode } = action.payload
      const id = v4()

      state.placeholder = { ...action.payload, id }
      if (sourceNode) {
        state.links.push({ from: sourceNode, to: id })
      }
    },
    deletePlaceholder: (state, action: PayloadAction<string | undefined>) => {
      const id = action.payload ?? state.placeholder?.id

      state.placeholder = null
      if (id) {
        state.links = state.links.filter((l) => l.from !== id && l.to !== id)
      }
    },
    createClientNode: (state, action: PayloadAction<CreateNodePayload>) => {
      const { placeholderId, type, area } = action.payload
      const id = placeholderId ?? v4()
      const placeholderData = state.placeholder
      const nodeData = {
        id,
        type,
        title: INITIAL_NODE_TITLE[type],
        details: '-',
        isActivated: false,
        createdAt: new Date(),
      }

      if (placeholderId && placeholderData) {
        const area = action.payload.area ?? placeholderData.area

        state.placeholder = null
        state.nodes[placeholderId] = { ...placeholderData, ...nodeData, area }
      } else if (area) {
        state.nodes[id] = { ...nodeData, area }
      }
    },
    createServerNode: (state: PipelineState, action: PayloadAction<CreateServerNodePayload>) => {
      const { id, ...data } = action.payload

      state.placeholder = null
      state.nodes[id] = { id, ...data }
    },
    updateNode: <D extends UpdateNode = UpdateNode>(state: PipelineState, action: PayloadAction<D>) => {
      const { id, clientId, ...data } = action.payload

      /** TODO:ksh: inactive 상태의 노드를 생성할 때 NodePlaceholder에서 바로 API 호출을 하면 필요 없을지도 - 2024.09.04 */
      /** client에서 생성한 id를 server id로 변환 */
      if (clientId) {
        const inactiveNodeData = state.nodes[clientId]

        state.links = state.links.map((l) => {
          if (l.from === clientId) {
            return { ...l, from: id }
          }
          if (l.to === clientId) {
            return { ...l, to: id }
          }
          return l
        })

        delete state.nodes[clientId]
        state.nodes[id] = { ...inactiveNodeData, id, ...data }
      } else {
        const prevData = state.nodes[id]

        state.nodes[id] = { ...prevData, ...data }
      }
    },
    updateNodesInBulk: <D extends UpdateNode = UpdateNode>(state: PipelineState, action: PayloadAction<D[]>) => {
      action.payload.forEach((data) => {
        const { id, ...d } = data

        if (id) {
          const prevData = state.nodes[id]

          state.nodes[id] = { ...prevData, ...d }
        }
      })
    },
    deleteNode: (state, action: PayloadAction<string>) => {
      const id = action.payload
      const { previous = [] } = state.nodes[id] ?? {}
      const RETURNS = { links: [] as Link[], nextNodes: [] as string[] }
      const { links, nextNodes } = state.links.reduce((a, l) => {
        if (l.from !== id && l.to !== id) {
          a.links.push(l)
        }
        /** 현재 id 노드에 연결된 다음 노드 */
        if (l.from === id) {
          a.nextNodes.push(l.to)
        }
        return a
      }, RETURNS)

      delete state.nodes[id]

      previous.forEach((n) => {
        const nextNodes = state.nodes[n]?.next ?? []

        state.nodes[n].next = nextNodes.filter((n) => n !== id)
      })
      nextNodes.forEach((n) => {
        const previousNodes = state.nodes[n]?.previous ?? []

        state.nodes[n].previous = previousNodes.filter((n) => n !== id)
      })
      state.links = links
    },

    /* Link */
    changeLink: (state, action: PayloadAction<ChangeLinkPayload>) => {
      const { from, to, prevLink, error } = action.payload
      const fNode = state.nodes[from]
      const tNode = state.nodes[to]

      state.links = state.links
        .filter((l) => l.from !== prevLink.from || l.to !== prevLink.to)
        .concat({
          from,
          to,
          /** TODO:ksh: error 메시지 필요한 경우 수정 - 2024.09.03 */
          error: undefined,
        })
    },
    createLinks: (state, action: PayloadAction<Link | Link[]>) => {
      const links = isArray(action.payload) ? action.payload : [action.payload]

      links.forEach((link) => {
        const { from, to } = link
        const previousOfStart = state.nodes[from].next ?? []
        const nextOfEnd = state.nodes[to].previous ?? []

        state.links.push(link)
        state.nodes[from].next = [...new Set([...previousOfStart, to])]
        state.nodes[to].previous = [...new Set([...nextOfEnd, from])]
      })
    },
    addNodeInProgress: (state, action: PayloadAction<{ type: 'train' | 'validation' | 'inference', nodeId: string, modelType?: string }>) => {
      if (!find(state.tasksInProgress, (t) => t.nodeId === action.payload.nodeId)) {
        state.tasksInProgress = [...state.tasksInProgress, action.payload]
      }
    },
    removeNodeInProgress: (state, action: PayloadAction<string>) => {
      state.tasksInProgress = filter(state.tasksInProgress, (t) => t.nodeId !== action.payload)
    },
    updateNodeTaskStatus: (state, action: PayloadAction<{nodeId: string, variant: NodeVariant}>) => {
      const { nodeId, variant } = action.payload
      state.nodes[nodeId].variant = variant
    },
    resetNodeInProgress: (state, action: PayloadAction<void>) => {
      state.tasksInProgress = []
    },
  },
})

export const {
  resetPipelineData,
  loadAllServerNodes,
  invalidateNode,
  /** @deprecated  */
  createDatasetNode,
  /** @deprecated  */
  createLabelNode,
  createPlaceholder,
  deletePlaceholder,
  createClientNode,

  createServerNode,
  updateNode,
  updateNodesInBulk,
  deleteNode,

  changeLink,
  createLinks,

  addNodeInProgress,
  removeNodeInProgress,
  updateNodeTaskStatus,
  resetNodeInProgress,
} = pipelineSlice.actions

const pipelineReducers = pipelineSlice.reducer

export default pipelineReducers
